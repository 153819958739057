import { THUMBNAILS_TOOLBAR_HEIGHT } from '../consts'
import { IGetVisibleElementsParameters } from '@views/organization/documents/components/Document/components/File/types'
import { sendErrorToSentry } from '@utils/utils'
import { Nullable } from '@store/types/commonTypes'

// eslint-disable-next-line no-control-regex
const NullCharactersRegExp = /\x00/g
// eslint-disable-next-line no-control-regex
const InvisibleCharactersRegExp = /[\x01-\x1F]/g

export function binarySearchFirstItem(items: any, condition: any, start = 0): number {
  let minIndex = start
  let maxIndex = items.length - 1

  if (maxIndex < 0 || !condition(items[maxIndex])) {
    return items.length
  }

  if (condition(items[minIndex])) {
    return minIndex
  }

  while (minIndex < maxIndex) {
    const currentIndex = minIndex + maxIndex >> 1
    const currentItem = items[currentIndex]

    if (condition(currentItem)) {
      maxIndex = currentIndex
    } else {
      minIndex = currentIndex + 1
    }
  }

  return minIndex
}

function backtrackBeforeAllVisibleElements(index: number, views: any[], top: number): number {
  if (index < 2) {
    return index
  }

  let elt = views[index].div
  let pageTop = +elt.offsetTop + +elt.clientTop

  if (pageTop >= top) {
    elt = views[index - 1].div
    pageTop = +elt.offsetTop + +elt.clientTop
  }

  for (let i = index - 2; i >= 0; --i) {
    elt = views[i].div

    if (+elt.offsetTop + +elt.clientTop + +elt.clientHeight <= pageTop) {
      break
    }

    index = i
  }

  return index
}

export function removeNullCharacters(str: string, replaceInvisible = false): string {
  if (replaceInvisible) {
    str = str.replace(InvisibleCharactersRegExp, ' ')
  }

  return str.replace(NullCharactersRegExp, '')
}

export function parseQueryString(query: any): Map<any, any> {
  const params = new Map()

  for (const [key, value] of new URLSearchParams(query)) {
    params.set(key.toLowerCase(), value)
  }

  return params
}

export function watchScroll(viewAreaElement: any, callback: any): any {
  const debounceScroll = function (evt: Event): void {
    if (rAF) {
      return
    }

    rAF = window.requestAnimationFrame(function viewAreaElementScrolled() {
      rAF = null
      const currentX = viewAreaElement.scrollLeft
      const lastX = state.lastX

      if (currentX !== lastX) {
        state.right = currentX > lastX
      }

      state.lastX = currentX
      const currentY = viewAreaElement.scrollTop
      const lastY = state.lastY

      if (currentY !== lastY) {
        state.down = currentY > lastY
      }

      state.lastY = currentY
      callback(state)
    })
  }

  const state = {
    right: true,
    down: true,
    lastX: viewAreaElement.scrollLeft,
    lastY: viewAreaElement.scrollTop,
    _eventHandler: debounceScroll
  }
  let rAF: Nullable<number> = null
  viewAreaElement.addEventListener('scroll', debounceScroll, true)
  return state
}

export function getVisibleElements({
  scrollEl,
  views,
  sortByVisibility = false,
  horizontal = false,
  rtl = false
}: IGetVisibleElementsParameters): any {
  const top = scrollEl.scrollTop + THUMBNAILS_TOOLBAR_HEIGHT
  const bottom = top + scrollEl.clientHeight
  const left = scrollEl.scrollLeft
  const right = left + scrollEl.clientWidth

  function isElementBottomAfterViewTop(view: any): boolean {
    const element = view.div
    const elementBottom = +element.offsetTop + +element.clientTop + +element.clientHeight
    return elementBottom > top
  }

  function isElementNextAfterViewHorizontally(view: any) : boolean {
    const element = view.div
    const elementLeft = +element.offsetLeft + +element.clientLeft
    const elementRight = +elementLeft + +element.clientWidth
    return rtl ? elementLeft < right : elementRight > left
  }

  const visible = []
  const ids = new Set()
  const numViews = views.length
  let firstVisibleElementInd = binarySearchFirstItem(views, horizontal ? isElementNextAfterViewHorizontally : isElementBottomAfterViewTop)

  if (firstVisibleElementInd > 0 && firstVisibleElementInd < numViews && !horizontal) {
    firstVisibleElementInd = backtrackBeforeAllVisibleElements(firstVisibleElementInd, views, top)
  }

  let lastEdge = horizontal ? right : -1

  for (let i = firstVisibleElementInd; i < numViews; i++) {
    const view = views[i]
    const element = view.div
    const currentWidth = +element.offsetLeft + +element.clientLeft
    const currentHeight = +element.offsetTop + +element.clientTop
    const viewWidth = element.clientWidth
    const viewHeight = element.clientHeight
    const viewRight = +currentWidth + +viewWidth
    const viewBottom = +currentHeight + +viewHeight

    if (lastEdge === -1) {
      if (viewBottom >= bottom) {
        lastEdge = viewBottom
      }
    } else if ((horizontal ? currentWidth : currentHeight) > lastEdge) {
      break
    }

    if (viewBottom <= top || currentHeight >= bottom || viewRight <= left || currentWidth >= right) {
      continue
    }

    const hiddenHeight = Math.max(0, top - currentHeight) + Math.max(0, viewBottom - bottom)
    const hiddenWidth = Math.max(0, left - currentWidth) + Math.max(0, viewRight - right)
    const fractionHeight = (viewHeight - hiddenHeight) / viewHeight
    const fractionWidth = (viewWidth - hiddenWidth) / viewWidth
    const percent = fractionHeight * fractionWidth * 100 | 0
    visible.push({
      id: view.id,
      x: currentWidth,
      y: currentHeight,
      view,
      percent,
      widthPercent: fractionWidth * 100 | 0
    })
    ids.add(view.id)
  }

  const first = visible[0]
  const last = visible[visible.length - 1]

  if (sortByVisibility) {
    visible.sort(function (a, b) {
      const pc = a.percent - b.percent

      if (Math.abs(pc) > 0.001) {
        return -pc
      }

      return a.id - b.id
    })
  }

  return {
    first,
    last,
    views: visible,
    ids
  }
}

export function scrollIntoView(element: any, spot: any, scrollMatches = false): void {
  let parent = element.offsetParent

  if (!parent) {
    const sentryParams = {
      info: {},
      message: 'offsetParent is not set -- cannot scroll'
    }
    sendErrorToSentry(sentryParams)
    return
  }

  let offsetY = +element.offsetTop + +element.clientTop
  let offsetX = +element.offsetLeft + +element.clientLeft

  // eslint-disable-next-line no-mixed-operators,no-unmodified-loop-condition
  while (parent.clientHeight === parent.scrollHeight && parent.clientWidth === parent.scrollWidth || scrollMatches && (parent.classList.contains('markedContent') || getComputedStyle(parent).overflow === 'hidden')) {
    offsetY += +parent.offsetTop
    offsetX += +parent.offsetLeft
    parent = parent.offsetParent

    if (!parent) {
      return
    }
  }

  if (spot) {
    if (spot.top !== undefined) {
      offsetY += +spot.top
    }

    if (spot.left !== undefined) {
      offsetX += +spot.left
      parent.scrollLeft = offsetX
    }
  }

  parent.scrollTop = offsetY
}

export function isValidRotation(angle: number): boolean {
  return Number.isInteger(angle) && angle % 90 === 0
}

export class OutputScale {
  sx: number
  sy: number

  constructor() {
    const pixelRatio = window.devicePixelRatio || 1
    this.sx = pixelRatio
    this.sy = pixelRatio
  }

  get scaled(): boolean {
    return this.sx !== 1 || this.sy !== 1
  }
}
