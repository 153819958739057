import { AxiosResponse } from 'axios'
import { ReactNode } from 'react'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { ILibPresetValue } from '@infologistics/frontend-libraries'
import {
  fieldValue,
  IAssignedToUser,
  IApplicationState,
  IContractorField,
  IDictionary,
  IGroup,
  ISubOrg,
  Nullable,
  IAssignedToSubOrg, IFileFieldValue
} from '@store/types/commonTypes'
import { IDocumentRecipient } from '@views/organization/documents/components/DocumentEdit/types'
import { AssignMode, PreviousVersionMode, SignatureCheckStatus, StagesType } from '@const/consts'
import { IConstraintToSet } from '@store/modules/constraints/types'
import { ISelectedCertificatePowerOfAttorney } from '@views/user/Profile/modals/CertificatePower/types';
import { IStageAssigneeOption } from '@common/Stages/components/Stage/types'
import { IPreProcessingInfo, IPreProcessingJobStatus } from '../metadata/types'

// Message
export type MessageDirection = 'INCOMING' | 'OUTGOING'

export type MessageSendStatus =
  | 'WAITING_SENDER_SIGNATURE'
  | 'WAITING_TO_SEND'
  | 'SUCCESS'
  | 'ERROR'
  | 'WAITING_PROXY_SIGNATURE'
  | 'WAITING_TO_SEND_PROXY'
  | 'ERROR_IN_PROXY'

export interface IOrganization {
  oguid: Nullable<string>
  inn: Nullable<string>
  kpp: Nullable<string>
  ogrn: Nullable<string>
  nameShort: string
}

export type DocumentDirection = 'INCOMING' | 'INTERNAL' | 'OUTGOING'

export type FlowState = 'COMPLETED' | 'IN_PROGRESS' | 'NOT_STARTED'

type FlowResult =
  | 'DECLINED'
  | 'ERROR'
  | 'SOLVED'
  | 'STOPPED'

export type IDocumentField = number | string | boolean | IContractorField | IDictionary | DocumentDirection | IFileFieldValue | IFileFieldValue[]

export type DocumentDetailsField = number | string | boolean | IDictionary | DocumentDirection

export interface IDocumentFields {
  [key: string]: Nullable<IDocumentField>
}

export interface DocumentFieldDependency {
  [key: string]: string[]
}

export interface IDocumentFieldsDependencies {
  dependent?: DocumentFieldDependency,
  calculable?: DocumentFieldDependency
}

export interface IDocumentDetailsField {
  [key: string]: DocumentDetailsField
}

export interface IHistoryOrg {
  inn: Nullable<string>
  kpp: Nullable<string>
  nameShort: string
  ogrn: Nullable<string>
  oguid: Nullable<string>
}

export interface IHistoryUser {
  email: string
  name: string
  oguid: Nullable<string>
  patronymic: Nullable<string>
  position: string
  surname: string
}

export interface IHistoryDocumentFields {
  subOrg: Nullable<ISubOrg>
  initiator: Nullable<IAssignedToUser>
  fields: Nullable<IDocumentFields>
}

export interface IHistory {
  comment: Nullable<string>
  historyStateCaption: string
  historyStateCode: string
  historyTimestamp: Nullable<number>
  orgWhereHappened: IHistoryOrg
  signatureAttachmentOguid: Nullable<string>
  subOrgName: Nullable<string>
  user: Nullable<IHistoryUser>
  signatureCheck: Nullable<SignatureCheckStatus>
  taskOguid: Nullable<string>
  beforeHistory: Nullable<IHistoryDocumentFields>
  afterHistory: Nullable<IHistoryDocumentFields>
}

export interface IFileData {
  id: string
  name: string
  size: number
}

export type IAllowedAction =
  | 'APPROVE'
  | 'CORRECTION_REQUEST'
  | 'DELETE_INVITE'
  | 'DOWNLOAD_DOCUMENTS'
  | 'DOWNLOAD_REGISTRY'
  | 'DECLINE_SIGN'
  | 'DECLINE_APPROVE'
  | 'DELETE'
  | 'MODIFY'
  | 'NEW_VERSION'
  | 'RESEND'
  | 'SIGN'
  | 'SIGN_TITLE'
  | 'SIGN_TICKET'
  | 'START_PROCESS'
  | 'STOP_PROCESS'

export interface IDocumentVersion {
  documentId: number
  documentOguid: string
  version: number
}

interface IQueryMatches {
  page: number
  match: string
  indexStart: number
  indexEnd: number
}

export interface IDocument {
  allowedActions: IAllowedAction[]
  contractorOrg: Nullable<IOrganization>
  creatorOguid: Nullable<string>
  constraintViolations: IConstraintViolation[]
  comment: Nullable<string>
  details: IDocumentDetailsField[]
  direction: DocumentDirection
  documentId: number
  documentAttachmentOguid: string
  documentTimestamp: number
  documentVersions: IDocumentVersion[]
  externalId: Nullable<string>
  fields: IDocumentFields
  flowCompletedTimestamp: Nullable<number>
  flowResult: Nullable<FlowResult>
  flowStageType: string
  flowStageUserName: Nullable<string>
  flowStagesCurrentNumber?: number
  flowStagesTotalCount?: number
  flowStartTimestamp: Nullable<number>
  flowState: Nullable<FlowState>
  history: IHistory[]
  initiator: Nullable<IAssignedToUser>
  isExternal: boolean
  isReadOnly: boolean
  isWorkflowFinished: Nullable<boolean>
  messageOguid?: string
  oguid: string
  packageDocumentCount?: number
  readOnlyFields: string[]
  relatedDocumentCount?: number
  subOrg: Nullable<ISubOrg>
  tasks?: IAccessTask[]
  type: string
  unreadTodosCount?: number
  workflowStatuses: Nullable<string[]>
  queryMatches?: IQueryMatches[]
  isReloadFile?: boolean
  preProcessingStatus: IPreProcessingJobStatus
  preProcessingInfo: Nullable<IPreProcessingInfo>
}

interface IConstraintViolationField {
  key: string
  label: string
}

export interface IConstraintViolation {
  type: string
  code: string
  errorMessage: string
  fields: IConstraintViolationField[]
}

export interface IAccessTask {
  oguid: string
  type: 'APPROVAL' | 'TODO' | 'SIGNING' | 'ACQUAINTANCE'
}

export type PreviewOrientation = 'PORTRAIT' | 'LANDSCAPE'

export interface IPreviewData {
  fileCode: string
  mimeType: string
  orientation: string
}

export enum GetPreviewFileMode {
  ATTACH = 'attach',
  SHELF = 'shelf'
}

export interface IGetPreviewFile {
  fileCode?: string
  mimeType: string
  orientation: PreviewOrientation
  totalPages: number
}

export interface IDocumentFiles {
  data: IPreviewData[]
  isAvailable: boolean
  total: number
}

export type SignCheckStatus = 'WAITING_FOR_CHECK' | 'INVALID_SIGNATURE' | 'VALID_SIGNATURE' | 'NOT_ACCEPTABLE'

export interface IDocumentSignInfo {
  signatureCheckStatus: SignCheckStatus
  signatureCheckTimestamp: Nullable<number>
  signatureName: string
  signatureSurname: string
  signaturePatronymic: Nullable<string>
  signatureRegistrationCertificate: string
  signatureSignerOrganizationName: string
  certificateDateFrom: Nullable<number>
  certificateDateTo: Nullable<number>
  signatureTimestamp: Nullable<number>
  issueOrganization: string
  signatureId: string
}

export interface IMessage {
  oguid: string
  direction: MessageDirection
  lastPatchOguid: Nullable<string>
  lastPatchTimestamp: Nullable<number>
  sendStatus: Nullable<MessageSendStatus>
  sendTimestamp: number
  messageTimestamp: number
  deliveryTimestamp: Nullable<number>
  contractorOrg: IOrganization
  proxyOrg: Nullable<IOrganization>
  isProxified: Nullable<boolean>
  documents: IDocument[]
}

export interface ISelectedDocuments {
  [key: string]: boolean
}

export interface IDraftDocument {
  constraintViolations?: IConstraintViolation[]
  data: IDraftDocumentData
  fileName: string
  fileSize: number
  previewFiles: IDocumentFiles
  blocked?: boolean
}

export interface IDocumentValuesFields {
  [key: string]: fieldValue
}

export interface ISignedContent {
  fileOguid: string
  powerOfAttorney?: Nullable<ISelectedCertificatePowerOfAttorney>
  signatureOguid?: Nullable<string>
  isEncryptedContent?: Nullable<string>
}

export interface IDraftDocumentData {
  documentId?: Nullable<string>
  comment: Nullable<string>
  details: IDocumentDetailsField[]
  initiatorOguid: Nullable<string>
  fields: IDocumentValuesFields
  signedContent: ISignedContent
  subOrgOguid: Nullable<string>
  templateBased?: boolean
  type: string,
  fieldsDependencies?: IDocumentFieldsDependencies
  preProcessingJobOguid?: Nullable<string>
}

export interface IDraftRouteDocumentData {
  initiatorOguid: Nullable<string>
  fields: IDocumentValuesFields
  subOrgOguid: Nullable<string>
  type: string
}

export interface IDraftMessageData extends Omit<IDraftDocumentData, "preProcessingJobOguid"> {
  isRequireRecipientSignature: boolean
}

export interface ICopyDocumentData extends Omit<IDraftDocumentData, 'signedContent'> {
  direction: DocumentDirection
  isFlowRequired?: boolean
  previousVersionDocumentOguid: Nullable<string>
  signedContent: Nullable<ISignedContent>
}

export interface INewVersionDocumentData extends Omit<IDraftDocumentData, 'signedContent'> {
  direction: DocumentDirection
  previousVersionDocumentOguid: Nullable<string>
  signedContent: Nullable<ISignedContent>
}

export interface IDraftMessage {
  constraintViolations?: IConstraintViolation[]
  data: IDraftMessageData
  fileName: string
  fileSize: number
  previewFiles: IDocumentFiles
}

export interface IDraftDocumentCurrent {
  [key: string]: IDraftDocument
}
export interface IDraftMessageCurrent {
  [key: string]: IDraftMessage
}

export interface IDraftDocuments {
  current: IDraftDocumentCurrent
  idList: string[]
}

export interface IDraftMessages {
  current: IDraftMessageCurrent
  idList: string[]
}

export interface IUpdateDraftDocumentMessageOptions {
  previewFiles?: IDocumentFiles
  fileName?: string
  fileSize?: string
  blocked?: boolean
}

export interface IRelations {
  relatedDocuments: IRelatedDocument[]
  packageDocuments: IRelatedDocument[]
}

interface IRelatedDocumentInitiator {
  oguid: string
  surname: string
  name: string
  patronymic: string
  position: string
  email: string
}

interface IRelatedDocumentSugorg {
  oguid: string
  name: string
}

interface IRelatedDocumentOrg {
  oguid: string
  inn: string
  kpp: string
  ogrn: string
  nameShort: string
}

export interface IRelatedDocument {
  messageOguid: Nullable<string>
  oguid: string
  parentOguid: Nullable<string>
  initialDocumentOguid: Nullable<string>
  initiator: Nullable<IRelatedDocumentInitiator>
  subOrg: Nullable<IRelatedDocumentSugorg>
  isExternal: boolean
  externalId: Nullable<string>
  documentId: number
  comment: Nullable<string>
  flowState: string
  flowResult: Nullable<string>
  indexKey: number
  relatedDocumentCount: number
  packageDocumentCount: number
  documentTimestamp: number
  messageTimestamp: Nullable<number>
  type: string
  version: Nullable<string>
  workflowStatuses: string[]
  isWorkflowFinished: boolean
  direction: string
  contractorOrg: Nullable<IRelatedDocumentOrg>
  proxyOrg: Nullable<IRelatedDocumentOrg>
  isProxy: boolean
  sendStatus: Nullable<string>
  fields: IDocumentFields
  details: IDocumentDetailsField[]
  documentAttachmentOguid: string
  additionalWorkflowInformation?: Nullable<IAdditionalWorkflowInformation>
  constraintViolations: IConstraintViolation[]
}

export interface ISignature {
  check: string
  attachmentOguid: string
}

export interface IWorkflowSignature extends ISignature {
  status: string
}

export interface IAdditionalWorkflowInformation {
  senderSignature: IWorkflowSignature
  isRequireRecipientSignature: boolean
  recipientSignature: IWorkflowSignature
  recipientTitle: {
    signature: ISignature
    status: string
    attachmentOguid: string
  }
  proxySignature: IWorkflowSignature
  proxyTitle: {
    signature: ISignature
    status: string
    attachmentOguid: string
  }
  recipientConfirmation: {
    status: string
    recipientAttachmentOguid: string
  }
  recipientConfirmationOperator: {
    status: string
    confirmationAttachmentOguid: string
    receiptAttachmentOguid: string
  }
  operatorConfirmation: {
    status: string
    confirmationAttachmentOguid: string
    receiptAttachmentOguid: string
  }
  recipientTitleConfirmation: {
    status: string
    confirmationAttachmentOguid: string
    receiptAttachmentOguid: string
  }
  correctionRequest: {
    request: {
      status: string
      confirmationAttachmentOguid: string
      receiptAttachmentOguid: string
    }
    isRequested: boolean
    isRevised: boolean
    isCorrected: boolean
  }
  revocation: {
    status: string
    confirmationAttachmentOguid: string
    receiptAttachmentOguid: string
  }
  lastEventOguid: string
  lastEventTimestamp: number
}

export interface IFlowState {
  processOguid: string
  routeOguid: string
  stages: IStage[]
}

export interface IStage {
  oguid: string
  userName: string
  type: StagesType
  state: string
  tasks: ITask[]
  assignees: IAssignee[]
  constraints: Nullable<Array<string | IConstraintToSet>>
  isMilestone?: boolean
  round?: number
  hint: string
}

export interface IUpdatedFlowStage {
  name?: Nullable<string>
  oguid: Nullable<string>
  assignees: IUpdatedFlowTask[]
  type: string
  constraints?: Nullable<Array<string | IConstraintToSet>>
}

export interface IUpdatedFlowTask {
  assignedToGroupOguid: Nullable<string>
  assignedToUserOguid: Nullable<string>
  assignedToManagerOfSubOrgOguid: Nullable<string>
  assignMode: Nullable<AssignMode>
  oguid: Nullable<string>
  hoursToAutoAction?: Nullable<number>
  autoReassignToUserOguid?: Nullable<string>
  autoReassignToGroupOguid?: Nullable<string>
  autoReassignToOwner?: Nullable<boolean>
  autoReassignToManagerOfSubOrgOguid?: Nullable<string>
}

interface ICompletedBy {
  oguid: string
  surname: string
  name: Nullable<string>
  patronymic: Nullable<string>
  position: Nullable<string>
  email: string
}

export interface ITask {
  oguid: string
  type: string
  state: string
  result: string
  completedBy: Nullable<ICompletedBy>
  completedTimestamp: Nullable<number>
  dueTimestamp: Nullable<number>
  isDueDateTimeViolation: boolean
  startedTimestamp: number
  assignMode: Nullable<AssignMode>
  assignedToUser: Nullable<IAssignedToUser>
  assignedToCalculatedUser: Nullable<ILibPresetValue>
  assignedToGroup: Nullable<IGroup>
}

export interface IAssignee {
  oguid: string
  type: string
  state: string
  result: string
  completedBy: Nullable<ICompletedBy>
  completedTimestamp: Nullable<number>
  startedTimestamp: number
  dueTimestamp: Nullable<number>
  isDueDateTimeViolation: boolean
  assignMode: Nullable<AssignMode>
  assignedToUser: Nullable<IAssignedToUser>
  assignedToCalculatedUser: Nullable<ILibPresetValue>
  assignedToGroup: Nullable<IGroup>
  assignedToManagerOfSubOrg?: Nullable<IAssignedToSubOrg>
  autoReassignToUser?: Nullable<IAssignedToUser>
  autoReassignToGroup?: Nullable<IStageAssigneeOption>
  autoReassignToManagerOfSubOrg?: Nullable<IStageAssigneeOption>
  autoReassignToOwner?: Nullable<boolean>
  autoActionTimestamp?: Nullable<number>
  autoReassignedFromUser?: Nullable<IAssignedToUser>
  autoReassignedFromGroup?: Nullable<IStageAssigneeOption>
  autoReassignedFromManagerOfSubOrg?: Nullable<IAssignedToSubOrg>
  isAutoCompleted?: Nullable<boolean>
}

export interface IParentTask {
  assignedToGroup: Nullable<IGroup>
  assignedToUser: Nullable<IAssignedToUser>
  author: Nullable<IAssignedToUser>
  comment: Nullable<string>
  completedBy: Nullable<IAssignedToUser>
  completedTimestamp: Nullable<number>
  description: Nullable<string>
  isRead: boolean
  oguid: string
  parentTaskOguid: Nullable<string>
  processParticipants: IAssignedToUser[]
  startedBy: Nullable<IAssignedToUser>
  startedTimestamp: Nullable<number>
}

export interface ITodoAttachment {
  fileName: string
  mimeType: string
  oguid: string
}

export interface ITodo {
  assignedToUser: IAssignedToUser
  attachment: Nullable<ITodoAttachment>
  author: IAssignedToUser
  comment: Nullable<string>
  completedBy: Nullable<IAssignedToUser>
  completedTimestamp: Nullable<number>
  description: Nullable<string>
  isCompleted: boolean
  isRead: boolean
  oguid: string
  parentTask: Nullable<IParentTask>
  processParticipants: IAssignedToUser[]
  startedBy: Nullable<IAssignedToUser>
  startedTimestamp: number
  todos: ITodo[]
}

export interface IAttachment {
  oguid: string,
  filename: string,
  created: number,
  createdBy: IAssignedToUser
}

export interface ITodoBody {
  assignedToGroupOguid: Nullable<string>
  assignedToUserOguid: Nullable<string>
  description: string
  parentTaskOguid: Nullable<string>
  fileOguid: Nullable<string>
}

export interface IPackageBody {
  relatedDocumentOguid: string
  relationType: RelationType
}

export type RelationType = 'PACKAGE' | 'RELATED'

export interface IAttachmentBody {
  oguid: string
}

export interface IDocumentTask {
  state?: Nullable<string>
  result?: Nullable<string>
}

export interface IDocumentStage {
  state?: Nullable<string>
}

export interface IDocumentStatusInfo {
  icon: ReactNode
  textClass: string
}

export type IDraftDocumentConstraintViolations = IConstraintViolation[]

export type IDraftMessageConstraintViolations = IDraftDocumentConstraintViolations[]

export interface IUpdateDocumentFieldsRequestData {
  documentOguid: string
  initiatorOguid: Nullable<string>
  fields: IDocumentFields
  orgOguid: string
  subOrgOguid: Nullable<string>
  details: IDocumentDetailsField[]
}

export interface ISendMessages {
  orgOguid: string
  toOrg: string
  packageKey: boolean
  proxyOrg: string
  assignedToUserOguid: string
  isFlowRequired?: boolean
  type?: string
}

export interface ISendDocument {
  isRequireRecipientSignature: boolean
  orgOguid?: string
  packageKey: boolean
  recipients: Nullable<IDocumentRecipient[]>
  isFlowRequired?: boolean
}

export interface ISendDocumentData {
  comment?: Nullable<string>
  direction?: DocumentDirection | string
  externalId?: Nullable<string>
  details: IDocumentDetailsField[]
  fields: IDocumentValuesFields
  initialDocumentOguid?: Nullable<string>
  initiatorOguid?: Nullable<string>
  packageKey?: Nullable<number>
  previousVersionDocumentOguid?: Nullable<string>
  previousVersionMode?: PreviousVersionMode
  signedContent: Nullable<ISignedContent>
  subOrgOguid?: Nullable<string>
  type: string
  parentOguid?: Nullable<string>
  version?: Nullable<string>
  preProcessingJobOguid?: Nullable<string>
}

export interface IFlowStageTask {
  assignMode: Nullable<AssignMode>
  assignedToGroupOguid: Nullable<string>
  assignedToUserOguid: Nullable<string>
}

export interface IFlowStageAssignee {
  assignMode: Nullable<AssignMode>
  assignedToGroupOguid:Nullable<string>
  assignedToUserOguid: Nullable<string>
  assignedToCalculatedUser?: Nullable<ILibPresetValue>
  assignedToManagerOfSubOrgOguid?: Nullable<string>
  hoursToAutoAction?: Nullable<number>
  autoReassignToUserOguid?: Nullable<string>
  autoReassignToGroupOguid?: Nullable<string>
  autoReassignToOwner?: Nullable<boolean>
  autoReassignToManagerOfSubOrgOguid?: Nullable<string>
}

export interface IFlowStage {
  userName: Nullable<string>
  type: string
  constraints?: Nullable<Array<string | IConstraintToSet>>
  hint?: Nullable<string>
  hoursToProcess?: Nullable<number>
  isMilestone?: boolean
  assignees: IFlowStageAssignee[]
}

export interface IFlowStages {
  stages: IFlowStage[]
}

export interface ISendDocumentRequest {
  documents: ISendDocumentData[]
  flowStages?: Nullable<IFlowStages>
  isFlowRequired?: boolean
  isIgnoreConstraints?: boolean
}

export interface ISetCurrentDraftDocumentMessageParams {
  fileData: IFileData
  isClean?: boolean
  isMessage?: boolean
  templateBased?: boolean
  type?: string
  uuid: string
  subOrgOguid: Nullable<string>
}

export interface IGenerateTemplateFileData {
  comment: Nullable<string>
  direction: string
  fields: IDocumentFields
  initiatorOguid: Nullable<string>
  subOrgOguid: Nullable<string>
}

// actions

export enum DocumentsActionTypes {
  CLEAR_CURRENT_DOCUMENT_FILES = 'CLEAR_CURRENT_DOCUMENT_FILES',
  CLEAR_CURRENT_DRAFT_DOCUMENTS = 'CLEAR_CURRENT_DRAFT_DOCUMENTS',
  REMOVE_CURRENT_DRAFT_DOCUMENT = 'REMOVE_CURRENT_DRAFT_DOCUMENT',
  CLEAR_CURRENT_DRAFT_MESSAGES = 'CLEAR_CURRENT_DRAFT_MESSAGES',
  REMOVE_CURRENT_DRAFT_MESSAGE = 'REMOVE_CURRENT_DRAFT_MESSAGE',
  RESET_DOCUMENTS = 'RESET_DOCUMENTS',
  RESET_CURRENT_DOCUMENT = 'RESET_CURRENT_DOCUMENT',
  RESET_SELECTED_DOCUMENT = 'RESET_SELECTED_DOCUMENT',
  SELECT_DOCUMENT = 'SELECT_DOCUMENT',
  SELECT_DOCUMENTS = 'SELECT_DOCUMENTS',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  SET_DOCUMENTS_MESSAGES = 'SET_DOCUMENTS_MESSAGES',
  SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT',
  SET_CURRENT_DOCUMENT_FILE_FIELDS = 'SET_CURRENT_DOCUMENT_FILE_FIELDS',
  SET_CURRENT_DOCUMENT_FILES = 'SET_CURRENT_DOCUMENT_FILES',
  SET_CURRENT_DOCUMENT_SIGN_INFO = 'SET_CURRENT_DOCUMENT_SIGN_INFO',
  SET_CURRENT_DRAFT_DOCUMENT = 'SET_CURRENT_DRAFT_DOCUMENT',
  SET_CURRENT_DRAFT_MESSAGE = 'SET_CURRENT_DRAFT_MESSAGE',
  UPDATE_CURRENT_DRAFT_DOCUMENT = 'UPDATE_CURRENT_DRAFT_DOCUMENT',
  UPDATE_CURRENT_DRAFT_MESSAGE = 'UPDATE_CURRENT_DRAFT_MESSAGE',
  SET_DOCUMENT_RELATIONS = 'SET_DOCUMENT_RELATIONS',
  SET_FLOW_STATE = 'SET_FLOW_STATE',
  RESET_FLOW_STATE = 'RESET_FLOW_STATE',
  SET_TODOS = 'SET_TODOS',
  SET_ATTACHMENTS = 'SET_ATTACHMENTS',
  SET_RELOAD_CURRENT_DOCUMENT_FILE = 'SET_RELOAD_CURRENT_DOCUMENT_FILE',
  UPDATE_UNREAD_TODOS_COUNT = 'UPDATE_UNREAD_TODOS_COUNT',
}

export interface IActionClearCurrentDocumentFiles extends Action<string> {
  type: DocumentsActionTypes.CLEAR_CURRENT_DOCUMENT_FILES
}

export interface IActionClearCurrentDraftDocuments extends Action<string> {
  type: DocumentsActionTypes.CLEAR_CURRENT_DRAFT_DOCUMENTS
}

export interface IActionRemoveCurrentDraftDocument extends Action<string> {
  payload: IDraftDocuments
  type: DocumentsActionTypes.REMOVE_CURRENT_DRAFT_DOCUMENT
}

export interface IActionClearCurrentDraftMessages extends Action<string> {
  type: DocumentsActionTypes.CLEAR_CURRENT_DRAFT_MESSAGES
}

export interface IActionRemoveCurrentDraftMessage extends Action<string> {
  payload: IDraftMessages
  type: DocumentsActionTypes.REMOVE_CURRENT_DRAFT_MESSAGE
}

export interface IActionResetDocuments extends Action<string> {
  type: DocumentsActionTypes.RESET_DOCUMENTS
}

export interface IActionSetDocuments extends Action<string> {
  payload: IDocument[]
  type: DocumentsActionTypes.SET_DOCUMENTS
}

export interface IActionSetMessages extends Action<string> {
  payload: IMessage[]
  type: DocumentsActionTypes.SET_DOCUMENTS_MESSAGES
}

export interface IActionSelectDocument extends Action<string> {
  payload: string
  type: DocumentsActionTypes.SELECT_DOCUMENT
}

export interface IActionSelectDocuments extends Action<string> {
  payload: ISelectedDocuments
  type: DocumentsActionTypes.SELECT_DOCUMENTS
}

export interface IActionResetSelectedDocuments extends Action<string> {
  type: DocumentsActionTypes.RESET_SELECTED_DOCUMENT
}

export interface IActionSetCurrentDocument extends Action<string> {
  payload: IDocument
  type: DocumentsActionTypes.SET_CURRENT_DOCUMENT
}

export interface IActionResetCurrentDocument extends Action<string> {
  type: DocumentsActionTypes.RESET_CURRENT_DOCUMENT
}

export interface IActionSetCurrentDocumentFileFields extends Action<string> {
    payload: Record<string, IFileFieldValue | IFileFieldValue[]>
    type: DocumentsActionTypes.SET_CURRENT_DOCUMENT_FILE_FIELDS
}

export interface IActionSetCurrentDocumentFiles extends Action<string> {
  payload: IDocumentFiles
  type: DocumentsActionTypes.SET_CURRENT_DOCUMENT_FILES
}

export interface IActionSetCurrentDocumentSignInfo extends Action<string> {
  payload: IDocumentSignInfo
  type: DocumentsActionTypes.SET_CURRENT_DOCUMENT_SIGN_INFO
}

export interface IActionSetCurrentDraftDocument extends Action<string> {
  payload: IDraftDocuments
  type: DocumentsActionTypes.SET_CURRENT_DRAFT_DOCUMENT
}

export interface IActionSetCurrentDraftMessage extends Action<string> {
  payload: IDraftMessages
  type: DocumentsActionTypes.SET_CURRENT_DRAFT_MESSAGE
}

export interface IActionUpdateCurrentDraftDocument extends Action<string> {
  payload: IDraftDocuments
  type: DocumentsActionTypes.UPDATE_CURRENT_DRAFT_DOCUMENT
}

export interface IActionUpdateCurrentDraftMessage extends Action<string> {
  payload: IDraftMessages
  type: DocumentsActionTypes.UPDATE_CURRENT_DRAFT_MESSAGE
}

export interface IActionSetDocumentRelation extends Action<string> {
  payload: IRelations
  type: DocumentsActionTypes.SET_DOCUMENT_RELATIONS
}

export interface ISetFlowStateAction extends Action<string> {
  payload: IFlowState
  type: DocumentsActionTypes.SET_FLOW_STATE
}

export interface IResetFlowStateAction extends Action<string> {
  type: DocumentsActionTypes.RESET_FLOW_STATE
}

export interface ISetTodosAction extends Action<string> {
  payload: ITodo[]
  type: DocumentsActionTypes.SET_TODOS
}

export interface ISetAttachmentsAction extends Action<string> {
  payload: IAttachment[]
  type: DocumentsActionTypes.SET_ATTACHMENTS
}

export interface IActionSetReloadCurrentDocumentFileAction extends Action<string> {
  payload: boolean,
  type: DocumentsActionTypes.SET_RELOAD_CURRENT_DOCUMENT_FILE
}

export interface IActionUpdateUnreadTodosCount extends Action<string> {
  type: DocumentsActionTypes.UPDATE_UNREAD_TODOS_COUNT
}

export type Actions =
  | IActionClearCurrentDocumentFiles
  | IActionClearCurrentDraftDocuments
  | IActionRemoveCurrentDraftDocument
  | IActionClearCurrentDraftMessages
  | IActionRemoveCurrentDraftMessage
  | IActionResetDocuments
  | IActionResetCurrentDocument
  | IActionSetDocuments
  | IActionSetMessages
  | IActionSelectDocument
  | IActionSelectDocuments
  | IActionSetCurrentDocument
  | IActionSetCurrentDocumentFileFields
  | IActionSetCurrentDocumentFiles
  | IActionSetCurrentDocumentSignInfo
  | IActionSetCurrentDraftDocument
  | IActionSetCurrentDraftMessage
  | IActionUpdateCurrentDraftDocument
  | IActionUpdateCurrentDraftMessage
  | IActionSetDocumentRelation
  | ISetAttachmentsAction
  | ISetFlowStateAction
  | IResetFlowStateAction
  | ISetTodosAction
  | IActionResetSelectedDocuments
  | IActionSetReloadCurrentDocumentFileAction
  | IActionUpdateUnreadTodosCount

type ActionKeys =
  | 'clearCurrentDocumentFiles'
  | 'clearCurrentDraftDocuments'
  | 'removeCurrentDraftDocument'
  | 'clearCurrentDraftMessages'
  | 'removeCurrentDraftMessage'
  | 'setDocuments'
  | 'setMessages'
  | 'selectDocument'
  | 'selectDocuments'
  | 'setCurrentDocument'
  | 'setCurrentDocumentFileFields'
  | 'setCurrentDocumentFiles'
  | 'setCurrentDocumentSignInfo'
  | 'setCurrentDraftDocument'
  | 'setCurrentDraftMessage'
  | 'updateCurrentDraftDocument'
  | 'updateCurrentDraftMessage'
  | 'setAttachments'
  | 'setDocumentRelations'
  | 'setFlowState'
  | 'setTodos'
  | 'setReloadCurrentDocumentFile'

export interface IDocumentsState {
  attachments: IAttachment[]
  currentDocument: IDocument
  currentDocumentFileFields: Record<string, IFileFieldValue | IFileFieldValue[]>
  currentDocumentFiles: IDocumentFiles
  currentDocumentSignInfo: IDocumentSignInfo
  draftDocuments: IDraftDocuments
  draftMessages: IDraftMessages
  documents: IDocument[]
  messages: IMessage[]
  selectedDocuments: ISelectedDocuments
  relations: IRelations
  flowState: IFlowState
  todos: ITodo[]
}

export type DocumentsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type DocumentsThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>

export type DocumentsThunkDispatch = ThunkDispatch<IDocumentsState, void, Action<string>>

export interface IFileSharedLinkBody {
  attachmentOguid: Nullable<string>
  jobOguid: Nullable<string>
  expiredInDays: Nullable<number>
}
