import cn from 'classnames'
import React, { ChangeEvent, FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { ExpirationInDaysValue, DEFAULT_EXPIRED_IN_DAYS } from '@views/organization/documents/components/Document/components/File/consts'
import {
  Button,
  Checkbox,
  FormLabel,
  IconCopy,
  IconShare,
  IconTimes,
  Input,
  LibCheckboxState
} from '@infologistics/frontend-libraries'

import DocumentsService from '@services/documents'

import { getBaseUrl } from '@utils/utils'

import { Nullable } from '@store/types/commonTypes'
import { IFileSharingProps as IProps } from '../types'
import { IFileSharedLinkBody } from '@store/modules/documents/types'

import styles from './FileSharing.module.css'

const TIMEOUT = 500

const FileSharing: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { buttonClasses, documentAttachmentOguid, orgOguid } = props
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false)
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)
  const [linkHref, setLinkHref] = useState<Nullable<string>>(null)
  const [isExpirationError, setIsExpirationError] = useState<boolean>(false)
  const [values, setValues] = useState<IFileSharedLinkBody>({
    attachmentOguid: documentAttachmentOguid,
    expiredInDays: DEFAULT_EXPIRED_IN_DAYS,
    jobOguid: null
  })
  const [errors, setErrors] = useState({
    copy: null,
    create: null
  })
  const [noExpire, setNoExpire] = useState<boolean>(false);

  const classNames = {
    enter: 'fl-modal-enter',
    enterActive: 'fl-modal-enter_active',
    exit: 'fl-modal-exit',
    exitActive: 'fl-modal-exit_active'
  }

  const labelClasses = 'font-xs mb-1'

  const handleCloseModal = (): void => {
    setIsModalOpen(false)
    setErrors({ create: null, copy: null })
  }

  const handleOpenModal = (): void => {
    setIsModalOpen(true)
  }

  const displayNotification = (): void => {
    setIsShowNotification(true)
    setTimeout(() => {
      setIsShowNotification(false)
    }, 2000)
  }

  const handleCreateLink = (): void => {
    const newValues = {
      ...values,
      expiredInDays: noExpire?null:values.expiredInDays
    }

    DocumentsService.generateSharedFileId(newValues)
      .then((resp) => {
        const { oguid } = resp.data

        if (oguid && orgOguid) {
          setLinkHref(`${getBaseUrl()}shared/${orgOguid}/sharedFileLink/${oguid}`)
        }
      })
      .catch(() => {
        setErrors({ ...errors, create: t('document:links.createLinkError') })
        displayNotification()
      })
  }

  const handleCopyLink = (): void => {
    if (!linkHref) return

    navigator.clipboard.writeText(linkHref.toString())
      .then(() => { setIsLinkCopied(true) })
      .catch(() => { setErrors({ ...errors, copy: t('document:links.copyLinkError') }) })
      .finally(displayNotification)
  }

  const handleExpirationChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    const { value } = evt.target
    const formattedValue = Number(value)

    if (isNaN(formattedValue)) return

    setIsExpirationError(formattedValue > ExpirationInDaysValue.max || formattedValue < ExpirationInDaysValue.min)
    setValues({ ...values, expiredInDays: formattedValue })
  }

  const handleCheckedChange = () => {
    setNoExpire(!noExpire);
  }

  const renderNotification = (): ReactElement => {
    const errorText = errors.copy ?? errors.create
    const bgColor = errorText ? 'bg-danger-400' : 'bg-success'

    return (
      <CSSTransition in={isShowNotification} timeout={TIMEOUT} unmountOnExit={true} classNames={classNames}>
        <div className={cn(styles.notification,'absolute px-4 py-2 text-light text-nowrap', bgColor)}>
          {errorText && <span>{errorText}</span>}
          {isLinkCopied && <span>{t('document:links.copy')}</span>}
        </div>
      </CSSTransition>
    )
  }

  const renderCreateLink = (): ReactElement => <>
    <div>
      <div className='d-flex align-items-end mb-4'>
        <div className={cn(styles.expiration,'mr-4')}>
          <FormLabel text={t('document:links.expires')} classes={labelClasses} htmlFor='expiredInDays'/>
          <Input
            id='expiredInDays'
            value={values.expiredInDays}
            name='expiredInDays'
            onChange={handleExpirationChange}
            maxLength={2}
            hasError={isExpirationError}
            errorText={t('document:links.expirationError')}
            disabled={noExpire}
          />
        </div>
        <Button theme='primary' onClick={handleCreateLink} isDisabled={isExpirationError && !noExpire}>
          {t('document:links.create')}
        </Button>
      </div>
      <div className='d-flex'>
        <FormLabel
          classes={cn(labelClasses, 'mr-1')}
          text={t('document:links.noLimits')}
          htmlFor='saveFile'
        />
        <Checkbox
          name='saveFile'
          id='saveFile'
          checked={noExpire?LibCheckboxState.CHECKED:LibCheckboxState.UNCHECKED}
          onChange={handleCheckedChange}
        />
      </div>
    </div>
  </>

  const renderCopyLink = (): ReactElement => <>
    <div className='mr-4 full-width'>
      <FormLabel text={t('document:links.link')} classes={labelClasses} htmlFor='linkHref'/>
      <Input
        id='linkHref'
        value={linkHref}
        name='linkHref'
        onChange={() => null}
        readOnly
      />
    </div>
    <Button theme='text' onClick={handleCopyLink} classes={cn(styles.copy_button, 'd-flex p-2 justify-content-center align-items-center')}>
      <IconCopy color='gray-500' hint={t('common:copy')} size='sm' />
    </Button>
  </>

  return (
    <>
      <Button theme='text' onClick={handleOpenModal} classes={buttonClasses} title={t('document:links.share')}>
        <IconShare externalClass={styles.button_icon} />
      </Button>

      <CSSTransition in={isModalOpen} timeout={TIMEOUT} unmountOnExit={true} classNames={classNames}>
        <div className={cn(styles.wrapper,'absolute at-0 bg-light p-4 d-flex flex-column')}>
          <Button theme='text' onClick={handleCloseModal} classes='absolute atr-2'>
            <IconTimes color='gray-500' hint={t('common:close')} size='xs' />
          </Button>

          <div className='d-flex align-items-end'>
            { linkHref ? renderCopyLink() : renderCreateLink() }
          </div>
          {renderNotification()}
        </div>
      </CSSTransition>
    </>
  )
}

export default FileSharing
