export const SCALE_BREAKPOINTS = [25, 50, 75, 100, 125, 150, 200]

export const DEFAULT_SCALE = 100

export const TYPE_ZIP = 'application/zip'

export const THUMBNAILS_TOOLBAR_HEIGHT = 45

export const VIEWER_WRAPPER_PADDING_LEFT = 16

export const PAGE_MARGIN_BOTTOM = 20
export const PROTECTED_FILE_ERROR_NAME = 'PasswordException'
export const FILE_UNEXPECTED_ERROR_NAME = 'UnexpectedResponseException'

export enum LinkTarget {
  NONE = 0,
  SELF= 1,
  BLANK= 2,
  PARENT= 3,
  TOP = 4
}

export enum LinkAction {
  GO_BACK = 'GoBack',
  GO_FORWARD = 'GoForward',
  NEXT_PAGE = 'NextPage',
  PREV_PAGE = 'PrevPage',
  LAST_PAGE = 'LastPage',
  FIRST_PAGE = 'FirstPage',
}

export enum RenderingState {
  INITIAL = 0,
  RUNNING = 1,
  PAUSED = 2,
  FINISHED = 3
}

export const DEFAULT_LINK_REL = 'noopener noreferrer nofollow'

export enum PdfScrollMode {
  UNKNOWN = -1,
  VERTICAL = 0, // Default value
  HORIZONTAL = 1,
  WRAPPED = 2,
  PAGE = 3,
}

export const ExpirationInDaysValue = {
  min: 1,
  max: 90
}

export const DEFAULT_EXPIRED_IN_DAYS = 30
