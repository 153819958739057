import { Action, ActionCreator } from 'redux'

import { AccessMode, ApplyToExternal, DependencyType, FieldType, FormProperty, MathOperationType, SumTableConditionOperatior, TextAlign } from '@const/consts'
import { IApplicationState, ModuleThunkDispatch, Nullable } from '@store/types/commonTypes'
import { StageType } from '@store/modules/routes/types'
import { IFieldSplitter } from '@views/administration/components/metadata/DocumentTypes/components/DocumentType/types';
import { IDocumentDetailsField, IDocumentValuesFields, ISignedContent } from '../documents/types';

// document types reference

export interface ISplitter {
  caption: Nullable<string>
}

export interface IFormPropertiesObject {
  visible: boolean
  // order: number
  // width: number
}

export interface IFormPropertiesFormObject extends IFormPropertiesObject {
  formCreateVisible: boolean
  splitter: Nullable<ISplitter>
}

export interface IFormProperties {
  [FormProperty.CARD]: IFormPropertiesFormObject
  [FormProperty.LIST]: IFormPropertiesObject
}

export type DataType = keyof typeof FieldType

export interface ILocalization {
  [key: string]: string
}

export interface IComponent {
  componentName: Nullable<string>
  labels: ILocalization
  hints: Nullable<ILocalization>
  placeholders: Nullable<ILocalization>
  regexp: Nullable<string>
}

export interface IFieldComponent {
  componentName: Nullable<string>
  label: string
  hint: Nullable<string>
  placeholder: Nullable<string>
  regexp: Nullable<string>
}

export interface IDocumentTypeField {
  dictType: Nullable<string>
  isArray: boolean
  isRequired: boolean
  isSystem: boolean
  type: DataType
  formProperties: Nullable<IFormProperties>
  component: IFieldComponent
  key?: string
  index: number
}

export interface IDocumentTypeFieldFromServer extends IDocumentTypeField {
  key: string
}

export interface IDocumentTypeFields {
  [key: string]: IDocumentTypeField
}

export interface IDocumentTypeGroup {
  name: string
  oguid: string
}

export interface IDocumentTypeTable {
  fields: ITableField[]
  summaryFields: Nullable<ISummaryTableField[]>
}

export interface IDocumentTypeTableForServer {
  fields: ITableFieldForServer[]
  summaryFields: Nullable<ISummaryTableFieldForServer[]>
}

export interface IDocumentTypeTemplate {
  autoDocumentNumber: Nullable<IAutoDocumentNumber>
}

export interface ITableFieldForServer {
  accessMode: AccessMode
  isAccessModeInherited?: boolean
  isHidden: boolean
  isRequired: boolean
  key: string
  textAlign: Nullable<TextAlign>
  width: number
  order: Nullable<number>
}

export interface ITableField extends ITableFieldForServer {
  component: IComponent
  dictType: Nullable<string>
  type: DataType
}

export interface ISummaryTableFieldForServer {
  fieldKey: string
  sumTableFieldKey: string
  sumTableConditionFieldKey: string
  sumTableConditionFieldValues: Nullable<string[]>
  sumTableCondition: Nullable<SumTableConditionOperatior>
  order: Nullable<number>
}

export interface ISummaryTableField extends ISummaryTableFieldForServer {
  component: IFieldComponent
  dictType: Nullable<string>
  type: DataType
}

export interface IDocumentTypeDependentField {
  fieldKey: string
  dependencyType: DependencyType
  mathOperationType: Nullable<MathOperationType>
  mathOperationRoundTo: Nullable<number>
  sourceFieldKeys: string[]
  order: Nullable<number>
}

export enum IPreProcessingOcrVolume {
  ALL = "ALL",
  ONLY_FIRST_PAGE = "ONLY_FIRST_PAGE"
}

export enum IPreProcessingOcrType {
  STANDARD = "STANDARD",
  ADVANCED = "ADVANCED"
}

export interface IPreProcessingPageRange {
  pageFrom: number,
  pageTo: number
}

export enum IPreProcessingAiModelType {
  STANDARD = "STANDARD",
  ADVANCED = "ADVANCED"
}

export enum IPreProcessingAiExecutionMode {
  SYNC = "SYNC",
  BATCH = "BATCH"
}

export interface IPreProcessingAiProcessingProperties {
  executionMode: IPreProcessingAiExecutionMode
  fields: Nullable<string[]>
  isAddDictionary: boolean
  messageContent: Nullable<string>
  modelType: IPreProcessingAiModelType
  processOnlyFirstTokensQuantity: Nullable<number>
  pythonScript: Nullable<string>
  tools: Nullable<string>
  onlyPages?: Nullable<IPreProcessingPageRange>
}

export interface IPreProcessingProperties {
  ocrVolume?: IPreProcessingOcrVolume
  ocrType: IPreProcessingOcrType
  ocrLang: string
  ocrOnlyPages?: Nullable<IPreProcessingPageRange>
  isSearchablePdf: boolean
  aiProcessingProperties: Nullable<IPreProcessingAiProcessingProperties>
}

export enum IPreProcessingJobStatus {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  IN_QUEUE = "IN_QUEUE",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  STOPPED = "STOPPED",
}

export enum IPreProcessingJobErrorCode {
  QUEUE_SERVICE_UNAVAILABLE = "QUEUE_SERVICE_UNAVAILABLE",
  FILE_SERVICE_UNAVAILABLE = "FILE_SERVICE_UNAVAILABLE",
  RECOGNITION_EXECUTION = "RECOGNITION_EXECUTION",
  RECOGNITION_EXCEPTION = "RECOGNITION_EXCEPTION",
  AI_SERVICE_UNAVAILABLE = "AI_SERVICE_UNAVAILABLE",
  AI_SERVICE_EXCEPTION = "AI_SERVICE_EXCEPTION",
  PYTHON_SERVICE_UNAVAILABLE = "PYTHON_SERVICE_UNAVAILABLE",
  PYTHON_SERVICE_EXCEPTION = "PYTHON_SERVICE_EXCEPTION",
  DF_PATCH = "DF_PATCH",
}

export type PreProcessingDetailsType = (number | null) | string | boolean;

export interface IPreProcessingDetails {
  [key: string]: string
}

export interface IPreProcessingJobDetails {
  [key: string]: PreProcessingDetailsType;
}

export interface IPreProcessingJobFields {
  [key: string]: string | IPreProcessingJobDetails[];
}
export interface IPreProcessingFields extends IPreProcessingJobFields {
  documentType: string;
}

export interface IPreProcessingJob {
  oguid: Nullable<string>
  status: IPreProcessingJobStatus
  errorCode: Nullable<IPreProcessingJobErrorCode>
  errorDescription: Nullable<string>
  time: Nullable<number>
  fields: IPreProcessingJobFields
}

export interface IPreProcessingInfo {
  preProcessingJobOguid: Nullable<string>
  errorCode: Nullable<IPreProcessingJobErrorCode>
  errorDescription: Nullable<string>
  started: Nullable<number>
  finished: Nullable<number>
  ocrType: Nullable<IPreProcessingOcrType>
  ocrPages: Nullable<number>
  aiModelType: IPreProcessingAiModelType
  aiExecutionMode: IPreProcessingAiExecutionMode
  aiTokensIn: Nullable<number>
  aiTokensOut: Nullable<number>
}

export interface IPreProcessingFieldsMatch {
  fields: IPreProcessingFields;
  details: IPreProcessingDetails[];
}

export interface IFieldsMatchDocument {
  documentType: string
  initiator?: Nullable<string>
  subOrg?: Nullable<string>
  fields: IDocumentValuesFields
  packageKey?: Nullable<number>
  comment?: Nullable<string>
  details: IDocumentDetailsField[]
  signedContent: ISignedContent
}

export enum IFieldsMatchErrorsCode {
  NOT_EXIST = "NOT_EXIST",
  IS_REQUIRED = "IS_REQUIRED",
  INVALID_FIELD = "INVALID_FIELD",
  ENUM_ERROR = "ENUM_ERROR",
  INT_ERROR = "INT_ERROR",
  BOOLEAN_ERROR = "BOOLEAN_ERROR",
  DATE_ERROR = "DATE_ERROR",
  NUMBER_ERROR = "NUMBER_ERROR",
  CURRENCY_ERROR = "CURRENCY_ERROR",
}

export interface IFieldsMatchError {
  field: string
  code: IFieldsMatchErrorsCode
  isRequired: boolean
}

export interface IFieldsMatchSolo {
  document: IFieldsMatchDocument
  errors: IFieldsMatchError[]
}

export interface IFieldsMatch {
  documents: IFieldsMatchSolo[]
}

export interface IDocumentType {
  title: string
  hint: string
  comment: string
  group: Nullable<IDocumentTypeGroup>
  hasTemplate: boolean
  isFileType: boolean
  isFormal: boolean
  isRequireRecipientSignature: Nullable<boolean>
  isSystem: boolean
  fields: IDocumentTypeFields
  table: Nullable<IDocumentTypeTable>
  dependentFields: Nullable<IDocumentTypeDependentField[]>
  template: IDocTypeTemplate
  preProcessingProperties: Nullable<IPreProcessingProperties>
}

export interface IDocumentTypeWithFieldsArray extends Omit<IDocumentType, 'fields'> {
  fields: IDocumentTypeFieldFromServer[]
}

export interface IDocTypeTemplate {
  autoDocumentNumber: IAutoDocumentNumber
}

export interface IAutoDocumentNumber {
  length: number
  prefix: string
}

export interface IUserDocumentType extends IDocumentType {
  isAllowedToCreate: boolean
}

export interface IUserDocumentTypeWithFieldsArray extends Omit<IUserDocumentType, 'fields'> {
  fields: IDocumentTypeFieldFromServer[]
}

export interface IDocumentTypeFromServer {
  comments: Nullable<ILocalization>
  fields: IField[]
  group: Nullable<IDocumentTypeGroup>
  hasTemplate: boolean
  hints: Nullable<ILocalization>
  isFileType: boolean
  key: string
  titles: ILocalization
  table: Nullable<IDocumentTypeTable>
  dependentFields: Nullable<IDocumentTypeDependentField[]>
  template: Nullable<IDocumentTypeTemplate>
  preProcessingProperties: Nullable<IPreProcessingProperties>
}

export interface IDocumentTypeForServer {
  comments: Nullable<ILocalization>
  documentGroupOguid: Nullable<string>
  fields: Array<IFieldForServer|IFieldSplitter>
  hints: Nullable<ILocalization>
  key: string
  isFileType: boolean
  titles: ILocalization
  table: Nullable<IDocumentTypeTableForServer>
  dependentFields: Nullable<IDocumentTypeDependentField[]>
  template?: Nullable<IDocumentTypeTemplate>
  preProcessingProperties: Nullable<IPreProcessingProperties>
}

export interface IDocumentTypes {
  [key: string]: IDocumentType
}

export interface IDocumentTypesFromServer {
  [key: string]: IDocumentTypeWithFieldsArray
}

export interface IUserDocumentTypes {
  [key: string]: IUserDocumentType
}

export interface IUserDocumentTypesFromServer {
 [key: string]: IUserDocumentTypeWithFieldsArray
}

export type AccessGroupType = 'CREATE' | 'CREATE_ALL' | 'READONLY_ALL'

export interface IAccessGroup {
  hasDocumentTypes: boolean
  name: string
  oguid: string
  type: AccessGroupType
}

export interface IAccessGroupForServer {
  documentTypeGroupsOguids: string[]
  documentTypeKeys: string[]
  name: string
}

export interface IAccessGroupDocTypes {
  key: string,
  title: string
}

export interface IAccessGroupDocTypeGroups {
  name: string,
  oguid: string
}

export interface ISingleAccessGroup {
  documentTypes: IAccessGroupDocTypes[]
  documentTypeGroups: IAccessGroupDocTypeGroups[]
  hasDocumentTypes: boolean
  name: string
  oguid: string
}

// work statuses reference

export type Severity = 'ERROR' | 'INFO' | 'SUCCESS' | 'UNKNOWN' | 'WARNING'
export type WorkflowStatusGroup = 'COMPLETED' | 'ERROR' | 'NEED_TO_PROCESS' | 'SUCCESS' | 'WAITING_FOR_CONTRACTOR'

export interface IWorkflowStatus {
  severity: Severity
  caption: string
  group: WorkflowStatusGroup
}

export interface IWorkflowStatuses {
  [key: string]: IWorkflowStatus
}

// field types reference
export interface IField {
  accessMode: AccessMode
  applyToExternal: ApplyToExternal
  component: IComponent
  dictType: Nullable<string>
  formProperties: Nullable<IFormProperties>
  isAccessModeInherited?: boolean
  isAllowMultipleValues?: boolean
  isArray: boolean
  isOverriding?: boolean
  isRequired?: boolean
  isSystem?: boolean
  key: string
  type: FieldType
}

export interface IFieldForServer {
  accessMode: AccessMode
  formProperties: Nullable<IFormProperties>
  isAccessModeInherited?: boolean
  isRequired?: boolean
  key: string
}

export interface IFlowStageType {
  buttonCaption: string
  declinedCaption: string
  name: string
  solvedCaption: string
  taskType: StageType
  type: string
}

export enum HistoryStateCodeGroup {
  APPROVING = 'APPROVING',
  CREATING = 'CREATING',
  DECLINING = 'DECLINING',
  QUESTION = 'QUESTION',
  RECEIVING = 'RECEIVING',
  SENDING = 'SENDING',
  SIGNING = 'SIGNING',
  STOP = 'STOP',
  UNKNOWN = 'UNKNOWN'
}

export interface IHistoryStateCode {
  captionFemale: string
  captionMale: string
  group: HistoryStateCodeGroup
  severity: Severity
}

export interface IHistoryStateCodes {
  [key: string]: IHistoryStateCode
}

export interface IMarkIsRead {
  taskOguids: string[],
  isRead: boolean
}

// actions
export enum MetadataActionTypes {
  RESET_METADATA = 'RESET_METADATA',
  SET_ACCESS_GROUPS = 'SET_ACCESS_GROUPS',
  SET_DICT_TYPES = 'SET_DICT_TYPES',
  SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES',
  SET_DOCUMENT_TYPE_GROUPS = 'SET_DOCUMENT_TYPE_GROUPS',
  SET_FIELDS = 'SET_FIELDS',
  SET_FLOW_STAGE_NAMES = 'SET_FLOW_STAGE_NAMES',
  SET_FLOW_STAGE_TYPES = 'SET_FLOW_STAGE_TYPES',
  SET_HISTORY_STATE_CODES = 'SET_HISTORY_STATE_CODES',
  SET_LOCALIZATIONS = 'SET_LOCALIZATIONS',
  SET_USER_DOC_TYPES = 'SET_USER_DOC_TYPES',
  SET_WORKFLOW_STATUSES = 'SET_WORKFLOW_STATUSES'
}

export interface IMetadataState {
  accessGroups: IAccessGroup[]
  dictTypes: string[]
  documentTypes: IDocumentTypes
  documentTypeGroups: IDocumentTypeGroup[]
  fields: IField[]
  flowStageNames: string[]
  flowStageTypes: IFlowStageType[]
  historyStateCodes: IHistoryStateCodes
  localizations: string[]
  userDocTypes: IUserDocumentTypes
  workflowStatuses: IWorkflowStatuses
}

export interface IActionResetMetadata extends Action<string> {
  type: MetadataActionTypes.RESET_METADATA
}

export interface IActionSetDictTypes extends Action<string> {
  payload: string[]
  type: MetadataActionTypes.SET_DICT_TYPES
}

export interface IActionSetAccessGroups extends Action<string> {
  payload: IAccessGroup[]
  type: MetadataActionTypes.SET_ACCESS_GROUPS
}

export interface IActionSetDocumentTypes extends Action<string> {
  payload: IDocumentTypesFromServer
  type: MetadataActionTypes.SET_DOCUMENT_TYPES
}

export interface IActionSetDocumentTypeGroups extends Action<string> {
  payload: IDocumentTypeGroup[]
  type: MetadataActionTypes.SET_DOCUMENT_TYPE_GROUPS
}

export interface IActionSetFields extends Action<string> {
  payload: IField[]
  type: MetadataActionTypes.SET_FIELDS
}

export interface IActionSetFlowStageNames extends Action<string> {
  payload: string[]
  type: MetadataActionTypes.SET_FLOW_STAGE_NAMES
}

export interface IActionSetFlowStageTypes extends Action<string> {
  payload: IFlowStageType[]
  type: MetadataActionTypes.SET_FLOW_STAGE_TYPES
}

export interface IActionSetHistoryStateCodes extends Action<string> {
  payload: IHistoryStateCodes
  type: MetadataActionTypes.SET_HISTORY_STATE_CODES
}

export interface IActionSetLocalizations extends Action<string> {
  payload: string[]
  type: MetadataActionTypes.SET_LOCALIZATIONS
}

export interface IActionSetUserDocTypes extends Action<string> {
  payload: IUserDocumentTypesFromServer
  type: MetadataActionTypes.SET_USER_DOC_TYPES
}

export interface IActionSetWorkflowStatuses extends Action<string> {
  payload: IWorkflowStatuses
  type: MetadataActionTypes.SET_WORKFLOW_STATUSES
}

export type Actions =
  | IActionResetMetadata
  | IActionSetAccessGroups
  | IActionSetDictTypes
  | IActionSetDocumentTypes
  | IActionSetDocumentTypeGroups
  | IActionSetFields
  | IActionSetFlowStageNames
  | IActionSetFlowStageTypes
  | IActionSetHistoryStateCodes
  | IActionSetLocalizations
  | IActionSetUserDocTypes
  | IActionSetWorkflowStatuses

type ActionKeys =
  | 'setAccessGroups'
  | 'setDictTypes'
  | 'setDocumentTypes'
  | 'setDocumentTypeGroups'
  | 'setFields'
  | 'setFlowStageNames'
  | 'setFlowStageTypes'
  | 'setHistoryStateCodes'
  | 'setLocalizations'
  | 'setUserDocTypes'
  | 'setWorkflowStatuses'

export type MetadataActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type MetadataThunkDispatch = ModuleThunkDispatch<IApplicationState>
