import { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';

import AbstractHttpService from '../abstractHttpService';
import urls from '@app/const/urls';
import { IApplicationFull, IApplicationCreate, IApplication, IApplicationEdit, IApplicationPatch } from '@app/store/modules/applications/types';

class ApplicationsService extends AbstractHttpService {
  private readonly url = urls.applications;

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/orgGetUserAppsV1
  async getUserList(): Promise<AxiosResponse<IApplication[]>> {
    const url = this.url.userList;

    return await this._http.get(url)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/listApps
  async getList(): Promise<AxiosResponse<IApplicationFull[]>> {
    const url = this.url.list;

    return await this._http.get(url)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/getApp
  async getApp(oguid: string): Promise<AxiosResponse<IApplicationFull[]>> {
    const url = this.url.single.replace('{{ oguid }}', oguid);

    return await this._http.get(url)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/createApp
  async createApp(data: IApplicationEdit): Promise<AxiosResponse<IApplicationCreate>> {
    const url = this.url.list;

    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'multipart/form-data'
    }
    const config: AxiosRequestConfig = { headers }

    return await this._http.post(url, data, config)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/updateApp
  async updateApp(oguid: string, data: IApplicationEdit): Promise<AxiosResponse<IApplicationCreate>> {
    const url = this.url.single.replace('{{ oguid }}', oguid);

    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'multipart/form-data'
    }
    const config: AxiosRequestConfig = { headers }
    return await this._http.put(url, data, config)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/patchApp
  async patchApp(oguid: string, data: IApplicationPatch): Promise<AxiosResponse<IApplicationCreate>> {
    const url = this.url.single.replace('{{ oguid }}', oguid);

    return await this._http.patch(url, data)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/deleteApp
  async deleteApp(oguid: string): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', oguid);

    return await this._http.delete(url)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsApps/operation/deployApp
  async deployApp(oguid: string): Promise<AxiosResponse> {
    const url = this.url.deploy.replace('{{ oguid }}', oguid);

    return await this._http.post(url, null)
  }
}

export default new ApplicationsService()
