import { Action, ActionCreator } from 'redux';

import {
  FilePreviewActionTypes,
  IFilePreview,
  IFilePreviewClose,
  IFilePreviewSet,
} from './types';

export const setPreview: ActionCreator<Action> = (
  data: IFilePreview
): IFilePreviewSet => ({
  payload: data,
  type: FilePreviewActionTypes.SET_PREVIEW,
});

export const closePreview: ActionCreator<
  Action
> = (): IFilePreviewClose => ({
  type: FilePreviewActionTypes.CLOSE_PREVIEW,
});
