import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  IDocument,
  IFileSharedLinkBody,
  IFlowState,
  IMessage,
  IPackageBody,
  IRelations,
  ISendDocumentRequest, ITask, ITodo, ITodoBody, IUpdatedFlowStage
} from '@store/modules/documents/types'
import { needResetParams } from '@const/consts'
import { IReceipt } from '@store/modules/receipts/types'
import { IPagination } from '@infologistics/frontend-libraries/dist/components/Pagination/types'
import { IParams } from '@store/types/commonTypes'
import { IDocumentsDownloadRequest } from '@common/DocumentsDownloadModal/types'

class DocumentsService extends AbstractHttpService {
  private readonly url = urls.documents

  // `${BASE_URL}orgs/${orgOguid}/documents/exportExcel`
  async exportExcel(params: any): Promise<AxiosResponse> {
    const url = this.url.list.exportExcel

    const config: AxiosRequestConfig = { params }

    return await this._http.post(url, undefined, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/exportFiles`
  async exportFiles(data: IDocumentsDownloadRequest): Promise<AxiosResponse> {
    const url = this.url.list.exportFiles

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/jobFiles/{{ jobOguid }}`
  async getJobFile(jobOguid: string, requestId?: string): Promise<AxiosResponse> {
    const url = this.url.single.getJobFile.replace('{{ jobOguid }}', jobOguid)
    let config: AxiosRequestConfig = {
      params: { ...needResetParams },
      responseType: 'blob'
    }

    if (requestId) {
      config = {
        ...config,
        params: {
          ...config.params,
          requestId
        }
      }
    }

    return await this._http.get(url, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/{{ documentOguid }}/page/{{ documentPage }}`
  async getAttachPreview(documentOguid: string, documentPage: string, requestId?: string, config?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
    const url = this.url.single.getAttachPreview
      .replace('{{ documentOguid }}', documentOguid)
      .replace('{{ documentPage }}', documentPage)

    let newConfig: AxiosRequestConfig = {
      responseType: 'text'
    }

    if (config) {
      newConfig = {
        ...newConfig,
        ...config,
      }
    }

    if (requestId) {
      newConfig = {
        ...newConfig,
        params: {
          requestId
        }
      }
    }

    return await this._http.get(url, newConfig)
  }

  // `${BASE_URL}orgs/${orgOguid}/shelf/{{ fileOguid }}/page/{{ page }}`
  async getShelfPreview(fileOguid: string, documentPage: string, requestId?: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    const url = this.url.single.getShelfPreview
      .replace('{{ fileOguid }}', fileOguid)
      .replace('{{ documentPage }}', documentPage)

    let newConfig: AxiosRequestConfig = {
      responseType: 'text'
    }

    if (config) {
      newConfig = {
        ...newConfig,
        ...config,
      }
    }

    if (requestId) {
      newConfig = {
        ...newConfig,
        params: {
          requestId
        }
      }
    }
    return await this._http.get(url, newConfig)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents`
  async sendDocuments(data: ISendDocumentRequest): Promise<AxiosResponse<string[]>> {
    const url = this.url.list.documents

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/messages`
  async sendMessages(data: any): Promise<AxiosResponse<string[]>> {
    const url = this.url.list.messages

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/shelf`
  async sendFile(data: FormData): Promise<AxiosResponse<string>> {
    const url = this.url.single.sendFile

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/{{ documentOguid }}/relations`
  async postRelation(documentOguid: string, data: IPackageBody): Promise<AxiosResponse> {
    const url = this.url.relation.list.replace('{{ documentOguid }}', documentOguid)

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/{{ documentOguid }}/relations/relatedDocumentOguid`
  async deleteRelation(documentOguid: string, relatedOguid: string): Promise<AxiosResponse> {
    const url = this.url.relation.single.replace('{{ documentOguid }}', documentOguid).replace('{{ relatedDocumentOguid }}', relatedOguid)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/receipts`
  async getReceipts(params?: IPagination): Promise<AxiosResponse<IReceipt[]>> {
    const url = this.url.list.receipts

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.get(url, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/${documentOguid}`
  async getDocumentData(documentOguid: string): Promise<AxiosResponse<IDocument>> {
    const url = this.url.single.document.replace('{{ documentOguid }}', documentOguid)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents`
  async getDocuments(params: IParams): Promise<AxiosResponse<IDocument[]>> {
    const url = this.url.list.documents
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/messages`
  async getMessages(params: any): Promise<AxiosResponse<IMessage[]>> {
    const url = this.url.list.messages
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/${documentOguid}`
  async updateDocumentFields(documentOguid: string, requestBody: any): Promise<AxiosResponse<IDocument>> {
    const url = this.url.single.document.replace('{{ documentOguid }}', documentOguid)

    return await this._http.put(url, requestBody)
  }

  // `${BASE_URL}orgs/${orgOguid}/attachments/${signatureId}/signatureInfo`
  async getDocumentSignInfo(signatureId: string): Promise<AxiosResponse<IDocument>> {
    const url = this.url.single.getSignatureInfo.replace('{{ signatureId }}', signatureId)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/{documentOguid}/relations`
  async getDocumentRelations(documentOguid: string): Promise<AxiosResponse<IRelations>> {
    const url = this.url.relation.list.replace('{{ documentOguid }}', documentOguid)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/{flowEntityOguid}/flowState`
  async getFlowState(taskOguid: string): Promise<AxiosResponse<IFlowState>> {
    const url = this.url.flow.state.replace('{{ flowEntityOguid }}', taskOguid)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/{flowEntityOguid}/flowState`
  async updateFlowState(taskOguid: string, stages: IUpdatedFlowStage[]): Promise<AxiosResponse<IFlowState>> {
    const url = this.url.flow.state.replace('{{ flowEntityOguid }}', taskOguid)

    return await this._http.put(url, stages)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/{flowEntityOguid}/flowStop`
  async stopFlow(taskOguid: string, params?: IParams): Promise<AxiosResponse> {
    const url = this.url.flow.stop.replace('{{ flowEntityOguid }}', taskOguid)
    const config = { params }

    return await this._http.post(url, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/{flowEntityOguid}/todos`
  async getTodos(taskOguid: string): Promise<AxiosResponse<ITodo[]>> {
    const url = this.url.flow.todos.replace('{{ flowEntityOguid }}', taskOguid)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/{flowEntityOguid}/tasks`
  async postTask(taskOguid: string, data: ITodoBody, params: IParams): Promise<AxiosResponse<ITask>> {
    const url = this.url.flow.tasks.replace('{{ flowEntityOguid }}', taskOguid)
    const config = { params }

    return await this._http.post(url, data, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/${documentOguid}`
    async removeDocument(documentOguid: string): Promise<AxiosResponse> {
    const url = this.url.single.document.replace('{{ documentOguid }}', documentOguid)

    return await this._http.delete(url)
  }

  async generateDocumentId(): Promise<AxiosResponse> {
    const url = this.url.single.documentId

    return await this._http.post(url, null)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/sharedFileLink/`
  async generateSharedFileId(data: IFileSharedLinkBody): Promise<AxiosResponse> {
    const url = this.url.single.sharedFileId

    return await this._http.post(url, data)
  }
}

export default new DocumentsService()
