import {
  LinkTarget,
  LinkAction,
  DEFAULT_LINK_REL,
  PdfScrollMode,
  VIEWER_WRAPPER_PADDING_LEFT
} from '@views/organization/documents/components/Document/components/File/consts'
import { EventBus, PDFDocumentProxy } from 'pdfjs-dist/types/web/base_viewer'
import { PDFLinkServiceOptions } from 'pdfjs-dist/types/web/pdf_link_service'
import { ILinkAttributesProps } from '@views/organization/documents/components/Document/components/File/types'
import { sendErrorToSentry } from '@utils/utils'
import { removeNullCharacters } from './ui_utils'
import { Nullable } from '@store/types/commonTypes'

function addLinkAttributes(link: HTMLAnchorElement, { url, target, rel, enabled = true }: ILinkAttributesProps = {}): void {
  if (!url) {
    throw new Error('A valid "url" parameter must provided.')
  }

  const urlNullRemoved = removeNullCharacters(url)

  if (enabled) {
    link.href = link.title = urlNullRemoved
  } else {
    link.href = ''
    link.title = `Disabled: ${urlNullRemoved}`

    link.onclick = () => {
      return false
    }
  }

  let targetStr = ''

  switch (target) {
    case LinkTarget.NONE:
      break

    case LinkTarget.SELF:
      targetStr = '_self'
      break

    case LinkTarget.BLANK:
      targetStr = '_blank'
      break

    case LinkTarget.PARENT:
      targetStr = '_parent'
      break

    case LinkTarget.TOP:
      targetStr = '_top'
      break
  }

  link.target = targetStr
  link.rel = typeof rel === 'string' ? rel : DEFAULT_LINK_REL
}

let PDF_VIEWER: any = null
let DOCUMENT: PDFDocumentProxy

export class PDFLinkService {
  private readonly pagesRefCache = new Map()
  eventBus: EventBus
  pdfDocument: Nullable<PDFDocumentProxy>
  pdfViewer: any
  pdfHistory: any
  baseUrl: Nullable<string>
  externalLinkTarget?: number | undefined
  externalLinkRel?: string | undefined
  externalLinkEnabled: boolean

  constructor(props: PDFLinkServiceOptions) {
    this.eventBus = props.eventBus
    this.externalLinkTarget = props.externalLinkTarget
    this.externalLinkRel = props.externalLinkRel
    this.externalLinkEnabled = true
    this.baseUrl = null
    this.pdfDocument = null
    this.pdfHistory = null
  }

  setDocument(pdfDocument: PDFDocumentProxy, baseUrl: Nullable<string> = null): void {
    this.baseUrl = baseUrl
    DOCUMENT = pdfDocument
    this.pdfDocument = DOCUMENT
    this.pagesRefCache.clear()
  }

  setViewer(pdfViewer: any): void {
    PDF_VIEWER = pdfViewer
    this.pdfViewer = PDF_VIEWER
  }

  setHistory(pdfHistory: any): void {
    this.pdfHistory = pdfHistory
  }

  get pagesCount(): number {
    return DOCUMENT ? DOCUMENT.numPages : 0
  }

  get page(): number {
    return PDF_VIEWER.currentPageNumber
  }

  set page(value: number) {
    PDF_VIEWER.currentPageNumber = value
  }

  get rotation(): number {
    return PDF_VIEWER.pagesRotation
  }

  set rotation(value: number) {
    PDF_VIEWER.pagesRotation = value
  }

  getPagesAverageHeight(): Nullable<number> {
    const pages = PDF_VIEWER._pages ?? []
    let pagesHeight = 0

    if (pages) {
      pages.forEach((page: any) => {
        pagesHeight += +page.height
      })
    }

    return pagesHeight ? pagesHeight / pages.length : null
  }

  goToPage(val: string | number): void {
    if (!DOCUMENT) {
      return
    }

    const pageNumber = (typeof val === 'string') ? PDF_VIEWER.pageLabelToPageNumber(val) ?? val : val ?? 0

    if (!(Number.isInteger(pageNumber) && pageNumber > 0 && pageNumber <= this.pagesCount)) {
      const sentryParams = {
        info: {},
        message: `PDFLinkService.goToPage: '${val}' is not a valid page.`
      }
      sendErrorToSentry(sentryParams)
      return
    }

    if (this.pdfHistory) {
      this.pdfHistory.pushCurrentPosition()
      this.pdfHistory.pushPage(pageNumber)
    }

    if (PDF_VIEWER?._scrollMode === PdfScrollMode.PAGE) {
      PDF_VIEWER.scrollPageIntoView({ pageNumber })
    } else {
      const x = VIEWER_WRAPPER_PADDING_LEFT * -1

      PDF_VIEWER.scrollPageIntoView({
        pageNumber,
        destArray: [null, { name: 'XYZ' }, x, null, PDF_VIEWER?._currentScaleValue ?? 1],
        allowNegativeOffset: true
      })
      PDF_VIEWER._location = { ...PDF_VIEWER?._location, pageNumber }
      PDF_VIEWER._currentPageNumber = pageNumber
    }
  }

  addLinkAttributes(link: HTMLAnchorElement, url: string, newWindow: boolean | undefined = false): void {
    addLinkAttributes(link, {
      url,
      target: newWindow ? LinkTarget.BLANK : this.externalLinkTarget,
      rel: this.externalLinkRel,
      enabled: this.externalLinkEnabled
    })
  }

  getDestinationHash(dest: string | []): string {
    if (typeof dest === 'string') {
      if (dest.length > 0) {
        return this.getAnchorUrl('#' + escape(dest))
      }
    } else if (Array.isArray(dest)) {
      const str = JSON.stringify(dest)

      if (str.length > 0) {
        return this.getAnchorUrl('#' + escape(str))
      }
    }

    return this.getAnchorUrl('')
  }

  getAnchorUrl(anchor: string): string {
    return `${this.baseUrl ?? ''}${anchor}`
  }

  executeNamedAction(action: string): void {
    switch (action) {
      case LinkAction.GO_BACK:
        this.pdfHistory?.back()
        break

      case LinkAction.GO_FORWARD:
        this.pdfHistory?.forward()
        break

      case LinkAction.NEXT_PAGE:
        PDF_VIEWER.nextPage()
        break

      case LinkAction.PREV_PAGE:
        PDF_VIEWER.previousPage()
        break

      case LinkAction.LAST_PAGE:
        this.page = this.pagesCount
        break

      case LinkAction.FIRST_PAGE:
        this.page = 1
        break

      default:
        break
    }

    this.eventBus.dispatch('namedaction', {
      source: this,
      action
    })
  }

  cachePageRef(pageNum: number, pageRef: any): void {
    if (!pageRef) {
      return
    }

    const refStr = pageRef.gen === 0 ? `${pageRef.num}R` : `${pageRef.num}R${pageRef.gen}`
    this.pagesRefCache.set(refStr, pageNum)
  }

  _cachedPageNumber(pageRef: any): Nullable<string> {
    if (!pageRef) {
      return null
    }

    const refStr = pageRef.gen === 0 ? `${pageRef.num}R` : `${pageRef.num}R${pageRef.gen}`
    return this.pagesRefCache.get(refStr) || null
  }

  isPageVisible(pageNumber: number): any {
    return PDF_VIEWER.isPageVisible(pageNumber)
  }

  isPageCached(pageNumber: number): any {
    return PDF_VIEWER.isPageCached(pageNumber)
  }
}
