import { AxiosResponse } from 'axios'
import cn from 'classnames'
import fileDownload from 'js-file-download'
import { debounce } from 'lodash'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  Button,
  DropdownButton,
  IconArchiveBoxIn,
  IconDefaultWidth,
  IconDownload,
  IconPrint,
  ILibHorizontalPosition,
  ILibIcon,
  Nullable
} from '@infologistics/frontend-libraries'

import { getScaleItems } from './utils'
import { displayBasicNotification, displayErrorNotification, getBaseUrl } from '@utils/utils'
import { DocumentType, FieldName, ResponseCode } from '@const/consts'
import { TYPE_ZIP } from './consts'
import { DocumentDirections } from '@const/translations'

import TokensService from '@services/tokensService'
import fileHelperService from '@services/fileHelper'
import DocumentsService from '@services/documents'

import { IApplicationState } from '@store/types/commonTypes'
import { IFileLinkButton, IFilesButtonsProps as IProps } from './types'
import FileSharing from '@views/organization/documents/components/Document/components/File/FileSharing/FileSharing'

import styles from './File.module.css'
import { DocumentFilenameType, DocumentsDownloadType } from '@app/common/DocumentsDownloadModal/types'
import { v4 as uuidv4 } from 'uuid'
import http from '@app/services/http'

const FileButtons: FC<IProps> = (props) => {
  const { t } = useTranslation(['common', 'document'])

  const activeOrganization =
    useSelector((state: IApplicationState) =>
      state.user.activeOrganization.oguid)

  const {
    currentDocument,
    currentDocumentFiles
  } = useSelector((state: IApplicationState) => state.documents)

  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [requestId, setRequestId] = useState<Nullable<string>>(null)

  useEffect(() => {
    setIsMounted(true)

    return () => {
      requestId && http.removeAxiosEvent(requestId)
      setIsMounted(false)
      setLoading(false)
    }
  }, [])

  const types = useSelector((state: IApplicationState) => state.metadata.userDocTypes)

  const { documentAttachmentOguid, oguid } = currentDocument
  const { isAvailable } = currentDocumentFiles
  const {
    draftDocumentId,
    orgOguid,
    replaceFileOguid,
    scale,
    setScale,
    getToken,
    classes,
    classesImportant,
    setLoading,
    hideSharingBtn,
    isAttachment,
    docFile,
  } = props

  const baseUrl = getBaseUrl()

  const iconProps: ILibIcon = {
    externalClass: styles.button_icon,
    withoutFill: true
  }

  const hrefOrigin = replaceFileOguid
    ? `${baseUrl}files/orgs/${activeOrganization}/shelf/${replaceFileOguid}/previewPDF/%%token%%`
    : `${baseUrl}files/orgs/${activeOrganization}/documents/${oguid}/printPdf/%%token%%`

  const hrefAttachment = isAttachment
    ? `${baseUrl}files/orgs/${activeOrganization}/attachments/${replaceFileOguid}/previewPDF/%%token%%`
    : hrefOrigin

  const href = docFile
    ? `${baseUrl}files/orgs/${orgOguid}/documents/${oguid}/fileField/${docFile.name}${docFile.index !== undefined ? `index/${docFile.index}/` : ""}previewPDF/%%token%%`
    : hrefAttachment

  const buttons: IFileLinkButton[] = [
    {
      href: href,
      title: t('document:links.print'),
      icon: <IconPrint {...iconProps} />,
      dataType: 'print',
      isDisabled: !isAvailable
    },
    {
      href: replaceFileOguid
        ? `${baseUrl}files/orgs/${activeOrganization}/shelf/${replaceFileOguid}/previewPDF/%%token%%`
        : `${baseUrl}files/orgs/${activeOrganization}/attachments/${documentAttachmentOguid}/%%token%%` ,
      title: t('document:links.download'),
      icon: <IconDownload {...iconProps} />
    },
    {
      href: `${baseUrl}orgs/${activeOrganization}/documents/exportFiles`,
      title: t('document:links.downloadAll'),
      icon: <IconArchiveBoxIn {...iconProps} />,
      isDisabled: !!replaceFileOguid,
      dataType: 'all',
    }
  ]

  const getType = (dataType: string): string => {
    const { fields, type } = currentDocument
    const { filename } = fields

    return dataType === TYPE_ZIP
      ? types[type].title
      : type === DocumentType.NONFORMALIZED && typeof filename === 'string'
        ? filename
        : types[type].title
  }

  const getNumber = (isArchive: boolean): string => {
    const { fields } = currentDocument

    const num = fields[FieldName.DOCUMENT_NUMBER]
    const numDoc = typeof num === 'string' ? `№ ${num.replaceAll('.', '_')}` : ''

    return numDoc ? (isArchive ? `_${numDoc}` : `${numDoc}_`) : ''
  }

  const getDirection = (): string => {
    const { direction } = currentDocument.fields

    return typeof direction === 'string' ? `${t(DocumentDirections[direction])}_` : ''
  }

  const getFileTitle = (dataType: string, isArchive: boolean): string =>
    `${getDirection()}${getNumber(isArchive)}${getType(dataType)}`

  const getArchiveTitle = (dataType: string, isArchive: boolean): string =>
    `${getDirection()}${getType(dataType)}${getNumber(isArchive)}`

  const getFileNameType = (resp: AxiosResponse, firstSplit: string) => {
    const {
      type: docType
    } = currentDocument

    const contentDisposition = resp.headers['content-disposition']
    const contentType = resp.headers['content-type']
    const filename = contentDisposition
      ?.split(firstSplit)[1]
      .split(';')[0]
      .replace(/['"]/g, '')
    const lastDotId = filename?.lastIndexOf('.')
    const extFile = filename?.substring(lastDotId)
    const dataType = resp.data.type
    const isAddExtension = docType !== DocumentType.NONFORMALIZED
    const extension = isAddExtension && extFile ? extFile : ''
    const nameFile = contentType === 'application/zip'
      ? getArchiveTitle(dataType, true)
      : getFileTitle(dataType, false)
    const title = `${nameFile}${extension}`.trim()

    return { title, dataType }
  }

  const loadFileWithDebounce = debounce(
    (href: string, type: string | undefined) =>
      fileHelperService.getFileWithDebounce(href)
        .then((resp: AxiosResponse<Blob>) => {

          if (resp.data) {
            if (type) {
              window.open(URL.createObjectURL(resp.data))

              return
            }

            const {title, dataType} = getFileNameType(resp, 'filename=')

            fileDownload(resp.data, title, dataType)
          }
        })
        .catch((error) => {
          const { response } = error

          if (response?.data) {
            const reader = new FileReader()
            reader.readAsText(response.data)

            reader.onload = () => {
              const messages = JSON.parse(reader.result?.toString() ?? '{}')?.message?.split('.') ?? []

              displayBasicNotification({
                type: 'error',
                content: messages.length > 1 ? messages[1] : messages[0] ?? '',
                title: messages.length > 1 ? messages[0] ?? '' : ''
              })
            }
          } else {
            displayErrorNotification(error)
          }
        }),
    300
  )

  const createJob = async (): Promise<any> => {
    try {
      const {
        data: { oguid: jobOguid }
      } = await DocumentsService.exportFiles({
        documentOguids: [oguid],
        download: DocumentsDownloadType.FULL_WORKFLOW,
        filename: DocumentFilenameType.ORIGINAL,
        isDownloadPrintForm: false
      })

      const requestId = uuidv4()
      setRequestId(requestId);

      await getJobFile(jobOguid, requestId)
    } catch (err) {
      displayErrorNotification(err)
      setLoading(false);
    }
  }

  const getJobFile = async (jobOguid: string, requestId: string): Promise<void> => {
    const requestTimeout = 3000

    if (!isMounted) return

    try {
      const resp = await DocumentsService.getJobFile(jobOguid, requestId)
      const { status } = resp

      if (status === ResponseCode.NO_CONTENT) {
        await new Promise((resolve) => setTimeout(resolve, requestTimeout))
        return getJobFile(jobOguid, requestId)
      }

      if (status === ResponseCode.GET) {

        const {title, dataType} = getFileNameType(resp, 'filename*=UTF-8')

        fileDownload(resp.data, title, dataType)
        setLoading(false);
      }

    } catch (err) {
      displayErrorNotification(err)
      setLoading(false);
    }
  }

  const handleDownloadAllFiles = async (e: MouseEvent<HTMLAnchorElement>): Promise<void> => {
    e.preventDefault()

    setLoading(true);

    await createJob()
  }

  const handleDownloadFile = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()

    const {
      href,
      dataset: { type }
    } = e.currentTarget

    const fileToken = getToken()

    if (fileToken) {
      const linkWithToken = href.replace('%%token%%', fileToken)

      loadFileWithDebounce(linkWithToken, type)?.catch(() => undefined)
    } else {
      const { refreshToken } = TokensService.getTokensToStartup()

      TokensService.refresh(refreshToken)
        .then(() => {
          const newFileToken = getToken()

          if (newFileToken) {
            const linkWithToken = href.replace('%%token%%', newFileToken)
            loadFileWithDebounce(linkWithToken, type)?.catch(() => undefined)
          }
        })
        .catch(displayErrorNotification)
    }
  }

  return (
    <div className={cn(styles.buttons, classes, classesImportant ?? 'absolute at-6 ar-4')}>
      <ul className='list-unstyled d-flex flex-column'>
        <li className='mb-4'
            title={t('document:links.changeScale')}>
          <DropdownButton
            buttonTheme='text'
            popoverClasses='text-muted'
            buttonClasses={cn(
              styles.button_link,
              styles.button_dropDown_scale,
              'd-flex align-items-center justify-content-center text-mutted-400'
            )}
            containerClasses={styles.scale_popover}
            itemClasses={cn(styles.scale_item, 'py-1')}
            dropdownItems={getScaleItems()}
            onSelect={(item) => setScale(+item.data)}
            withoutArrow
            selectedItem={getScaleItems().find(item => item.data === scale)}
            selectedTitle={`${scale}%`}
            horizontalPosition={ILibHorizontalPosition.RIGHT}
          />
        </li>
        <li className='mb-4'>
          <Button
            theme='text'
            onClick={() => setScale(100)}
            classes={cn(
              styles.button_link,
              'd-flex align-items-center justify-content-center'
            )}
            title={t('document:links.resetScale')}
          >
            <IconDefaultWidth
              externalClass={styles.button_icon}
              withoutFill
            />
          </Button>

        </li>

        {
          (!draftDocumentId && !isAttachment && !docFile)
            ? buttons.map(({ href, title, icon, dataType, isDisabled }, index) =>
              <li
                key={`linkButton-${index}`}
                className='mb-4'
              >
                <a
                  href={href}
                  title={title}
                  data-type={dataType}
                  tabIndex={isDisabled ? -1 : 0}
                  onClick={(dataType === "all")?handleDownloadAllFiles:handleDownloadFile}
                  className={cn(
                    styles.button_link,
                    isDisabled && styles.button_link_disabled,
                    'd-flex align-items-center justify-content-center'
                  )}
                >
                  {icon}
                </a>
              </li>
            ) : null
        }
        {
          !hideSharingBtn &&
          <li className='relative'>
            <FileSharing
              buttonClasses={cn(styles.button_link, 'd-flex align-items-center justify-content-center')}
              documentAttachmentOguid={documentAttachmentOguid}
              orgOguid={orgOguid}
            />
          </li>
        }
      </ul>
    </div>
  )
}

export default FileButtons
