import { IPDFLinkService } from 'pdfjs-dist/types/web/pdf_thumbnail_viewer'
import { PDFPageProxy } from 'pdfjs-dist/types/src/display/api'
import { IL10n } from 'pdfjs-dist/types/web/annotation_editor_layer_builder'
import { PDFDocumentProxy } from 'pdfjs-dist/types/web/pdf_find_controller'
import { IPDFThumbnailViewerOptions, IVisibleRenderingQueue, IPDFRenderingQueue } from '../types'
import { sendErrorToSentry } from '@utils/utils'
import { RenderingState } from '@views/organization/documents/components/Document/components/File/consts'
import { Nullable } from '@store/types/commonTypes'

import * as _ui_utils from './ui_utils'
import * as _pdf_thumbnail_view from './ThumbnailView'

const THUMBNAIL_SELECTED_CLASS = 'selected'

export class PDFThumbnailViewer {
  container: HTMLDivElement
  linkService: IPDFLinkService
  renderingQueue: IPDFRenderingQueue
  l10n: IL10n
  pageColors: Nullable<{ background: string, foreground: string }>
  scroll: {
    right: boolean
    down: boolean
    lastX: any
    lastY: any
    _eventHandler: (evt: any) => void
  }

  private thumbnails: any
  pdfDocument: Nullable<PDFDocumentProxy>
  private currentPageNumber: any
  private _pagesRotation: number
  private pageLabels: Nullable<any[] | undefined>

  constructor({
    container,
    eventBus,
    linkService,
    renderingQueue,
    l10n,
    pageColors
  }: IPDFThumbnailViewerOptions) {
    this.container = container
    this.linkService = linkService
    this.renderingQueue = renderingQueue
    this.l10n = l10n
    this.pageColors = pageColors ?? null
    this.pdfDocument = null
    this._pagesRotation = 0

    if (this.pageColors && !(CSS.supports('color', this.pageColors.background) && CSS.supports('color', this.pageColors.foreground))) {
      if (this.pageColors.background || this.pageColors.foreground) {
        const sentryParams = {
          info: {},
          message: `PDFThumbnailViewer: Ignoring 'pageColors'-option, since the browser doesn't support the values used.`
        }
        sendErrorToSentry(sentryParams)
      }

      this.pageColors = null
    }

    this.scroll = _ui_utils.watchScroll(this.container, this.scrollUpdated.bind(this))

    this.resetView()
  }

  private scrollUpdated(): void {
    this.renderingQueue.renderHighestPriority()
  }

  getThumbnail(index: number | string): any {
    return this.thumbnails[index]
  }

  private getVisibleThumbs(): IVisibleRenderingQueue {
    return _ui_utils.getVisibleElements({
      scrollEl: this.container,
      views: this.thumbnails
    })
  }

  scrollThumbnailIntoView(pageNumber: number): void {
    if (!this.pdfDocument) {
      return
    }

    const thumbnailView = this.thumbnails[pageNumber - 1]

    if (!thumbnailView) {
      const sentryParams = {
        info: {},
        message: 'scrollThumbnailIntoView: Invalid "pageNumber" parameter.'
      }
      sendErrorToSentry(sentryParams)
      return
    }

    if (pageNumber !== this.currentPageNumber) {
      const prevThumbnailView = this.thumbnails[this.currentPageNumber - 1]
      prevThumbnailView.div.classList.remove(THUMBNAIL_SELECTED_CLASS)
      thumbnailView.div.classList.add(THUMBNAIL_SELECTED_CLASS)
    }

    const {
      first,
      last,
      views
    } = this.getVisibleThumbs()

    if (views.length > 0) {
      let shouldScroll = false

      if (pageNumber <= first.id || pageNumber >= last.id) {
        shouldScroll = true
      } else {
        for (const {
          id,
          percent
        } of views) {
          if (id !== pageNumber) {
            continue
          }

          shouldScroll = percent < 100
          break
        }
      }

      const container = this.container
      const containerTop = container.getBoundingClientRect().top ?? 0
      const containerScrolled = container.scrollTop ?? 0

      const thumbnailTop = thumbnailView.div.getBoundingClientRect().top ?? 0

      if (shouldScroll) {
        container.scrollTo({ top: +thumbnailTop + containerScrolled - containerTop })
      }
    }

    this.currentPageNumber = pageNumber
  }

  get pagesRotation(): number {
    return this._pagesRotation
  }

  set pagesRotation(rotation: number) {
    if (!_ui_utils.isValidRotation(rotation)) {
      throw new Error('Invalid thumbnails rotation angle.')
    }

    if (!this.pdfDocument) {
      return
    }

    if (this._pagesRotation === rotation) {
      return
    }

    this._pagesRotation = rotation
    const updateArgs = {
      rotation
    }

    for (const thumbnail of this.thumbnails) {
      thumbnail.update(updateArgs)
    }
  }

  cleanup(): void {
    for (const thumbnail of this.thumbnails) {
      if (thumbnail.renderingState !== RenderingState.FINISHED) {
        thumbnail.reset()
      }
    }

    _pdf_thumbnail_view.TempImageFactory.destroyCanvas()
  }

  private resetView(): void {
    this.thumbnails = []
    this.currentPageNumber = 1
    this.pageLabels = null
    this._pagesRotation = 0
    this.container.textContent = ''
  }

  setDocument(pdfDocument: PDFDocumentProxy): void {
    if (this.pdfDocument) {
      this.cancelRendering()

      this.resetView()
    }

    this.pdfDocument = pdfDocument

    if (!pdfDocument) {
      return
    }

    const firstPagePromise = pdfDocument.getPage(1)
    const optionalContentConfigPromise = pdfDocument.getOptionalContentConfig()
    firstPagePromise.then(firstPdfPage => {
      const pagesCount = pdfDocument.numPages
      const viewport = firstPdfPage.getViewport({
        scale: 1
      })

      for (let pageNum = 1; pageNum <= pagesCount; ++pageNum) {
        const thumbnail = new _pdf_thumbnail_view.PDFThumbnailView({
          container: this.container,
          id: pageNum,
          defaultViewport: viewport.clone(),
          optionalContentConfigPromise,
          linkService: this.linkService,
          renderingQueue: this.renderingQueue,
          l10n: this.l10n,
          pageColors: this.pageColors
        })

        this.thumbnails.push(thumbnail)
      }

      const firstThumbnailView = this.thumbnails[0]

      if (firstThumbnailView) {
        firstThumbnailView.setPdfPage(firstPdfPage)
      }

      const thumbnailView = this.thumbnails[this.currentPageNumber - 1]
      thumbnailView.div.classList.add(THUMBNAIL_SELECTED_CLASS)
    }).catch(null)
  }

  private cancelRendering(): void {
    for (const thumbnail of this.thumbnails) {
      thumbnail.cancelRendering()
    }
  }

  setPageLabels(labels: Nullable <any[]>): void {
    if (!this.pdfDocument) {
      return
    }

    if (!labels) {
      this.pageLabels = null
    } else if (!(Array.isArray(labels) && this.pdfDocument.numPages === labels.length)) {
      this.pageLabels = null
      const sentryParams = {
        info: {},
        message: 'PDFThumbnailViewer_setPageLabels: Invalid page labels.'
      }
      sendErrorToSentry(sentryParams)
    } else {
      this.pageLabels = labels
    }

    for (let i = 0, ii = this.thumbnails.length; i < ii; i++) {
      this.thumbnails[i].setPageLabel(this.pageLabels?.[i] ?? null)
    }
  }

  private async ensurePdfPageLoaded(thumbView: any): Promise<Nullable<PDFPageProxy> | undefined> {
    if (thumbView.pdfPage) {
      return thumbView.pdfPage
    }

    try {
      const pdfPage = await this.pdfDocument?.getPage(thumbView.id)

      if (!thumbView.pdfPage) {
        thumbView.setPdfPage(pdfPage)
      }

      return pdfPage
    } catch (reason) {
      const sentryParams = {
        info: {},
        message: `Unable to get page for thumb view, ${reason}`
      }
      sendErrorToSentry(sentryParams)
      return null
    }
  }

  private getScrollAhead(visible: IVisibleRenderingQueue): boolean {
    if (visible.first?.id === 1) {
      return true
    } else if (visible.last?.id === this.thumbnails.length) {
      return false
    }

    return this.scroll.down
  }

  forceRendering(): boolean {
    const visibleThumbs = this.getVisibleThumbs()

    const scrollAhead = this.getScrollAhead(visibleThumbs)
    const thumbView = this.renderingQueue.getHighestPriority(visibleThumbs, this.thumbnails, scrollAhead)

    if (thumbView) {
      this.ensurePdfPageLoaded(thumbView)
        .then(() => {
          this.renderingQueue.renderView(thumbView)
        })
        .catch(() => null)
      return true
    }

    return false
  }
}
