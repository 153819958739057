import React, { ChangeEvent, FC, useState, useEffect } from 'react'
import {
  IFilesThumbnailsProps as IProps
} from '@views/organization/documents/components/Document/components/File/types'
import cn from 'classnames'
import {
  Button,
  IconAngle,
  IconAngleTop,
  Input
} from '@infologistics/frontend-libraries'
import { useTranslation } from 'react-i18next'

import styles from './File.module.css'

const FileThumbnailsControls: FC<IProps> = (props) => {
  const {
    isThumbnailsOpen,
    currentPage,
    totalPages,
    onSubmit,
    onClick,
    onBlur
  } = props;

  const { t } = useTranslation()

  const [page, setPage] = useState<number>(currentPage)

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>):void => {
    const page = Number(value) <= totalPages ? Number(value) : currentPage
    setPage(page)
  }

  const handleNextButtonClick = ():void => {
    const page = currentPage + 1 <= totalPages ? currentPage + 1 : currentPage
    onClick(page)
  }

  const handlePrevButtonClick = ():void => {
    if (currentPage === 1) return
    const page = currentPage > 1 ? currentPage - 1 : currentPage
    onClick(page)
  }

  const handleBlur = ():void => {
    onBlur(page)
  }

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage)
    }
  }, [currentPage])

  return (
    <div className={cn(
      'd-flex flex-row align-items-center',
      styles.thumbnail_controls,
      !isThumbnailsOpen ? styles.thumbnail_controls_close : ''
    )}
    >
      <Button
        theme='text'
        classes={cn(styles.thumbnail_nextPrev_button, 'mr-2')}
        onClick={handlePrevButtonClick}
        isDisabled={currentPage <= 1}
        title={t('document:pagination:previous')}
      >
        <IconAngleTop
          size='sm'
          color='light'
        />
      </Button>
      <Button
        theme='text'
        classes={cn(styles.thumbnail_nextPrev_button, 'mr-2')}
        onClick={handleNextButtonClick}
        isDisabled={currentPage + 1 > totalPages}
        title={t('document:pagination:next')}
      >
        <IconAngle
          size='sm'
          color='light'
        />
      </Button>
      <form onSubmit={(evt) => onSubmit(evt, page)} className='d-flex align-items-center'>
        <Input
          id='page'
          name='page'
          value={page}
          onBlur={handleBlur}
          onChange={handleChange}
          classes={cn(styles.thumbnail_pageInput)}
          inputSize='xs'
          maxLength={5}
        />
        { totalPages ? <span className='font-xs text-light ml-1'>{t('common:of')}&nbsp;{totalPages}</span> : null }
      </form>
    </div>
  )
}

export default FileThumbnailsControls
