import { AxiosRequestConfig, AxiosResponse } from 'axios'
import AbstractHttpService from '@services/abstractHttpService'

class FileHelperService extends AbstractHttpService {
  async getFileWithDebounce(href: string): Promise<AxiosResponse<Blob>> {
    const config: AxiosRequestConfig = { responseType: 'blob' }

    return await this._http.get(href, config)
  }
}

export default new FileHelperService()
