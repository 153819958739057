import { Actions, FilePreviewActionTypes, IFilePreview } from './types';

export const initialState: IFilePreview = {
  show: false,
  documentId: '',
};

export default function filePreviewReducer(
  state: IFilePreview = initialState,
  action: Actions
): IFilePreview {
  switch (action.type) {
    case FilePreviewActionTypes.SET_PREVIEW:
      return action.payload;
    case FilePreviewActionTypes.CLOSE_PREVIEW:
      return initialState;
    default:
      return state;
  }
}
