import { Component, ReactNode } from 'react'
import { createPortal } from 'react-dom'

import { IPortalProps } from './types'

const modalRoot = document.getElementById('modal-root')
const el = document.createElement('div')

export class Portal extends Component<IPortalProps> {
  public componentDidMount(): void {
    if (modalRoot) {
      el.style.zIndex = '2'
      el.style.position = 'relative'
      modalRoot.appendChild(el)
    }
  }

  public componentWillUnmount(): void {
    if (modalRoot?.firstChild) {
      modalRoot.firstChild.remove()
    }
  }

  public render(): ReactNode {
    return createPortal(this.props.children, el)
  }
}
