import { AxiosError } from 'axios'
import cn from 'classnames'
import { FormikErrors, FormikHelpers, FormikTouched, FormikValues } from 'formik'
import * as History from 'history'
import { TFunction } from 'i18next'
import { camelCase, cloneDeep, get, has, isEqual, omit } from 'lodash'
import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'
import queryString from 'query-string'
import React, { cloneElement, CSSProperties, ReactNode, ReactNodeArray } from 'react'
import { match } from 'react-router'
import slug from 'slug'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/browser'
import { SeverityLevel } from '@sentry/types'

import { Instance as flInstance } from '@infologistics/frontend-libraries'

import currencies from '@common/CurrencySelect/currencies.json'

import i18n from '@app/i18n'

import {
  Button,
  getFormattedDateForDateFilter,
  getUTCOffset,
  IconCopy,
  IconEditAlt,
  IconIncoming,
  IconOutgoing,
  IconPlus,
  ILibBasicNotificationOptions,
  ILibDetailedTableCellFilterOptions,
  ILibDetailedTableColumn,
  ILibDetailedTableRow,
  ILibDropdownItem,
  ILibIcon,
  ILibOption,
  ILibPagination,
  ILibSelectValueType,
  ILibTreeSelectOption,
  ILibUser as IRecipient,
  showBasicNotification,
  showConfirmNotification,
  showErrorNotification,
  showSuccessNotification,
  Theme
} from '@infologistics/frontend-libraries'
import { OrganizationLink } from '@containers/Navigation'

import {
  ActionTypeToStageType,
  Alias,
  AllowedAction,
  AllRoles,
  altRubleSymbol,
  apiUrlCookieName,
  BASE_URL,
  BooleanValue,
  BrowserStorage,
  copiedFieldNumberRegexp,
  Currency,
  currencySymbol,
  DateFormat,
  DetailedTableColumn,
  DetailedTableColumnOrderTemplate,
  DIRECTION_COLORS,
  DirectionValue,
  FieldName,
  FieldType,
  FlowIcons,
  FlowResult,
  FlowState,
  FlowStateStatus,
  FlowStatusColor,
  ICONS_NAMES,
  Instance,
  instanceCurrency,
  Languages,
  LocaleType,
  LocalStorageKeysForSaveBetweenSessions,
  MassiveActionPage,
  MAX_BADGES_COUNT,
  MAX_FIELD_KEY_ID,
  MINIMUM_ITEMS_COUNT_TO_SHOW_PAGINATION,
  NavMode,
  NoticeCategory,
  noticesPerPageDefault,
  PDF_PREVIEW_EXTENSIONS,
  PER_PAGE_ITEMS_DEFAULT,
  PhoneVerifyCountry,
  RegisterField,
  relatedDocsFileNameLength,
  Role,
  RoleModelRestrictionKeys,
  RouteName,
  Section,
  Severity,
  StatusIcon,
  StatusIcons,
  TextAlign,
  TextColorClass,
  urlRegexp,
  UserSettingsKey
} from '@const/consts'
import cookies from '@utils/cookies'
import { getSurnameWithInitialsBot } from './documentsUtils'

import { ISentryOptions } from '@app/types'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'
import { RouteWithSubRoutes } from '@common/Routing'
import store from '@store/configureStore'
import { IHandbook } from '@store/modules/handbooks/types'
import { INavItem } from '@const/navSidebar'
import {
  DataType,
  IDocumentType,
  IDocumentTypeField,
  IDocumentTypes,
  IDocumentTypesFromServer,
  IField,
  IFlowStageType,
  IPreProcessingJobStatus,
  ITableField,
  IUserDocumentTypesFromServer,
  IWorkflowStatuses
} from '@store/modules/metadata/types'
import { ISubOrg } from '@store/modules/routes/types'
import { IUserFilter } from '@store/modules/userFilters/types'
import {
  AllServiceParams,
  fieldValue,
  ICertificates,
  IFileFieldValue,
  IGroupType,
  IParams,
  ITo,
  IUserOrganization,
  Nullable,
  SelectChangeFunction
} from '@store/types/commonTypes'
import {
  DocumentDetailsField,
  FlowState as FlowStateType,
  IAllowedAction,
  IDocument,
  IDocumentDetailsField,
  IDocumentField,
  IDocumentFields,
  IDocumentStatusInfo,
  IDocumentTask,
  IFlowStage,
  IMessage,
  ISelectedDocuments,
  ITodo
} from '@store/modules/documents/types'
import { IContractorData } from '@store/modules/contractors/types'
import { IUserData, IUserRoleRestrictions } from '@containers/Navigation/components/NavigationWrapper/types'
import {
  BooleanOptions,
  Common,
  Currency as CurrencyTranslations,
  DocumentDirections,
  Documents,
  MassiveActions,
  Recognition,
  TableFieldsDirectionsLabels,
  TaskResult,
  TaskState
} from '@const/translations'
import { IMessage as ITaskMessage } from '@store/modules/tasks/types'
import { IDocumentRecipient } from '@views/organization/documents/components/DocumentEdit/types'
import { getFields, getUserDocTypes } from '@store/modules/metadata/actions'
import { setMissingFields, setMissingType, setMissingTypeFields, toggleRootMode } from '@store/modules/utils/actions'
import { ILibDivision as IDivision } from '@infologistics/frontend-libraries'
import { Certificate, CertificateKeys, ICertificateRowData } from '@views/user/Profile/modals/CertificateSettings/types'
import CryptoProProvider from '@common/CryptoPro/CryptoProProvider'
import { IFile } from '@common/ExportModal/types'
import { IDocTypeFieldKeys, IDocTypeFieldTitles, IFieldsDivSub, IGetFormattedFieldValueParams } from '@utils/types'
import { IUserSettings } from '@store/modules/user/types'
import { changeOrganization, resetStoreAfterOrganizationChange, updateUserSettings } from '@store/modules/user/actions'
import { setLoaded } from '@store/modules/startup/actions'

import {
  IDocumentTypeState
} from '@views/administration/components/metadata/DocumentTypes/components/DocumentType/types'

import { IFieldValues } from '@views/administration/components/metadata/Fields/components/Field/types'
import { ISelectedCertificatePower } from '@views/user/Profile/modals/CertificatePower/types'
import { IApplication } from '@app/store/modules/applications/types'

export interface INameInterface {
  surname?: string | null
  name?: string | null
  patronymic?: string | null
  language?: string
}

export interface IBasicNotificationOptions {
  content: string
  isTimerShow?: boolean
  title: string
  type: string
}

export interface ISuccessNotificationOptions {
  content?: string
  title?: string
}

export interface IConfirmNotificationOptions {
  cancelText?: string
  content?: string
  contentNotification?: string
  submitText?: string
  onSubmit: () => any
  title?: string
  theme?: Theme
}

export const getFilter = (filter: string, condition: string): string => {
  switch (condition) {
    case 'like':
      return `${filter}%`
    case 'contains':
      return `%${filter}%`
    case 'eq':
      return `=${filter}`
    case 'lt':
      return `<${filter}`
    case 'gt':
      return `>${filter}`
    default:
      return filter
  }
}

export const getPagination = (headers: any): ILibPagination | null => {
  if (
    !headers.hasOwnProperty('x-per-page') &&
    !headers.hasOwnProperty('x-next-page') &&
    !headers.hasOwnProperty('x-page') &&
    !headers.hasOwnProperty('x-prev-page') &&
    !headers.hasOwnProperty('x-total')
  ) {
    return null
  }

  return {
    itemsPerPage: headers?.['x-per-page'] ?? 10,
    nextPage: headers?.['x-next-page'] ?? null,
    pageIndex: headers?.['x-page'] !== '-1' ? +headers['x-page'] : 1,
    prevPage: headers?.['x-prev-page'] ?? null,
    total: headers?.['x-total'] ?? null
  }
}

export const debounce = (func: any, wait: number): any => {
  let timeout: any = null
  return (...args: any[]) => {
    const next = (): any => func(...args)

    clearTimeout(timeout)
    timeout = setTimeout(next, wait)
  }
}

export const displaySuccessNotification = (options: ISuccessNotificationOptions): void => {
  const { content, title } = options
  const { language, getResource } = i18n

  showSuccessNotification({
    content: content ?? String(getResource(language, 'notification', 'successContent')),
    title: title ?? String(getResource(language, 'notification', 'successTitle'))
  })
}

export const displayBasicNotification = (options: IBasicNotificationOptions): void => {
  const { content, title, type } = options
  const { language, getResource } = i18n

  showBasicNotification({
    content: content || getResource(language, 'notification', 'successContent'),
    title: title === undefined ? getResource(language, 'notification', 'successTitle') : title,
    type
  })
}

export const displayErrorNotification = ({ config, response }: AxiosError<any>): void => {
  if (config?.signal?.aborted) return

  const { language, getResource } = i18n

  const errorMessage = String(getResource(language, 'notification', 'errorContent'))

  showErrorNotification({
    content: response?.data?.message || errorMessage,
    title: ''
  })
}

export const displayConfirmNotification = (options: IConfirmNotificationOptions): void => {
  const {
    cancelText,
    submitText,
    content,
    contentNotification,
    title,
    onSubmit,
    theme
  } = options
  const { language, getResource } = i18n

  showConfirmNotification({
    cancelText: cancelText ?? getResource(language, 'common', 'no'),
    content: content ?? getResource(language, 'notification', 'confirmContent'),
    contentNotification,
    onSubmit,
    submitText: submitText ?? getResource(language, 'common', 'yes'),
    title: title ?? getResource(language, 'notification', 'confirmTitle'),
    theme
  })
}

export const getPerPageNumber = (): string | number => {
  const itemPerPage = localStorage.getItem(window.location.pathname)

  return itemPerPage ?? PER_PAGE_ITEMS_DEFAULT
}

export const getParamsFromSearch = (search: string, page?: number | string): any => {
  const params: any = queryString.parse(search)

  if (page) {
    params.page = page
  } else if (!params.page) {
    params.page = 1
  }

  if (!params.perPage) {
    params.perPage = getPerPageNumber()
  }

  return params
}

export const getRoutesList = (routes: IRoute[]): ReactNodeArray =>
  routes.map((route: IRoute) => {
    const { name, path } = route

    return <RouteWithSubRoutes key={name} {...route} path={path} />
  })

export const getOrgDataFromMatch = (matchData: match<AllServiceParams>): string => {
  if (matchData?.params?.org) return `/${matchData.params.org}`

  return ''
}

export const createUrl = (urlData: match<AllServiceParams> | string, ...rest: Array<string | number>): string => {
  const org = typeof urlData === 'string' ? `/${urlData}` : getOrgDataFromMatch(urlData)

  return (
    org +
    rest.reduce((accumulator: string, currentValue: string | number) => {
      if (!currentValue) return accumulator

      const newCurrentValue =
        typeof currentValue !== 'number' && !currentValue.includes('/') && accumulator.slice(-1) !== '/'
          ? `/${currentValue}`
          : currentValue
      return `${accumulator}${newCurrentValue}`
    }, '')
  )
}

export const modifyRoutes = (privateRoutes: IRoute[], user: Nullable<IUserRoleRestrictions>): IRoute[] => {
  const newRoutes = filterAvailableRoutes(cloneDeep(privateRoutes), user)

  return addOrgInPath(newRoutes)
}

const filterAvailableRoutes = (routes: IRoute[], user: Nullable<IUserRoleRestrictions>): IRoute[] => {
  const { instance } = store.getState().utils

  if (!user) {
    return routes.filter((route: IRoute) => route.useRootMode)
  }

  const { role } = user

  return routes.filter((route: IRoute) => {
    const isRoleCorrect = !!role && route.roles && route.roles.includes(role)

    if (!isRoleCorrect) return false

    if (route.name === 'UtdEdit' && instance !== Instance.RUSSIAN) return false

    const routeKeys = Object.keys(route)

    const routeRestrictionsKeys: string[] = []
    const notFulfilledRestrictions: string[] = []

    routeKeys.forEach((key: string) => {
      if (RoleModelRestrictionKeys.includes(key)) {
        routeRestrictionsKeys.push(key)

        if (route[key] !== user[key]) {
          notFulfilledRestrictions.push(key)
        }
      }
    })

    const isRouteWithoutRestrictions = !routeRestrictionsKeys.length

    if (isRouteWithoutRestrictions) return true

    return !notFulfilledRestrictions.length
  })
}

export const addOrgInPath = (routes: IRoute[]): IRoute[] => {
  return routes.reduce((accumulator: IRoute[], currentValue: IRoute) => {
    const newRoute = { ...currentValue, path: `${RouteName.ORGANIZATION_URL}${currentValue.path}` }

    if (currentValue.useRootMode) {
      const secondRoute = { ...currentValue, path: currentValue.path }
      return [...accumulator, newRoute, secondRoute]
    }

    return [...accumulator, newRoute]
  }, [])
}

export const getOrganizationInfo = (alias: string | null, oguid: string): string => alias ?? oguid

export const createPath = (org: string, to: History.LocationDescriptor): string | any => {
  const orgOguid: string = '/' + org

  if (typeof to === 'string') return orgOguid + to
  if (typeof to === 'object' && to.pathname) return { ...to, pathname: `${orgOguid}${to.pathname}` }

  return to
}

export const transformPathToString = (to: History.LocationDescriptor): string => {
  const newTo = ''

  if (typeof to === 'string') return newTo + to
  if (typeof to === 'object' && to.pathname) return to.pathname

  return newTo
}

export const checkMode = (name: string): string => name.replace(/-/g, '_')

const getModifiedFilters = (filters: IUserFilter[]): INavItem[] =>
  filters.map(({ oguid = '', title }: IUserFilter) => ({
    route: `${RouteName.DOCUMENTS}${RouteName.FILTERS}/${oguid}`,
    title,
    uuid: oguid,
    roles: AllRoles
  }))

export const addUserFilterInDocuments = (menuItems: INavItem[], filters: IUserFilter[]): INavItem[] => {
  const modifiedFilters = getModifiedFilters(filters)
  const modifiedMenuItems = cloneDeep(menuItems)

  const documentsIndex = modifiedMenuItems.findIndex((menuItem: INavItem) => menuItem.route === RouteName.DOCUMENTS)

  if (modifiedMenuItems[documentsIndex]) {
    const documents = cloneDeep(modifiedMenuItems[documentsIndex].items) ?? []

    modifiedMenuItems[documentsIndex].items = [...documents, ...modifiedFilters]
  }

  return modifiedMenuItems
}

const iconProps: ILibIcon = {
  externalClass: 'mr-2'
}

export const addApplicationsMenuItems = (
  menuItems: INavItem[],
  applications: IApplication[],
  activeOrganizationAlias: string
) => {
  let newMenuItems = cloneDeep(menuItems);
  if (applications.length) {
    const [first, ...rest] = newMenuItems;

    const applicationsItems = applications.map((val) => {
      createUrl(activeOrganizationAlias, `${RouteName.APPLICATIONS}/${val.name}`);

      return {
        icon: ICONS_NAMES[val.icon],
        iconProps: iconProps,
        route: `${RouteName.APPLICATIONS}/${val.oguid}`,
        title: val.name,
        uuid: uuidv4(),
        roles: [Role.ADMINISTRATOR],
      };
    });

    newMenuItems = [first, ...applicationsItems, ...rest];
  }

  return newMenuItems;
};

export const removeUnavailableMenuItems = (menuItems: INavItem[], user: IUserRoleRestrictions, docTypes: IDocumentTypes): INavItem[] =>
  filterAvailableMenuItems(cloneDeep(menuItems), user, docTypes)

const filterAvailableMenuItems = (menuItems: INavItem[], user: IUserRoleRestrictions, docTypes: IDocumentTypes): INavItem[] => {
  const { role } = user
  const isContractorFieldPresent = Object.values(docTypes).some(({ fields }: IDocumentType) => (
    Object.values(fields).some(({ type }: IDocumentTypeField) => type === FieldType.CONTRACTOR)
  ))

  return menuItems.filter((menuItem: INavItem) => {
    const isContractorsItem = menuItem.route === RouteName.CONTRACTORS
    const isRoleCorrect = !!role && menuItem.roles.includes(role)

    if (!isRoleCorrect || (!isContractorFieldPresent && isContractorsItem)) return false

    if (menuItem.items?.length) {
      menuItem.items = filterAvailableMenuItems(menuItem.items, user, docTypes)
    }

    const menuItemKeys = Object.keys(menuItem)

    const menuItemRestrictionsKeys: string[] = []
    const notFulfilledRestrictions: string[] = []

    menuItemKeys.forEach((key: string) => {
      if (RoleModelRestrictionKeys.includes(key)) {
        menuItemRestrictionsKeys.push(key)

        if (menuItem[key] !== user[key]) {
          notFulfilledRestrictions.push(key)
        }
      }
    })

    const isMenuItemWithoutRestrictions = !menuItemRestrictionsKeys.length

    if (isMenuItemWithoutRestrictions) return true

    return !notFulfilledRestrictions.length
  })
}

export const getEmptySelectMessage = (t: TFunction) => (): string => t('common:noOptions')

export const getSortedParams = (params: IParams): string => {
  const sortedParams: IParams = {}

  for (const [key, value] of Object.entries(params)) {
    if (key !== 'page' && key !== 'perPage') {
      sortedParams[key] = value
    }
  }

  if (params.page) sortedParams.page = params.page

  if (params.perPage) sortedParams.perPage = params.perPage

  return queryString.stringify(sortedParams, { sort: false })
}

const SINGULAR = 0
const PLURAL = 1

// ToDo: all languages support needs to be completed
export const getPluralCounter = (n: number, language?: string): number => (n === 1 ? SINGULAR : PLURAL)

export const getMassiveActions = (t: TFunction, page?: MassiveActionPage): ILibDropdownItem[][] => {
  const allActions = [
    [
      { name: AllowedAction.SIGN, translation: t('tasks:buttons.signing') },
      { name: AllowedAction.APPROVE, translation: t('tasks:buttons.approval') }
    ],
    [
      { name: AllowedAction.DECLINE_SIGN, translation: t('tasks:buttons.refuseSigning') },
      { name: AllowedAction.DECLINE_APPROVE, translation: t('tasks:buttons.refuseApproved') }
    ],
    [
      { name: AllowedAction.START_PROCESS, translation: t('tasks:buttons.startProcess') },
      { name: AllowedAction.DELETE, translation: t('massiveActions:delete') }
    ],
    [
      { name: AllowedAction.DOWNLOAD_DOCUMENTS, translation: t('massiveActions:downloadDocuments') },
      { name: AllowedAction.DOWNLOAD_REGISTRY, translation: t('massiveActions:downloadRegistry') }
    ],
    [
      { name: AllowedAction.RESEND, translation: t('administrator:users.resend') },
      { name: AllowedAction.DELETE_INVITE, translation: t('common:delete') }
    ]
  ]

  switch (page) {
    case MassiveActionPage.TASKS: {
      const allowedActions = [
        AllowedAction.SIGN,
        AllowedAction.APPROVE,
        AllowedAction.DECLINE_SIGN,
        AllowedAction.DECLINE_APPROVE,
        AllowedAction.DOWNLOAD_DOCUMENTS,
        AllowedAction.DOWNLOAD_REGISTRY
      ]

      return filterAllowedActions(allActions, allowedActions)
    }

    case MassiveActionPage.DOCUMENTS: {
      const allowedActions = [
        AllowedAction.SIGN,
        AllowedAction.APPROVE,
        AllowedAction.DECLINE_SIGN,
        AllowedAction.DECLINE_APPROVE,
        AllowedAction.START_PROCESS,
        AllowedAction.DELETE,
        AllowedAction.DOWNLOAD_DOCUMENTS,
        AllowedAction.DOWNLOAD_REGISTRY
      ]

      return filterAllowedActions(allActions, allowedActions)
    }

    case MassiveActionPage.MESSAGES: {
      const allowedActions = [
        AllowedAction.SIGN,
        AllowedAction.APPROVE,
        AllowedAction.START_PROCESS,
        AllowedAction.DECLINE_SIGN,
        AllowedAction.DECLINE_APPROVE,
        AllowedAction.DOWNLOAD_DOCUMENTS
      ]

      return filterAllowedActions(allActions, allowedActions)
    }

    case MassiveActionPage.INVITES: {
      const allowedActions = [
        AllowedAction.RESEND,
        AllowedAction.DELETE_INVITE
      ]

      return filterAllowedActions(allActions, allowedActions)
    }

    default: {
      return allActions
    }
  }
}

const filterAllowedActions = (allActions: ILibDropdownItem[][], allowedActions: string[]): ILibDropdownItem[][] => {
  const { isExchangeParticipant, isFlowFunctionalityEnabled } = store.getState().user
  const { isRootMode } = store.getState().utils

  return allActions.map((actions) => actions.filter((action) => {
    const flowActions: string[] = [
      AllowedAction.SIGN,
      AllowedAction.APPROVE,
      AllowedAction.DECLINE_SIGN,
      AllowedAction.DECLINE_APPROVE,
      AllowedAction.START_PROCESS
    ]

    const exchangeActions: string[] = [
      AllowedAction.SIGN,
      AllowedAction.DECLINE_SIGN
    ]

    const rootModeActions: string[] = [
      AllowedAction.APPROVE,
      AllowedAction.DECLINE_APPROVE
    ]

    if (!allowedActions.includes(action.name)) return false

    if (isRootMode) return rootModeActions.includes(action.name)

    if (!isFlowFunctionalityEnabled) {
      return isExchangeParticipant
        ? !(flowActions.includes(action.name) && !exchangeActions.includes(action.name))
        : !flowActions.includes(action.name)
    }

    return true
  }))
}

export const getDocumentInfo = (
  { documentDate, documentNumber }: IDocumentFields,
  t: (translate: string) => string
): string => {
  const number = documentNumber && typeof documentNumber !== 'object' ? ` № ${String(documentNumber)}` : ''

  const date = documentDate ? ` ${t('common:from')} ${DateTime.fromMillis(+documentDate).toFormat(DateFormat.DATE)}` : ''

  return `${number}${date}`
}

export const getNestedTodos = (todos: ITodo[]): ITodo[] => {
  return todos.reduce((result: ITodo[], todo: ITodo) => {
    return [...result, todo, ...getNestedTodos(todo.todos)]
  }, [])
}

export const getOrganizationLink = (path: string, isAdd = false): ReactNode => (
  <OrganizationLink to={path}>
    {isAdd ? <IconPlus color='success-500' externalClass='m-1' /> : <IconEditAlt externalClass='m-1' />}
  </OrganizationLink>
)

export const getDictionaryValueFromOption = ({ label, value }: ILibOption): IHandbook => ({
  key: value,
  value: label
})

export const getOptionFromDictionaryValue = ({ key, value }: IHandbook): ILibOption => ({
  value: key,
  label: value
})

export const getOptionFromContractorData = ({ nameShort, oguid }: IContractorData): ILibOption => ({
  value: oguid,
  label: nameShort
})

export const getOptionFromFlowStageNameData = (value: string): ILibOption => ({
  value,
  label: value
})

export const getOptionFromFlowStageTypeData = ({ name, type }: IFlowStageType): ILibOption => ({
  value: type,
  label: name
})

export const getSubOrgValueFromOption = ({ label, value }: ILibTreeSelectOption): ISubOrg => ({
  name: label,
  oguid: value
})

export const getOptionFromSubOrgValue = ({ name, oguid }: ISubOrg): ILibOption => ({
  value: oguid,
  label: name
})

export const getOptionFromContractorValue = ({ inn, nameShort, oguid }: IContractorData): ILibOption => ({
  value: oguid,
  label: `${nameShort}${inn ? ` (ИНН ${inn})` : ''}`
})

export const getOptionFromFieldValue = (lng: string) => (field: IField) => ({
  value: field.key,
  label: field.component.labels[lng]
})

export const getStatusInfo = (
  flowState: FlowStateType | null,
  workflowStatuses: string[] | null,
  allStatuses: IWorkflowStatuses
): IDocumentStatusInfo => {
  if (workflowStatuses === null) {
    return {
      icon: null,
      textClass: TextColorClass.MUTED
    }
  }

  if (flowState === FlowState.IN_PROGRESS) {
    return {
      icon: StatusIcons[StatusIcon.PROGRESS],
      textClass: TextColorClass.WARNING
    }
  }

  for (const status of workflowStatuses) {
    const { severity } = allStatuses[status]

    switch (severity) {
      case Severity.ERROR:
        return {
          icon: StatusIcons[StatusIcon.ERROR],
          textClass: TextColorClass.DANGER
        }

      case Severity.INFO:
        return {
          icon: StatusIcons[StatusIcon.INFO],
          textClass: TextColorClass.INFO
        }

      case Severity.SUCCESS:
        return {
          icon: StatusIcons[StatusIcon.SUCCESS],
          textClass: TextColorClass.SUCCESS
        }

      case Severity.UNKNOWN:
        return {
          icon: StatusIcons[StatusIcon.UNKNOWN],
          textClass: TextColorClass.MUTED
        }

      case Severity.WARNING:
        return {
          icon: StatusIcons[StatusIcon.WARNING],
          textClass: TextColorClass.WARNING
        }
    }
  }

  return {
    icon: null,
    textClass: TextColorClass.MUTED
  }
}

export const getCopyBtn = (copyText: string, t: TFunction, isCopyId?: boolean): ReactNode => (
  <Button
      onClick={() => handleCopy(copyText, t, isCopyId)}
      externalClass='ml-1'
      title={t('common:copy')}
      theme='text'
  >
    <IconCopy size='sm' color='gray-600' />
  </Button>
)

export const handleCopy = (copyText: string, t: TFunction, isCopyId?: boolean): void => {
  navigator.clipboard.writeText(copyText.toString())
    .then(() => displayBasicNotification({
      title: t('common:copyTitle'),
      content: isCopyId ? t('common:copyID') : t('common:copyContent'),
      type: 'info'
    }))
    .catch(displayErrorNotification)
}

export const sliceRelatedDocsFileName = (filename: string): string => {
  const [name, extension] = filename.split(/\.([^.]*)$/g)
  const isLongName = name.length > relatedDocsFileNameLength

  let slicedName

  if (isLongName) {
    slicedName = filename.slice(0, relatedDocsFileNameLength)
  } else {
    slicedName = name
  }

  return `${slicedName}${isLongName && extension ? '~' : ''}${extension && '.'}${extension ?? ''}`
}

export const isListTooShort = (pageIndex: number, length: number): boolean => {
  return pageIndex === 1 && length < MINIMUM_ITEMS_COUNT_TO_SHOW_PAGINATION
}

export const redirectBack = (to: ITo): void => {
  const { match, history, section } = to
  const isHistory = window.history.length > 2

  if (isHistory) return history.goBack()

  const url = createUrl(match, section)
  return history.push(url)
}

export const getUserRoleRestrictions = (user: IUserData): IUserRoleRestrictions => {
  const { role, isReadonly, activeOrganization } = user

  const { isExchangeParticipant = false, isFlowFunctionalityEnabled = false } = activeOrganization

  return {
    role,
    isExchangeParticipant,
    isFlowFunctionalityEnabled,
    isReadonly
  }
}

export const fillAlias = (setFieldValue: FormikHelpers<any>['setFieldValue'], values: FormikValues): void => {
  const { contractor, nameShort } = values

  if (nameShort || contractor) {
    const name = contractor ? contractor.nameShort : nameShort

    const alias = generateAlias(name)

    setFieldValue(RegisterField.ALIAS, alias)
  }
}

export const generateAlias = (nameShort: string): string =>
  slug(nameShort.replace(Alias.ORG_TYPE_REGEXP, ''), Alias.SPACE_REPLACER)
    .replace(Alias.SYMBOLS_REGEXP, '')
    .toLowerCase()
    .slice(0, Alias.MAX_LENGTH)

/**
 * Function to convert the numeric value of a badge to a string value
 * taking into account the limit on the maximum allowed value MAX_BADGES_COUNT
 * @param count - badge numeric value (for example, 5, 10, 100)
 * @return badge string value (e.g. '5', '10', '99+')
 */
export const getBadgeValue = (count: number): string => {
  return count <= MAX_BADGES_COUNT ? count.toString() : `${MAX_BADGES_COUNT}+`
}

export const hasError = (name: string, errors?: FormikErrors<any>, touched?: FormikTouched<any>, disableTouched?: boolean): boolean =>
  has(errors, name) && (has(touched, name) || !!disableTouched)

/**
 * For translations to work, the function must be wrapped in t() at the calling location.
 */
export const getErrorText = (name: string, errors?: FormikErrors<any>): string =>
  has(errors, name) ? String(get(errors, name)) : ''

export const getDictErrorText = (name: string, errors?: FormikErrors<any>): string =>
  (typeof errors?.[name] === 'object' && errors[name]?.hasOwnProperty(name))
    ? String(errors[name]?.[name])
    : getErrorText(name, errors)

/**
 * Arranging available actions according to a template
 * @param templateActions a template according to which the order of actions is built
 * @param sortingActions actions to order
 */
export const sortActions = (templateActions: IAllowedAction[], sortingActions: IAllowedAction[]): IAllowedAction[] => {
  const sortedActions: IAllowedAction[] = []

  templateActions.map((action: any) => {
    if (sortingActions.includes(action)) {
      sortedActions.push(action)
    }
  })

  return sortedActions
}

export const clearLocalStorage = (): void => {
  const keysToClear = Object.keys(localStorage).filter(
    (key) => !(LocalStorageKeysForSaveBetweenSessions as string[]).includes(key)
  )

  keysToClear.forEach((key) => {
    localStorage.removeItem(key)
  })
}

export const getTaskIcon = (
  { result, state }: IDocumentTask,
  size = 'xs',
  classes?: string,
  hint?: string,
  preProcessingStatus?: IPreProcessingJobStatus
): ReactNode => {
  const flowResult = String(result)
  const flowState = String(state)

  if (flowState === FlowState.NOT_STARTED || !state) {
    switch (preProcessingStatus) {
      case IPreProcessingJobStatus.COMPLETED:
        return cloneElement(FlowIcons.result.SOLVED, { classes, size, hint })
      case IPreProcessingJobStatus.IN_QUEUE:
      case IPreProcessingJobStatus.IN_PROGRESS:
        return cloneElement(FlowIcons.state.IN_PROGRESS, { classes, size, hint })
      case IPreProcessingJobStatus.ERROR:
        return cloneElement(FlowIcons.result.ERROR, { classes, size, hint })
      case IPreProcessingJobStatus.STOPPED:
        return cloneElement(FlowIcons.result.STOPPED, { classes, size, hint })
      default:
    }
  }

  if (!result && !state) return null

  if (flowResult === FlowResult.CANCELED) {
    return cloneElement(FlowIcons.result.CANCELED, { classes, size, hint })
  }

  if (flowResult === FlowResult.CLOSED) {
    return cloneElement(FlowIcons.result.CLOSED, { classes, size, hint })
  }

  if (flowResult === FlowResult.ERROR) {
    return cloneElement(FlowIcons.result.ERROR, { classes, size, hint })
  }

  return flowState === FlowStateStatus.COMPLETED
    ? cloneElement(FlowIcons.result[flowResult], { classes, size, hint })
    : cloneElement(FlowIcons.state[flowState], { classes, size, hint })
}

export const getStageIcon = (state: string, hasCanceledTasks: boolean, size = 'xs', classes?: string): ReactNode => {
  if (!state) return null

  const flowState = String(state)

  if (flowState === FlowStateStatus.IN_PROGRESS) {
    return cloneElement(FlowIcons.state.IN_PROGRESS, { classes, size })
  }

  if (flowState === FlowStateStatus.WAITING) {
    return cloneElement(FlowIcons.state.WAITING, { classes, size })
  }

  if (flowState === FlowStateStatus.CANCELED) {
    return cloneElement(FlowIcons.result.CANCELED, { classes, size })
  }

  return flowState === FlowStateStatus.COMPLETED && hasCanceledTasks
    ? cloneElement(FlowIcons.result.DECLINED, { classes, size })
    : cloneElement(FlowIcons.result.APPROVED, { classes, size })
}

export const getFlowStatusColor = ({ result, state }: IDocumentTask, preProcessingStatus?: IPreProcessingJobStatus): string => {
  const flowResult = String(result)
  const flowState = String(state)

  if (!state || flowState===FlowState.NOT_STARTED) {
    switch (preProcessingStatus) {
      case IPreProcessingJobStatus.COMPLETED:
        return FlowStatusColor.result.SOLVED
      case IPreProcessingJobStatus.IN_QUEUE:
      case IPreProcessingJobStatus.IN_PROGRESS:
        return FlowStatusColor.state.IN_PROGRESS
      case IPreProcessingJobStatus.ERROR:
        return FlowStatusColor.result.CANCELED
      case IPreProcessingJobStatus.STOPPED:
        return FlowStatusColor.result.STOPPED
      default:
    }
  }

  if (flowResult === FlowResult.CANCELED) {
    return FlowStatusColor.result.CANCELED
  }

  if (flowResult === FlowResult.STOPPED) {
    return FlowStatusColor.result.STOPPED
  }

  return flowState === FlowStateStatus.COMPLETED ? FlowStatusColor.result[flowResult] : FlowStatusColor.state[flowState]
}

export const renderTaskStatus = (
  task: IDocumentTask,
  stageType: Nullable<IFlowStageType>,
  t: TFunction,
  classes?: string,
  isAutoCompleted?: Nullable<boolean>,
  preProcessingStatus?: IPreProcessingJobStatus
): ReactNode => {
  const textColor = `text-${getFlowStatusColor(task, preProcessingStatus)}`

  const statusText = getStatusText(task, stageType, t, isAutoCompleted, preProcessingStatus);

  if (
    (String(task.state) === FlowState.NOT_STARTED || !task.state) &&
    preProcessingStatus === IPreProcessingJobStatus.IN_QUEUE ||
    preProcessingStatus === IPreProcessingJobStatus.ERROR ||
    preProcessingStatus === IPreProcessingJobStatus.STOPPED
  ) {
    const newStatusText = statusText.split(" ")
    const last = newStatusText.pop();
    const other = newStatusText.join(' ');

    return (
      <div className={cn(classes, textColor)}>
        <div>{other}</div>
        <div>{last}</div>
      </div>
    );
  } else {
    return <span className={cn(classes, textColor)}>{statusText}</span>;
  }
}

export const getStatusText = (
  status: IDocumentTask,
  stageType: Nullable<IFlowStageType>,
  t: TFunction,
  isAutoCompleted?: Nullable<boolean>,
  preProcessingStatus?: IPreProcessingJobStatus
): string => {
  const { state, result } = status
  const flowResult = String(result)
  const flowState = String(state)

  if (flowResult === FlowResult.CANCELED) {
    return t(TaskResult.CANCELLED)
  }

  if (flowResult === FlowResult.STOPPED) {
    return t(TaskResult.STOPPED)
  }

  switch (flowState) {
    case FlowState.COMPLETED:
      switch (flowResult) {
        case FlowResult.DECLINED:
        case FlowResult.REJECTED:
          return stageType?.declinedCaption ?? t(TaskResult.CANCELLED)

        case FlowResult.APPROVED:
        case FlowResult.SOLVED:
          return isAutoCompleted ? t(TaskResult.AUTOCOMPLETED) : stageType?.solvedCaption ?? t(TaskResult.COMPLETED)

        default:
          return ''
      }

    case FlowState.WAITING:
      return t(TaskState.NOT_STARTED)

    case FlowState.IN_PROGRESS:
      switch (preProcessingStatus) {
        case IPreProcessingJobStatus.IN_QUEUE:
        case IPreProcessingJobStatus.IN_PROGRESS:
          return t(Recognition.status[preProcessingStatus]);
        case undefined:
        case IPreProcessingJobStatus.NOT_APPLICABLE:
        case IPreProcessingJobStatus.STOPPED:
        case IPreProcessingJobStatus.COMPLETED:
        case IPreProcessingJobStatus.ERROR:
        default: {
          return stageType?.name ?? t(TaskState.IN_PROGRESS);
        }
      }

    case FlowState.NOT_STARTED:
    default:
      switch (preProcessingStatus) {
        case undefined:
        case IPreProcessingJobStatus.NOT_APPLICABLE:
          return "";
        case IPreProcessingJobStatus.ERROR:
          return `${t(Recognition.status.ERROR)}`;
        default:
          return t(Recognition.status[preProcessingStatus]);
      }
  }
}

export const getStageType = (type: string): Nullable<IFlowStageType> => {
  const { flowStageTypes } = store.getState().metadata

  return flowStageTypes.find((stageType) => type === stageType.type) ?? null
}

export const getSelectedDocumentsActions = (params: {
  documents?: IDocument[]
  messages?: IMessage[]
  selectedDocuments: ISelectedDocuments
  taskMessages?: ITaskMessage[]
}): IAllowedAction[] => {
  const { documents, messages, selectedDocuments, taskMessages } = params

  if (!documents && !messages && !taskMessages) {
    return []
  }

  const selectedDocumentsOguids = Object.keys(selectedDocuments).filter(
    (documentID: string) => selectedDocuments[documentID]
  )

  const actions: IAllowedAction[] = []

  if (documents) {
    selectedDocumentsOguids.forEach((oguid) => {
      const document = documents.find((document) => document.oguid === oguid)

      document && actions.push(...document.allowedActions)
    })
  }

  if (messages) {
    const messagesDocuments = messages.reduce((acc: IDocument[], message) => [...acc, ...message.documents], [])

    selectedDocumentsOguids.forEach((oguid) => {
      const document = messagesDocuments.find((document) => document.oguid === oguid)

      document && actions.push(...document.allowedActions)
    })
  }

  if (taskMessages) {
    selectedDocumentsOguids.forEach((oguid) => {
      const taskMessage = taskMessages.find((taskMessage) => taskMessage.task.oguid === oguid)

      taskMessage && actions.push(...taskMessage.document.allowedActions)
    })
  }

  return Array.from(new Set(actions))
}

export const checkActions = (
  params: {
    documents?: IDocument[]
    messages?: IMessage[]
    selectedDocuments: ISelectedDocuments
    taskMessages?: ITaskMessage[]
  },
  action: IAllowedAction,
  onError: (message: string) => void,
  onSuccess: (action: IAllowedAction) => void
): void => {
  if (action === AllowedAction.SIGN) {
    const selectedDocumentsActions = getSelectedDocumentsActions(params)

    if (
      !selectedDocumentsActions.includes(AllowedAction.SIGN) &&
      !selectedDocumentsActions.includes(AllowedAction.SIGN_TITLE)
    ) {
      return onError(Documents.noDocsForSign)
    }

    if (
      selectedDocumentsActions.includes(AllowedAction.SIGN) &&
      selectedDocumentsActions.includes(AllowedAction.SIGN_TITLE)
    ) {
      return onError(Documents.conflictSignActions)
    }

    if (
      selectedDocumentsActions.includes(AllowedAction.SIGN) &&
      !selectedDocumentsActions.includes(AllowedAction.SIGN_TITLE)
    ) {
      return onSuccess(AllowedAction.SIGN)
    }

    if (
      !selectedDocumentsActions.includes(AllowedAction.SIGN) &&
      selectedDocumentsActions.includes(AllowedAction.SIGN_TITLE)
    ) {
      return onSuccess(AllowedAction.SIGN_TITLE)
    }
  }

  return onSuccess(action)
}

export const getDocumentStages = (recipients: Nullable<IDocumentRecipient[]>): IFlowStage[] => {
  const stages: IFlowStage[] = []

  if (recipients) {
    recipients.forEach(({ actionType, oguid, assignedToGroupOguid, assignMode }) => {
      const existingStage = stages.find(({ type }) => type === ActionTypeToStageType[actionType])

      if (existingStage) {
        existingStage.assignees.push({
          assignMode: assignMode ?? null,
          assignedToGroupOguid: assignedToGroupOguid ?? null,
          assignedToUserOguid: !assignedToGroupOguid ? oguid : null
        })
      } else {
        stages.push({
          userName: null,
          type: ActionTypeToStageType[actionType],
          assignees: [
            {
              assignMode: assignMode ?? null,
              assignedToGroupOguid: assignedToGroupOguid ?? null,
              assignedToUserOguid: !assignedToGroupOguid ? oguid : null
            }
          ]
        })
      }
    })
  }

  return stages
}

/**
 * Function to search by field key in a list of fields (in metadata)
 * @param key - field key
 * @return field if found; null if not found
 */
export const getField = (key: string): Nullable<IField> => {
  const { fields } = store.getState().metadata

  const field = fields.find((field) => field.key === key)

  return field ?? null
}

/**
 * Function to get a list of options for document types
 * @return array of options
 */
export const getDocTypeOptions = (): ILibOption[] => {
  const { userDocTypes } = store.getState().metadata
  const options = []

  for (const key in userDocTypes) {
    const type = userDocTypes[key]
    const option: ILibOption = {
      value: key,
      label: type.title
    }

    options.push(option)
  }

  return options
}

export const getFlowStageTypeInfo = (flowStageType: string, t: TFunction): IFlowStageType => {
  const { flowStageTypes } = store.getState().metadata

  const targetFlowStageType = flowStageTypes.find(({ type }: IFlowStageType) => type === flowStageType)

  if (!targetFlowStageType) throw new Error(t('document:missingFlowStageType', { flowStageType }))

  return targetFlowStageType
}

export const getButtonCaptionForAction = (action: IAllowedAction, flowStageType: string, t: TFunction): string => {
  return action === AllowedAction.APPROVE
    ? getFlowStageTypeInfo(flowStageType, t).buttonCaption
    : t(MassiveActions[action])
}

// Получение минимального количества знаков после запятой в зависимости от языка и валюты
export const getMinimumFractionsDigits = (
  language: string,
  currency: string
) => {
  const checkNum = 12.34;

  const checkNumDef = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
  }).format(checkNum);

  const checkNumZero = new Intl.NumberFormat(language, {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: currency,
  }).format(checkNum);

  const noFractDigits = checkNumDef === checkNumZero;

  return noFractDigits ? undefined : 2;
};

export const getFormattedSum = (value: Nullable<IDocumentField>, currency?: Nullable<IDocumentField> | fieldValue): string => {
  if (!(typeof value === 'number' || typeof value === 'string')) return ''

  const currencyCodes = currencies.map(({ iso }) => iso)

  const locale = getCurrencyLocale()
  const selectedCurrency = typeof currency === 'string' && currency.length && currencyCodes.includes(currency)
    ? currency
    : getInstanceCurrency()

  const minFractionDigits = getMinimumFractionsDigits(locale, selectedCurrency);

  const formattedSum = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: selectedCurrency,
    minimumFractionDigits: minFractionDigits
  }).format(+value)

  if (selectedCurrency === Currency.RAND || selectedCurrency === Currency.RUBLE) {
    const isRussiaRuble = selectedCurrency === Currency.RUBLE && getLanguageName(locale) === Languages.RU

    const replaceable = isRussiaRuble
      ? currencySymbol[Currency.RUBLE]
      : selectedCurrency

    const symbol = isRussiaRuble
      ? altRubleSymbol
      : currencySymbol[selectedCurrency]

    return formattedSum.replace(replaceable, symbol)
  }

  return formattedSum
}

// Является ли значение URL
export const isUrl = (value: any): boolean => {
  return typeof value === 'string' && !!value.match(urlRegexp)?.length;
};

// Является ли поле не системным полем суммы (SUM_TOTAL, SUM_VAT, SUM_WITHOUT_VAT)
export const getIsNotSumField = (key?: string) => {
  return (
    key !== FieldName.SUM_TOTAL &&
    key !== FieldName.SUM_VAT &&
    key !== FieldName.SUM_WITHOUT_VAT
  );
};

// Получение максимального количества знаков после запятой в зависимости от типа поля
export const getMaximumFractionsDigits = (isNotSumField: boolean) => {
  return isNotSumField ? 6 : undefined;
};

// Получение максимального количества знаков после запятой в зависимости от ключа поля
export const getFieldMaximumFractionsDigits = (key?: string) => {
  const isNotSumField = getIsNotSumField(key);
  return getMaximumFractionsDigits(isNotSumField);
};

export const getFormattedDateValue = (field: IDocumentField): string => {
  if (typeof field === 'number') {
    const datetime = DateTime.fromMillis(getFormattedDateForDateFilter(field))

    return datetime.toFormat(DateFormat.DATE_FULL_YEAR)
  }

  return ''
}

export const getFormattedDatetimeValue = (field: IDocumentField): string => {
  if (typeof field === 'number') {
    const datetime = DateTime.fromMillis(getFormattedDateForDateFilter(field))

    return datetime.toFormat(DateFormat.DATETIME_FULL_YEAR)
  }

  return ''
}

export const getFormattedFieldValue = (params: IGetFormattedFieldValueParams): ReactNode | string => {
  const {
    currency,
    directionIcon,
    directionIconProps,
    isShowSumCurrency,
    isNeedCopyLink,
    key,
    label = '',
    language,
    t,
    type,
    value
  } = params

  const getFormattedBooleanValue = (field: IDocumentField): string =>
    typeof field === 'boolean' ? (field ? t('common:yes') : t('common:no')) : ''

  const getFormattedContractorValue = (field: IDocumentField): string =>
    typeof field === 'object' && 'nameShort' in field ? field.nameShort : ''

  const getFormattedDictionaryValue = (field: IDocumentField): string =>
    typeof field === 'object' && 'value' in field ? field.value : ''

  const getFormattedDirectionValue = (field: IDocumentField): ReactNode => {
    if (typeof field !== 'string') return null

    const directionTitle = t(DocumentDirections[field])

    if (directionIcon) {
      const iconProps: ILibIcon = {
        color: DIRECTION_COLORS[field],
        ...directionIconProps
      }

      const icons = {
        [DirectionValue.INCOMING]: <IconIncoming {...iconProps} />,
        [DirectionValue.OUTGOING]: <IconOutgoing {...iconProps} />
      }

      return icons[field]
    }

    return directionTitle
  }

  const getFormattedNumberValue = (field: IDocumentField): string => {
    const isNotSumField = getIsNotSumField(key);

    if (!isShowSumCurrency || isNotSumField) {
      const maximumFractionDigits = getMaximumFractionsDigits(isNotSumField);
      return getIntlNumberFormat(language, field, maximumFractionDigits);
    }

    return getFormattedSum(field, currency);
  };

  const getFormattedStringValue = (field: IDocumentField, isNeedCopyLink = false): string | ReactNode => {
    if (typeof field !== 'string') return ''

    return field.match(urlRegexp)?.length ? (
      <div>
        <a href={field} target='_blank' rel='noreferrer'>
          {`${t('common:link')} ${label}`}
        </a>
        {isNeedCopyLink
          ? getCopyBtn(field, t)
          : null
        }
      </div>
    ) : (
      field
    )
  }

  const formattedValues = {
    [FieldType.BOOLEAN]: getFormattedBooleanValue,
    [FieldType.CONTRACTOR]: getFormattedContractorValue,
    [FieldType.DATE]: getFormattedDateValue,
    [FieldType.DATETIME]: getFormattedDatetimeValue,
    [FieldType.DIRECTION]: getFormattedDirectionValue,
    [FieldType.DICTIONARY]: getFormattedDictionaryValue,
    [FieldType.NUMBER]: getFormattedNumberValue,
    [FieldType.STRING]: getFormattedStringValue,
    // support for outputting this field by the function is currently not planned.
    // a stub to prevent crashing.
    [FieldType.FILE]: () => null
  }

  if (!value && value !== 0) return ''

  if (formattedValues.hasOwnProperty(FieldType.STRING)) return formattedValues[type](value, isNeedCopyLink)

  return formattedValues.hasOwnProperty(type) ? formattedValues[type](value) : ''
}

export const getTableFilterFieldValue = (
  type: DataType,
  value: Nullable<IDocumentField>
): Nullable<boolean | number | string> => {
  const getFilterBooleanValue = (field: IDocumentField): Nullable<boolean> =>
    typeof field === 'boolean' ? field : null

  const getFilterContractorValue = (field: IDocumentField): Nullable<string> =>
    typeof field === 'object' && 'oguid' in field ? field.oguid : null

  const getFilterDictionaryValue = (field: IDocumentField): Nullable<string> =>
    typeof field === 'object' && 'value' in field ? field.key : null

  const getFilterNumberValue = (field: IDocumentField): Nullable<number> =>
    typeof field === 'number' ? field : null

  const getFilterStringValue = (field: IDocumentField): Nullable<string> =>
    typeof field === 'string' ? field : null

  const filterValues = {
    [FieldType.BOOLEAN]: getFilterBooleanValue,
    [FieldType.CONTRACTOR]: getFilterContractorValue,
    [FieldType.DATE]: getFilterNumberValue,
    [FieldType.DATETIME]: getFilterNumberValue,
    [FieldType.DIRECTION]: getFilterStringValue,
    [FieldType.DICTIONARY]: getFilterDictionaryValue,
    [FieldType.NUMBER]: getFilterNumberValue,
    [FieldType.STRING]: getFilterStringValue
  }

  return filterValues.hasOwnProperty(type) && value !== null ? filterValues[type](value) : ''
}

export const sendErrorToSentry = (options: ISentryOptions): void => {
  const {
    activeOrganization: { name, oguid },
    profile: user
  } = store.getState().user

  const errorLevel: SeverityLevel = 'fatal'
  const errorInfo = {
    ...options.info,
    organizationName: name,
    organizationOguid: oguid,
    userOguid: user.oguid
  }

  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setLevel(errorLevel)
    scope.setExtras(errorInfo)
    Sentry.captureException(new Error(options.message))
  })
}

// check that the option exists and the option is not an array
export const selectChangeCheck = (cb: any, isCheckOption = true) => (option: ILibSelectValueType | IGroupType): SelectChangeFunction | undefined => {
  if (!isCheckOption && !Array.isArray(option)) {
    return cb(option)
  }

  if (option && !Array.isArray(option)) {
    return cb(option)
  }

  return undefined
}

export const getCertificatesFromLocalStorage = (key: string): Nullable<ICertificates> => {
  const dataFromCache = localStorage.getItem(key)

  return dataFromCache ? JSON.parse(dataFromCache) : null
}

export const getDetailedTableColumnsTemplate = (
  fields: IField[],
  language: string,
  t: TFunction
): ILibDetailedTableColumn[] => {
  const { isFlowFunctionalityEnabled } = store.getState().user

  const getFieldTitle = (fieldKey: string): string =>
    fields.find(({ key }) => key === fieldKey)?.component.labels[language] ?? fieldKey

  const beginColumns: ILibDetailedTableColumn[] = DetailedTableColumnOrderTemplate.beginColumns.map((colName) => {
    if (colName === 'DOCUMENT_DATE' || colName === 'DOCUMENT_NUMBER' || colName === 'DIRECTION') {
      return {
        isVisible: true,
        key: FieldName[colName],
        title: getFieldTitle(FieldName[colName])
      }
    }

    return {
      isVisible: true,
      isVisibilityLocked: colName === 'CONSTRAINTS' || colName === 'DOCUMENT_ID' || colName === 'SELECT_DOCUMENT',
      key: DetailedTableColumn[colName].key,
      title: t(DetailedTableColumn[colName].title)
    }
  })

  const fieldsColumns: ILibDetailedTableColumn[] = fields
    .filter(({ key }) => key !== FieldName.DOCUMENT_DATE && key !== FieldName.DOCUMENT_NUMBER && key !== FieldName.DIRECTION)
    .map(({ key, component: { labels } }) => ({
      isVisible: true,
      key,
      title: labels[language] ?? key
    }))

  const endColumnsOrderTemplate = isFlowFunctionalityEnabled ? 'endColumnsWithFlow' : 'endColumns'

  const endColumns: ILibDetailedTableColumn[] = DetailedTableColumnOrderTemplate[endColumnsOrderTemplate].map((colName) => ({
    isVisible: true,
    key: DetailedTableColumn[colName].key,
    title: t(DetailedTableColumn[colName].title)
  }))

  return [...beginColumns, ...fieldsColumns, ...endColumns]
}

export const getIntlNumberFormat = (
  language: string,
  value: Nullable<IDocumentField>,
  maximumFractionDigits?: number
): string =>
  typeof value === 'number' || typeof value === 'string'
    ? new Intl.NumberFormat(language, {
        maximumFractionDigits,
      }).format(+value)
    : '';

export const getTemplateFileName = (
  typeTitle: string,
  documentDate?: IDocumentField | fieldValue,
  documentNumber?: IDocumentField | fieldValue
): string => {
  const { language, getResource } = i18n

  const docNumber = typeof documentNumber === 'string' ? `№ ${documentNumber}` : ''
  const docDate =
    typeof documentDate === 'number'
      ? DateTime.fromMillis(documentDate).toFormat(DateFormat.DATE_FULL_YEAR)
      : DateTime.now().toFormat(DateFormat.DATE_FULL_YEAR)

  const fromString = String(getResource(language, 'common', 'from'))

  return `${typeTitle} ${docNumber} ${fromString} ${docDate}`
}

export const setDocTypeFieldKey = (
  allKeys: string[],
  maxLength: number,
  values: IDocumentTypeState | IFieldValues,
  prevValues: IDocumentTypeState | IFieldValues,
  keys: IDocTypeFieldKeys,
  setFieldValue: (field: string, value: unknown) => void
): void => {
  const {
    key,
    localization
  } = values

  const {
    localization: prevLocalization
  } = prevValues

  if (isEqual(localization, prevLocalization)) return

  const {
    base = 'field',
    title: titleKeyBase
  } = keys

  const titles: IDocTypeFieldTitles = Object.keys(localization).reduce((acc, key) => {
    if (!key.includes(titleKeyBase)) return acc

    return {
      ...acc,
      [key.replace(titleKeyBase, '')]: localization[key]
    }
  }, {})

  const title = slug(titles.en || titles.pt || titles.ru || base, {
    replacement: '_',
    lower: false
  })

  const titleKey = camelCase(title)

  const newKey = getComputedKey(allKeys, maxLength, titleKey.replace(titleKey[0], title[0]))

  if (key === newKey) return

  setFieldValue('key', newKey)
}

export const getComputedKey = (allKeys: string[], maxLength: number, oldKey = 'field'): string => {
  const [keyBase] = oldKey.split(copiedFieldNumberRegexp)

  const baseMatchedKeys = allKeys.filter((key) => (
    key.match(new RegExp(`^${keyBase}_\\d+$`, 'g'))
  ))

  const largestKeyId = baseMatchedKeys
    .map((key) => (
      +(key.split(`${keyBase}_`).pop() ?? '')
    ))
    .sort((a, b) => +a - +b)
    .pop() ?? 0

  const newKeyId = largestKeyId < MAX_FIELD_KEY_ID
    ? largestKeyId + 1
    : nanoid(6)

  const prefixedKeyId = largestKeyId || allKeys.includes(keyBase) ? `_${newKeyId}` : ''

  return `${keyBase.slice(0, maxLength - prefixedKeyId.length)}${prefixedKeyId}`
}

export const saveLastUrl = (location: ILocation): void => {
  const url = location.pathname + location.search
  localStorage.setItem(BrowserStorage.DOC_LAST_URL, url)
}

export interface ILocation {
  pathname: string
  search: string
}

export const getUrlSearchParams = (params: any): URLSearchParams => {
  return new URLSearchParams(Object.entries(params))
}

export const getLanguageName = (locale: string): string => {
  locale = locale.substring(0, 2)

  return Object.values(Languages).includes(locale) ? locale : Languages.EN
}

export const getDetailedTableColumns = (
  language: string,
  t: TFunction,
  tableSettings?: object
): ILibDetailedTableColumn[] => {
  const { userDocTypes, fields } = store.getState().metadata

  const documentTypesFieldsKeys: string[] = Object.keys(userDocTypes).reduce((typesFields: string[], key) => {
    const { fields } = userDocTypes[key]

    const addedFields = Object.keys(fields).reduce((typeFields: string[], key) => {
      return typesFields.includes(key)
        ? typeFields
        : [
          ...typeFields,
          key
        ]
    }, [])

    return [
      ...typesFields,
      ...addedFields
    ]
  }, [])

  const tableColumns = tableSettings?.[UserSettingsKey.TABLE_COLUMNS] ?? []

  const tableColumnsTemplate = getDetailedTableColumnsTemplate(fields, language, t)

  const templateColumnsKeys = tableColumnsTemplate.map(({ key }) => key).sort()
  const savedColumnsKeys = tableColumns.map(({ key }: ILibDetailedTableColumn) => key).sort()

  const savedColumns: ILibDetailedTableColumn[] = tableColumns.map((column: ILibDetailedTableColumn) => {
    const { key } = column

    const { isVisibilityLocked = false, title = '' } =
      tableColumnsTemplate.find(({ key: columnKey }) => columnKey === key) ?? {}

    return {
      ...column,
      isVisibilityLocked,
      title
    }
  })

  if (isEqual(templateColumnsKeys, savedColumnsKeys)) {
    return savedColumns
  }

  const cleanedSavedColumns = savedColumns.reduce((acc: ILibDetailedTableColumn[], column) => {
    const { key } = column

    if (templateColumnsKeys.includes(key)) {
      return [...acc, column]
    }

    return acc
  }, [])

  const combinedColumns = tableColumnsTemplate.reduce((acc: ILibDetailedTableColumn[], column) => {
    const { key } = column

    const isColumnExist = cleanedSavedColumns.some(({ key: columnKey }) => columnKey === key)

    if (isColumnExist) return acc

    const isVisible = cleanedSavedColumns.every(({ isVisible }) => isVisible)

    return [
      ...acc,
      {
        ...column,
        isVisible
      }
    ]
  }, cleanedSavedColumns)

  return combinedColumns.filter(({ key }) => (
    fields.some((field) => field.key === key)
      ? documentTypesFieldsKeys.includes(key)
      : true
  ))
}

export const getCurrentUserRecipient = (): IRecipient => {
  const { profile } = store.getState().user

  const { name, oguid, patronymic, surname } = profile

  return {
    name,
    oguid,
    patronymic,
    surname
  }
}

export const getDetailedTableFilterParams = (options: ILibDetailedTableCellFilterOptions, search: string): IParams => {
  const { key, value } = options

  const currentParams = queryString.parse(search)

  const params = Object.keys(currentParams).reduce((acc: IParams, paramKey) => {
    if (paramKey === key || paramKey === 'page' || paramKey === 'perPage') return acc

    return {
      ...acc,
      [paramKey]: currentParams[paramKey]
    }
  }, {})

  if (!(key in currentParams)) {
    const getValue = (): Nullable<string | string[]> => {
      if (Array.isArray(value)) return value

      return value !== undefined && value !== null ? String(value) : null
    }

    params[key] = getValue()
  }

  return params
}

export const updateMissingDocType = (type: string): void => {
  const {
    utils: {
      missingTypes
    }
  } = store.getState()

  if (missingTypes.includes(type)) return

  Promise.resolve(store.dispatch(getUserDocTypes()))
    .catch(displayErrorNotification)
  store.dispatch(setMissingType(type))
}

export const updateMissingDocTypeFields = (fields: string[], type: string, cb: () => void): void => {
  const {
    utils: {
      missingTypeFields
    }
  } = store.getState()

  if (!fields.length) return

  if (fields.every((field) => missingTypeFields[type]?.includes(field))) return cb()

  Promise.resolve(store.dispatch(getUserDocTypes()))
    .catch(displayErrorNotification)
  store.dispatch(setMissingTypeFields({ fields, type }))
}

export const updateMissingFields = (fields: string[], cb: () => void): void => {
  const {
    utils: {
      missingFields
    }
  } = store.getState()

  if (!fields.length) return

  if (fields.every((field) => missingFields.includes(field))) return cb()

  Promise.resolve(store.dispatch(getFields()))
    .catch(displayErrorNotification)
  store.dispatch(setMissingFields(fields))
}

export const getDivisionsOptions = (divisions: IDivision[], lvl = 0, parent?: string): ILibTreeSelectOption[] => {
  const filteredDivisions = divisions
    .filter(({ level, parentOguid }) => level === lvl && (parent ? parentOguid === parent : true))

  return filteredDivisions.map(({ name, oguid }) => {
    const children = getDivisionsOptions(divisions, lvl + 1, oguid)

    const option: ILibTreeSelectOption = {
      label: name,
      value: oguid
    }

    if (children.length) {
      option.children = children
    }

    return option
  })
}

export const getIsHorizontalNavMode = (
  userSettings = store.getState().user.settings
): boolean =>
  userSettings?.[UserSettingsKey.NAV_MODE] === NavMode.HORIZONTAL

export const getBaseUrl = (): string => cookies.get(apiUrlCookieName) ?? BASE_URL

export const getFormattedInstanceTitle = (instance?: string): string => {
  const { instance: appInstance } = store.getState().utils

  return (instance ?? appInstance).replace(/\W/g, '')
}

export const getOgrnTitle = (): string => Common['ogrn' + getInstancePostfix()]

export const getInstancePostfix = (): string => {
  const { instance } = store.getState().utils

  return getFormattedInstanceTitle(instance.charAt(0).toUpperCase() + instance.slice(1))
}

export const getInstance = (passedInstance?: string): Instance => {
  const instance = passedInstance ?? getInstance(store.getState().utils.instance)

  if (instance === Instance.BRASIL) return Instance.BRASIL

  if (instance === Instance.RUSSIAN) return Instance.RUSSIAN

  if (instance === Instance.SOUTH_AFRICA) return Instance.SOUTH_AFRICA

  return Instance.GLOBAL
}

/** Получение инстанса, совместимого с frontend-libraries */
export const getOldInstance = (instance: Instance): flInstance => {
  switch (instance) {
    case Instance.SOUTH_AFRICA:
      return flInstance.SOUTH_AFRICA;
    case Instance.BRASIL:
      return flInstance.BRASIL;
    case Instance.RUSSIAN:
      return flInstance.RUSSIAN;
    default:
      return flInstance.GLOBAL;
  }
}

export const getLanguageFromInstance = (locale: string, instance: Instance | string): string => {
  const isRussiaInstance = instance === Instance.RUSSIAN
  const isRussiaLocale = locale === LocaleType.RU

  if (!locale) {
    switch (instance) {
      case Instance.RUSSIAN:
        return LocaleType.RU
      case Instance.BRASIL:
        return LocaleType.PT
      default:
        return LocaleType.EN_US
    }
  }

  if (isRussiaLocale && isRussiaInstance) return LocaleType.RU

  if (!isRussiaLocale) return locale

  return LocaleType.EN_US
}

export const getPreferredLanguages = (instance: Instance | string, locale: string): string[] => {
  if (instance === Instance.RUSSIAN || locale === Languages.RU) return ['ru', 'en', 'pt']

  return ['en', 'pt']
}

export const getPreferredLocales = (instance: Instance | string, locale: string): string[] => {
  if (instance === Instance.RUSSIAN || locale === Languages.RU) return ['ru', 'en_us', 'en_gb', 'pt']

  return ['en_us', 'en_gb', 'pt']
}

export const getPreferredCountries = (instance: Instance | string): string[] => {
  if (instance === Instance.RUSSIAN) return ['ru', 'us', 'br', 'za']

  return ['us', 'br', 'za']
}

export const checkValidityPhoneNumber = (value: string): boolean =>
  window.intlTelInputUtils
    ? window.intlTelInputUtils.isValidNumber(value, '')
    : true

export const getInitialCountryPhone = (instance: Instance | string): string => {
  const initialCountry = {
    [Instance.RUSSIAN]: PhoneVerifyCountry.RU,
    [Instance.GLOBAL]: PhoneVerifyCountry.US,
    [Instance.BRASIL]: PhoneVerifyCountry.BR,
    [Instance.SOUTH_AFRICA]: PhoneVerifyCountry.ZA
  }

  return initialCountry[instance] ?? PhoneVerifyCountry.US
}

export const getSystemFields = (fields: IField[]): string[] => fields.filter(({ isSystem }) => isSystem).map(({ key }) => key)

export const getGroupedFieldOptions = (options: ILibOption[], fields: IField[], t: TFunction): Array<ILibOption | IGroupType> => {
  const systemFields = getSystemFields(fields)
  const systemOptions: ILibOption[] = []
  const additionalOptions: ILibOption[] = []
  // TODO remove 'fakeOptions' as soon as it becomes possible to pass only groups to the SelectWrapper.
  const fakeOptions: ILibOption[] = []

  options.forEach((option) => {
    if (systemFields.includes(option.value)) return systemOptions.push(option)
    return additionalOptions.push(option)
  })

  return [
    {
      label: t('common:systemFields'),
      options: systemOptions
    },
    {
      label: t('common:additoinalFields'),
      options: additionalOptions
    },
    ...fakeOptions
  ]
}

export const getEnabledState = (param: Nullable<boolean>, t: TFunction): string => (
  param
    ? t('common:enabled', { count: 1, postProcess: 'interval' })
    : t('common:disabled', { count: 1, postProcess: 'interval' })
)
export const getDataFromLocalStorage = (key: string): Nullable<string> => localStorage.getItem(key)

export const getClientToken = (): string => {
  const existsToken = getDataFromLocalStorage(BrowserStorage.TOKEN_CLIENT)
  if (existsToken) return existsToken

  const token = uuidv4()
  localStorage.setItem(BrowserStorage.TOKEN_CLIENT, token)

  return token
}

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result?.toString() ?? '')
    reader.onerror = error => reject(error)
  })
}

export const getCertificateKey = (certificate: Certificate): CertificateKeys => {
  if ('id' in certificate) return 'id'
  if ('validToDate' in certificate) return 'serialNumber'
  return 'oguid'
}

export const getCertificateData = (
  certificate: Certificate,
  crypto: CryptoProProvider,
  t: TFunction
): ICertificateRowData => {
  const getSubjectName = (subject: string): string => crypto.getSubjectInfo(subject, 'CN=').slice(3)

  const getSubjectType = (subject: string): string => {
    const employer = crypto.getSubjectInfo(subject, 'OGRNIP=')
    const organization = crypto.getSubjectInfo(subject, 'OGRN=')

    if (employer.length) {
      return t('crypto:sp')
    }

    if (organization.length) {
      return t('crypto:llc')
    }

    return ''
  }

  if ('id' in certificate) {
    const { CN, dateTo, dateFrom, id: key } = certificate

    return {
      dateFrom,
      dateTo,
      info: CN ? `${CN} (${t('crypto:cloud')})` : null,
      key
    }
  }

  if ('validToDate' in certificate) {
    const { validFromDate: dateFrom, validToDate: dateTo, serialNumber: key, subject } = certificate

    const name = getSubjectName(subject)
    const type = getSubjectType(subject)

    return {
      dateFrom,
      dateTo,
      info: `${type} ${name}`,
      key
    }
  }

  const { cn, dateFrom, dateTo, oguid } = certificate

  return {
    dateFrom,
    dateTo,
    info: cn ? `${cn} (${t('crypto:dsc')})` : null,
    key: oguid
  }
}

export const getCertififateValidity = (
  dateFrom: Nullable<string>,
  dateTo: Nullable<string>,
  crypto: CryptoProProvider,
  t: TFunction
): string => {
  if (!dateFrom && !dateTo) return t('common:notSpecifiedAlt')

  const from = `${dateFrom ? `${t('crypto:from')} ${crypto.getDate(dateFrom)}` : ''}`
  const to = `${dateTo ? ` ${t('crypto:to')} ${crypto.getDate(dateTo)}` : ''}`

  return `${from}${to}`
}

export const getCurrencyLocale = (): string => {
  const { locale } = store.getState().user.profile
  const languageName = getLanguageName(locale)

  return languageName === Languages.PT
    ? 'pt-BR'
    : locale.replace('_', '-')
}

export const getInstanceCurrency = (): Currency => {
  const instance = getInstance(store.getState().utils.instance)

  return instanceCurrency[instance]
}

export const getCopyNotifyText = (): ILibBasicNotificationOptions => {
  const { language, getResource } = i18n

  return {
    title: String(getResource(language, 'common', 'copyTitle')),
    content: String(getResource(language, 'notification', 'copyContentText')),
    type: 'info'
  }
}

export const prepareCellsWithTooltip = (cells: ILibDetailedTableRow, tooltipClass?: string): void => {
  Object.keys(cells).map(key => {
    if (cells[key]?.hint !== null && cells[key]?.isHintCopy !== false) {
      cells[key].copyNotifyText = getCopyNotifyText()
    }

    if (tooltipClass) cells[key].tooltipClasses = tooltipClass
  })
}

export const getNameWithInitials = (user: INameInterface): string => {
  const instance = getInstance()

  if (!instance || instance === Instance.RUSSIAN) {
    return getSurnameWithInitialsBot(user)
  }

  return `${user.name} ${user.patronymic ? user.patronymic[0].toUpperCase() + '. ' : null}${user.surname}`
}

export const getNoticesParams = (category: NoticeCategory, pageNum?: number): IParams => ({
  pageNum: pageNum ?? 1,
  categoryType: category,
  perPage: noticesPerPageDefault
})

export const getIsInterfaceDisabled = (): boolean => {
  const {
    activeOrganization: {
      oguid: orgOguid
    },
    profile: {
      orgs
    }
  } = store.getState().user
  const { isRootMode } = store.getState().utils

  return !orgs.find(({ oguid }) => oguid === orgOguid) && !isRootMode
}

export const getParentWithClass = (ref: HTMLElement | null, parentClass: string): HTMLElement | null => {
  if (!ref) return null

  if (ref.classList.contains(parentClass)) return ref

  return getParentWithClass(ref.parentElement, parentClass)
}

export const getChildWithClass = (ref: Nullable<Element>, childClass: string): Nullable<Element> => {
  if (!ref) return null
  if (ref.classList.contains(childClass)) return ref

  let foundNode: Nullable<Element> = null
  Array.from(ref.children).some(child => {
    const node = getChildWithClass(child, childClass)
    if (node) foundNode = node
    return !!node
  })
  return foundNode
}

export const getStyleForTooltipField = (tableTop: number, tableBottom: number, tooltipTop: number, tooltipBottom: number, pagination?: boolean): CSSProperties => {
  let style = {}
  const paginationOffset = pagination ? -48 : 0 // 48px - pagination block height

  if ((tableTop - tooltipTop) > 0 && (tooltipBottom - tableBottom) > 0) {
    const tooltipHeight = tableBottom - tableTop
    const centerTop = (tooltipBottom - tooltipTop) / 2 + tooltipTop
    const tooltipOffset = tooltipHeight / 2 + tableTop - centerTop + 4

    style = {
      '--tooltip-height': `${tooltipHeight}px`,
      '--tooltip-offset': `${tooltipOffset}px`,
      '--overflow-y': `scroll`
    }
  } else if ((tableTop - tooltipTop) > 0) {
    /* 24px - container outer shadow height */
    style = {
      '--tooltip-offset': `${-(tooltipTop - tableTop - 24)}px`
    }
  } else if ((tooltipBottom - tableBottom) > paginationOffset) {
    /* 60px = 24px (container outer shadow height) + 32px - two paddings of 16px + 4px - extra indent */
    style = {
      '--tooltip-offset': `${-(tooltipBottom - tableBottom + 60 + paginationOffset)}px`
    }
  }

  return style
}

export const changeOrg = (org: IUserOrganization, history: History.History, path?: string): void => {
  const { user } = store.getState()
  const dispatch = store.dispatch

  const { oguid, alias } = org
  const organizationIdentifier = alias || oguid

  const updatedUserSettings: IUserSettings = {
    ...user.settings,
    [UserSettingsKey.ROOT_MODE]: false
  }

  const newPath = createUrl(organizationIdentifier, path ?? Section.DOCUMENTS_ALL)

  Promise.resolve(dispatch(updateUserSettings(updatedUserSettings))).then(() => {
    dispatch(resetStoreAfterOrganizationChange())
    dispatch(changeOrganization(org))

    dispatch(toggleRootMode(false))
    dispatch(setLoaded(false))

    history.push(newPath)
  })
  .catch(displayErrorNotification)
}

export const toggleRootModeInUserSettings = (isRootMode: boolean) => {
  const { user } = store.getState()
  const { settings: userSettings } = user
  const dispatch = store.dispatch

  const updatedUserSettings: IUserSettings = {
    ...userSettings,
    [UserSettingsKey.ROOT_MODE]: isRootMode
  }

  dispatch(updateUserSettings(updatedUserSettings))
  dispatch(toggleRootMode(isRootMode))
}
export const areAllFieldsChecked = (fields: Record<string, any>): boolean => (
  Object.keys(fields).every((field: string) => fields[field].isChecked)
)

export const areAllFieldsUnchecked = (fields: Record<string, any>): boolean => (
  Object.keys(fields).every((field: string) => !fields[field].isChecked)
)

export const getFilename = (file: IFile): string => {
  const { bookType, name } = file
  return `${name}.${bookType}`
}

export const getTextAlignClass = (textAlign?: Nullable<TextAlign>): string => {
  const textAlignClasses = {
    [TextAlign.LEFT]: 'text-left',
    [TextAlign.CENTER]: 'text-center',
    [TextAlign.RIGHT]: 'text-right'
  }
  return textAlign ? textAlignClasses[textAlign] : ''
}

export const getBooleanValueLabel = (value: string): string => {
  const { language, getResource } = i18n

  if (value === BooleanValue.TRUE) return String(getResource(language, 'common', 'yes'))
  else if (value === BooleanValue.FALSE) return String(getResource(language, 'common', 'no'))
  else return ''
}

export const formatTableFieldValueToString = (value: DocumentDetailsField, type: DataType, t: TFunction, isCurrency = false): string => {
  if (type === FieldType.BOOLEAN)
    return typeof value === 'boolean' ? t(BooleanOptions[value?.toString()]) : ''

  else if (type === FieldType.DIRECTION)
    return Object.keys(TableFieldsDirectionsLabels).includes(value?.toString()) ? t(TableFieldsDirectionsLabels[value.toString()]) : ''

  else if (type === FieldType.DATE || type === FieldType.DATETIME) {
    return value ? getDateToString(value.toString(), type === FieldType.DATETIME) : ''
  }

  else if (type === FieldType.STRING && isCurrency) {
    return Object.keys(CurrencyTranslations).includes(value?.toString()) ? t(CurrencyTranslations[value.toString()]) : ''
  }

  else return value?.toString() ?? ''
}

export const formatTableFieldValueFromString = (
  values: IDocumentDetailsField[],
  t: TFunction,
  tableFields?: ITableField[]
): IDocumentDetailsField[] => {
  values.forEach(row => {
    Object.keys(row).forEach(key => {
      const value = row[key]
      if (value === '' || value === null) delete row[key]

      else {
        const type = tableFields?.find(field => field.key === key)?.type

        if (type === FieldType.NUMBER) row[key] = +value

        else if (type === FieldType.BOOLEAN) {
          if (value === t(BooleanOptions.true)) row[key] = true
          else if (value === t(BooleanOptions.false)) row[key] = false
          else delete row[key]
        }

        else if (type === FieldType.DIRECTION) {
          if (value === t(TableFieldsDirectionsLabels.INCOMING)) row[key] = DirectionValue.INCOMING
          else if (value === t(TableFieldsDirectionsLabels.OUTGOING)) row[key] = DirectionValue.OUTGOING
          else delete row[key]
        }

        else if (type === FieldType.DATE || type === FieldType.DATETIME) {
          const valueStr = value.toString()
          row[key] = +getDateFromString(valueStr, type === FieldType.DATETIME)
        }

        else if (type === FieldType.STRING && key === FieldName.CURRENCY) {
          const newValue = Object.keys(CurrencyTranslations).find(key => t(CurrencyTranslations[key]) === value)
          if (newValue) row[key] =  newValue
          else delete row[key]
        }
      }
    })
  })

  return values
}

export const getDateFromString = (value: string, isDateTime = false, isNoOffset = false): Date => {
  const dateFormat = isDateTime ? DateFormat.DATETIME_FULL_YEAR_DATEPICKER : DateFormat.DATE_FULL_YEAR_DATEPICKER
  const dateValue = value.split(', ')[0]

  const isDate = value.split(', ').length === 1
  const datetime = isDate ? DateTime.fromFormat(dateValue, DateFormat.DATE_FULL_YEAR_DATEPICKER) : DateTime.fromFormat(isDateTime ? value : dateValue, dateFormat)
  const formattedDatetime = isNoOffset ? datetime : datetime.plus({ minutes: getUTCOffset(datetime) })

  return formattedDatetime.toJSDate()
}

export const getDateToString = (value: string | Date, isDateTime = false, isNoOffset = false): string => {
  const datetime = typeof value === 'string' ? DateTime.fromJSDate(new Date(+value)) : DateTime.fromJSDate(value)
  const dateFormat = isDateTime ? DateFormat.DATETIME_FULL_YEAR_DATEPICKER : DateFormat.DATE_FULL_YEAR_DATEPICKER

  const formattedValue = isNoOffset ? datetime : datetime.minus({ minutes: getUTCOffset(datetime) })

  return formattedValue.toFormat(dateFormat)
}

export const getFormattedTypesFields = (payload: IDocumentTypesFromServer | IUserDocumentTypesFromServer) => {
  return Object.entries(payload).reduce((acc,[typeKey, typeValue]) => {
    const { fields } = typeValue

    if (!Array.isArray(fields)) return { ...acc, [typeKey]: { ...typeValue } }

    const formattedFields = fields.reduce((acc, field, index) => {
      return { ...acc, [field.key]: { ...field, index } }
    }, {})

    return { ...acc, [typeKey]: { ...typeValue, fields: {...formattedFields } }}
  }, {})
}


export const getConvertedFileFieldValue = (isArray: boolean, value: Nullable<IDocumentField>): Nullable<IDocumentField> => {
  if (isArray && !Array.isArray(value)) {
    return [value].filter(Boolean) as IFileFieldValue[]
  }

  if (!isArray && Array.isArray(value)) {
    return value[0] ?? null
  }

  return value
}

export const getAllDocumentTypeFields = (fields: IField[], tableFields?: ITableField[]) => {
  const formattedFields = fields.map(({ key, type }) => ({ key, type: type.toString() }))
  const formattedFieldsKeys = objKeysFromArray(formattedFields)
  const formattedTableFields = tableFields?.filter(({ key }) => !formattedFieldsKeys[key]).map(({ key, type }) => ({ key, type: type.toString() })) ?? []

  return [...formattedFields, ...formattedTableFields]
}

export const getTableFieldByKey = (fields: ITableField[], key: string): Nullable<ITableField> =>
  fields.find(field => field.key === key) ?? null

export const getDictTypeByKey = (fields: ITableField[], key: string): Nullable<string> =>
  getTableFieldByKey(fields, key)?.dictType ?? null

export const checkIsFiltersApplied = (search: string): boolean => (
  !!Object.keys(omit(queryString.parse(search), ['page', 'perPage'])).length
)

export const objKeysFromArray = (arr: Array<any>) => arr.reduce((acc, n) => (acc[n.key] = true, acc), {})

export const isFileFieldValue = (candidate: any): candidate is IFileFieldValue | IFileFieldValue[] => (
  Array.isArray(candidate) || 'allowedActions' in (candidate ?? {})
)

export const filterFileFields = (fields: Record<string, Nullable<IDocumentField>>): Record<string, Nullable<IDocumentField>> => (
  Object.keys(fields).reduce((acc, key) => {
    const field = getField(key)
    const fieldValue = fields[key]

    return field?.type === FieldType.FILE
      ? acc
      : {
        ...acc,
        [key]: fieldValue
      }
  }, {})
)

const getFileFieldForServer = (value: fieldValue): string | Array<string | null> | null => {
  if (!isFileFieldValue(value)) return null

  return Array.isArray(value)
    ? value.map(({fileOguid}) => fileOguid ?? null)
    : value.fileOguid ?? null
}

export const prepareFileFieldsForServer = (fields: Record<string, fieldValue>, remove?: boolean): Record<string, fieldValue> => (
  Object.keys(fields).reduce((acc, key) => {
    const field = getField(key)
    if (!field) return acc

    const { type } = field

    if (type === FieldType.FILE && remove) return acc

    const value = fields[key]

    const newValue = type === FieldType.FILE
      ? getFileFieldForServer(value)
      : value

    return {
      ...acc,
      [key]: newValue
    }
  }, {})
)

export const getNextDay = (timestamp: number): number => (
  DateTime.fromMillis(timestamp).plus({days: 1}).toMillis()
)

export const getPrevDay = (timestamp: number): number => (
  DateTime.fromMillis(timestamp).minus({days: 1}).toMillis()
)

export const getCertificatePowerOfAttorneysNumbers = (power: ISelectedCertificatePower | undefined | null): string => {
  if (!power) return ''

  const {
    powerOfAttorneys,
    powerOfAttorneysIdList
  } = power

  return powerOfAttorneysIdList.map((id) => {
    const {
      attorneyNumber,
      additionalInfo
    } = powerOfAttorneys[id]

    return attorneyNumber ?? additionalInfo
  }).join(', ')
}

export const getFieldsDivSub = (fieldsArray: string[]): IFieldsDivSub => {
  let firstFieldKey : Nullable<string> = null;
  const filteredArray = [...fieldsArray];
  for (let i = 0; i < fieldsArray.length-1; i++) {
    for (let j = 1; j < fieldsArray.length; j++) {
      if (fieldsArray[i] === fieldsArray[j]) {
        firstFieldKey = fieldsArray[i];
        break;
      }
    }
    if (firstFieldKey) {
      break;
    }
  }
  return {
    firstField: firstFieldKey,
    restFields: filteredArray.filter((val) => val !== firstFieldKey)
  }
}

/** Получение опций для Select */
export const getSelectOptions = (data: any[]): ILibOption[] => {
  return [
    ...data.map((val) => ({
      label: val,
      value: val,
    })),
  ];
};

/** Получение расширения файла */
export const getFileExtension = (fileName: string): string => {
  const fileNameArr = fileName.split('.');

  return fileNameArr.length > 1
    ? fileNameArr[fileNameArr.length - 1].toLowerCase()
    : '';
};

/** Открытие превью файла в картинках */
export const getFilePicsPreview = (
  fileName: string,
  disable?: boolean
): boolean => {
  if (disable) {
    return false
  }

  const fileExtension = getFileExtension(fileName);

  return !PDF_PREVIEW_EXTENSIONS.includes(fileExtension);
};
