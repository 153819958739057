import { TFunction } from 'i18next'
import {
  IDocumentTypeDependentField,
  IDocumentTypeField,
  IDocumentTypeFields,
  ITableField
} from '@store/modules/metadata/types'
import { Nullable } from '@store/types/commonTypes'
import { IDocumentFieldsDependencies, IDocumentValuesFields } from '@store/modules/documents/types'
import { DependencyType, FieldType, FIXED_POSITION_FIELDS, MathOperationType, Space } from '@const/consts'
import { ISourceFieldsCalculationParams } from '@utils/types'
import { getFieldsDivSub, INameInterface } from './utils'
import { ITableHandbooks } from '@app/views/organization/documents/components/Document/components/TableFields/types'
import { getSurnameWithInitials } from '@infologistics/frontend-libraries'

export const getDocumentCountHint = (
  t: TFunction,
  packageDocumentCount = 0,
  relatedDocumentCount = 0
): string =>
  t('documents:countFilesHint', {
    packageDocumentCount,
    relatedDocumentCount
  })

export const getFieldsDependencies = (
  fields: IDocumentTypeFields,
  dependentFields: Nullable<IDocumentTypeDependentField[]>,
  tableFields?: ITableField[]
): IDocumentFieldsDependencies => {
  let calculable = {}
  return filterFieldsDependencies(fields, dependentFields, tableFields)?.reduce((acc, field) => {
    // dependent fields
    if (field.dependencyType === DependencyType.DICT_LOOKUP) {
      // if influencing field is a handbook - the key is its dictType
      // otherwise the key is the name of the influencing field
     const tableFieldWithKey = tableFields?.find(tableField => tableField.key === field.fieldKey)
      const key: string = fields[field?.sourceFieldKeys[0]]?.dictType ??  tableFields?.find(tableField => tableField.key === field?.sourceFieldKeys[0])?.dictType ?? field?.sourceFieldKeys[0]
      if (key in (acc?.dependent ?? {})) {
        return { ...acc, dependent: { ...acc.dependent, [key]: [...acc.dependent?.[key] ?? [], fields[field?.fieldKey]?.dictType ?? tableFieldWithKey?.dictType] } }
      }

      return { ...acc, dependent: { ...acc.dependent, [key]: [fields[field?.fieldKey]?.dictType ?? tableFieldWithKey?.dictType] } }
    }

    // calculable fields
    if (field.dependencyType === DependencyType.MATH_OPERATION) {
      const keys: string[] = field.sourceFieldKeys ?? []

      keys?.forEach(key => {
        if (key in calculable) {
          calculable = { ...calculable, [key]: [...calculable?.[key] ?? [], field.fieldKey] }
          return
        }
        calculable = { ...calculable, [key]: [field.fieldKey] }
      })
    }

    return { ...acc, calculable }
  }, { dependent: {}, calculable: {} }) ?? {}
}

export const filterFieldsDependencies = (
  fields: IDocumentTypeFields,
  dependentFields: Nullable<IDocumentTypeDependentField[]>,
  tableFields?: ITableField[]
): Nullable<IDocumentTypeDependentField[]> => {
  if (!dependentFields) return null

  return dependentFields.filter(dependentField => !getIsFieldBroken(Object.keys(fields), dependentField, tableFields))
}

export const getIsFieldBroken = (
  fieldsKeys: string[],
  dependentField: IDocumentTypeDependentField,
  tableFields?: ITableField[]
) => {
  const { fieldKey, sourceFieldKeys } = dependentField

  const tableFieldsKeys = tableFields?.map(field => field.key) ?? []

  // The field removed from type
  const isDeleted = ![...fieldsKeys, ...tableFieldsKeys].includes(fieldKey)

  // The field is in both the list of main fields and the list of table fields
  const isMainField = fieldsKeys.includes(fieldKey)
  const isInTwoStructure = !!(isMainField && tableFields?.find(tableField => tableField.key === fieldKey))
  const areBothTypes = isMainField && sourceFieldKeys.some(key => !fieldsKeys.includes(key))

  // The field is the same field type as its influencing/dependent field
  let isSameType = false
  let isBothSourceDict = false
  if (tableFields?.find(field => field.key === fieldKey)) {
    sourceFieldKeys.forEach(sourceFieldKey => {
      const foundField = tableFields.find(field => field.key === sourceFieldKey)
      const foundMainField = fieldsKeys.includes(sourceFieldKey)
      if (!foundField && (tableFields.find(field => field.key === fieldKey)?.type !== FieldType.DICTIONARY)) isSameType = true
      if (foundField && foundMainField && (tableFields.find(field => field.key === sourceFieldKey)?.type === FieldType.DICTIONARY)) isBothSourceDict = true
    })
  }

  return isDeleted || isInTwoStructure || areBothTypes || isSameType || isBothSourceDict
}

const getSourceFieldValue = (key: string, fields: IDocumentValuesFields, handbooks: ITableHandbooks | undefined, tableFields: ITableField[] | undefined) => {
  const dictType = tableFields?.find((field) => field.key === key)?.dictType

  if (dictType) {
    const dictValue = handbooks?.[dictType ?? ''].find(pair => pair.key === fields[key])?.value
    return Number(dictValue?.toString().replace(',', '.'))
  }

  const fieldValue = fields[key]

  return fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue
    ? Number(fieldValue.value.toString().replace(',', '.'))
    : Number(fieldValue?.toString().replace(',', '.'))
}

export const getSourceFieldsCalculation = ({
  sourceFieldKeys,
  mathOperationType,
  fields,
  handbooks,
  tableFields
}: ISourceFieldsCalculationParams): number => {
  let result = 0

  const sourceFieldValues : number[] = sourceFieldKeys.map((key) => {
    return getSourceFieldValue(key, fields, handbooks, tableFields)
  })

  switch (mathOperationType) {
    case MathOperationType.MULTIPLY:
      result = sourceFieldValues.reduce((acc, val) => acc * val);
      break
    case MathOperationType.ADD:
      result = sourceFieldValues.reduce((acc, val) => acc + val);
      break
    case MathOperationType.DIVIDE: {
      const firstFieldArray = getFieldsDivSub(sourceFieldKeys);

      result = firstFieldArray.restFields.reduce(
        (acc, val) =>
          acc / getSourceFieldValue(val, fields, handbooks, tableFields),
        getSourceFieldValue(
          firstFieldArray.firstField ?? '',
          fields,
          handbooks,
          tableFields
        )
      );
      break;
    }
    case MathOperationType.SUBSTRACTION: {
      const firstFieldArray = getFieldsDivSub(sourceFieldKeys);

      result = firstFieldArray.restFields.reduce(
        (acc, val) =>
          acc - getSourceFieldValue(val, fields, handbooks, tableFields),
        getSourceFieldValue(
          firstFieldArray.firstField ?? '',
          fields,
          handbooks,
          tableFields
        )
      );
      break;
    }
  }

  return result
}

/** Получение массива полей без фиксированной позиции с сортировкой по index  */
export const getUnfixedDocFields = (fields: IDocumentTypeFields) => {
  const fieldsArr: IDocumentTypeField[] = [];

  Object.entries(fields).forEach(([key, value]) => {
    if (!FIXED_POSITION_FIELDS.includes(key)) {
      fieldsArr.push(value);
    }
  });

  fieldsArr.sort((val1, val2) => val1.index - val2.index);

  return fieldsArr;
};

export const getIsBotName = (user: INameInterface) => {
  const { name, surname, patronymic } = user;
  return surname === 'System' && name === 'bot' && !patronymic;
};

/** Функция вывода ФИО с учётом вывода полного имени для бота */
export const getSurnameWithInitialsBot = (user: INameInterface) => {
  const { name, surname, patronymic, language } = user;

  return getIsBotName(user)
    ? surname + Space.STANDART + name
    : getSurnameWithInitials({ name, patronymic, surname, language });
};
