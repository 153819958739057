import { Nullable } from '@store/types/commonTypes'

export enum DocumentsDownloadJobState {
  FAILED = 'failed',
  FINISHED = 'finished',
  PENDING = 'pending'
}

export enum DocumentsDownloadType {
  ONLY_DOCUMENT_FILE = "ONLY_DOCUMENT_FILE",
  FULL_WORKFLOW = "FULL_WORKFLOW"
}

export enum DocumentFilenameType {
  ORIGINAL = "ORIGINAL",
  DOCUMENT_NAME = "DOCUMENT_NAME"
}

export interface IDocumentsDownloadModalProps {
  onCloseModal: () => void
  selectedDocumentsOguids: Nullable<string[]>
}

export interface IDocumentsFileSharingProps {
  orgOguid?: Nullable<string>
  jobOguid?: Nullable<string>
}

export interface IDocumentsDownloadRequest {
  documentOguids: Nullable<string[]>,
  download: DocumentsDownloadType,
  filename?: DocumentFilenameType,
  isDownloadPrintForm?: boolean
}
