import cn from 'classnames'
import React, { FC, ReactElement, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
  DropdownList,
  Ellipsis,
  ILibDropdownItem,
  ILibHorizontalPosition,
  IconCog,
  IconSignOut,
  IconUser,
  Popover
} from '@infologistics/frontend-libraries'

import { BrowserStorage, RouteName, SSO_URL, UserMenuItem } from '@const/consts'
import { createUrl } from '@utils/utils'
import { getSurnameWithInitialsBot } from '@app/utils/documentsUtils'
import { logout } from '@store/modules/root/actions'
import { actions as loaderActions } from '@store/modules/loader/actions'
import { IApplicationState } from '@store/types/commonTypes'
import { IUserMenuProps as IProps } from './types'
import urls from '@const/urls'

import styles from './UserMenu.module.css'
import tokensService from '@app/services/tokensService'

const UserMenu: FC<IProps> = ({ history, match }: IProps) => {
  const { t } = useTranslation(['common', 'profile'])

  const { profile, federation } = useSelector((state: IApplicationState) => state.user)
  const dispatch = useDispatch<any>()

  const [popoverOpened, setPopoverOpened] = useState<boolean>(false)

  const closePopover = (isOpenFromPopover: boolean): void => {
    !isOpenFromPopover && setPopoverOpened(false)
  }

  const togglePopover = (): void => setPopoverOpened(!popoverOpened)

  const getPopoverBtn = (): ReactElement => {
    return (
      <div onClick={togglePopover} className={cn(styles.btn_wrapper, 'd-flex align-items-center')}>
        <IconUser classes={cn(styles.btn_icon, styles.icon_margin)}/>
        <Ellipsis>{getSurnameWithInitialsBot(profile)}</Ellipsis>
      </div>
    )
  }

  const federationToken = tokensService.getTokenFromCookies(BrowserStorage.TOKEN_FEDERATION, profile.oguid)

  const ssoUrl = `${SSO_URL}${urls.users.SSOLogout}`
  const ssoUser = !!federation || (federationToken && federationToken!=='')

  const getDropdownItems = (): ILibDropdownItem[] => {
    return [
      {
        name: UserMenuItem.PROFILE,
        element: (
          <span className='d-flex align-items-center text-nowrap p-2'>
            <IconCog classes={cn(styles.icon_margin, 'flex-shrink-0')}/>
            {t('profile:profileSettings')}
          </span>
        )
      },
      {
        name: UserMenuItem.LOGOUT,
        element: (
          <form
            action={ssoUser ? ssoUrl : undefined}
            method={ssoUser ? 'post' : undefined}
            onSubmit={e => handleClick(e)}
          >
            <button
              className={cn(
                'd-flex align-items-center text-nowrap p-2',
                styles.btn_logout
              )}
              type='submit'
            >
              <IconSignOut classes={cn(styles.icon_margin, 'flex-shrink-0')} />
              {t('common:signout')}
            </button>
          </form>
        )
      }
    ]
  }

  const handleSelect = ({ name }: ILibDropdownItem): void => {
    if (name !== UserMenuItem.LOGOUT) {
      const url = name === UserMenuItem.PROFILE
        ? createUrl(match, RouteName.PROFILE)
        : RouteName.SIGN_IN

        history.push(url)
        setPopoverOpened(false)
    }
  }

  const handleClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const url = RouteName.SIGN_IN

    dispatch(logout())

    history.push(url)
    setPopoverOpened(false)

    if (ssoUser) {
      dispatch(loaderActions.showLoader)
      e.currentTarget.submit();
    }
  }

  return (
    <Popover
      buttonComponent={getPopoverBtn()}
      containerClasses={styles.popover_container}
      popoverClasses={styles.popover}
      withoutArrow
      allowDropdownFlip
      horizontalPosition={ILibHorizontalPosition.RIGHT}
      parentManagement={{
        isOpenFromParent: popoverOpened,
        callbackForClose: closePopover
      }}
    >
      <DropdownList
        dropdownItems={getDropdownItems()}
        onSelect={handleSelect}
      />
    </Popover>
  )
}

export default withRouter(UserMenu)
