import cn from 'classnames'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { Portal } from '@common/Portal'
import { cutSize, needResetParams } from '@const/consts'
import {
  Background,
  Button,
  IconAngleLeft,
  IconAngleRight,
  IconTimes,
  Spinner
} from '@infologistics/frontend-libraries'
import { displayErrorNotification } from '@utils/utils'

import http from '@services/http'

import { getDraftDocumentMessagePreview, getPreviewFile } from '@store/modules/documents/actions'

import { IApplicationState } from '@store/types/commonTypes'

import styles from './Preview.module.css'
import File from '../../../Document/components/File/File'
import { GetPreviewFileMode, IDocumentFiles, IGetPreviewFile } from '@app/store/modules/documents/types'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { closePreview } from '@app/store/modules/filePreview/actions'

export const Preview: FC = () => {

  const [isLoading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [requestId, setRequestId] = useState<string>()
  const [updatePage, setUpdatePage] = useState(false)
  const [repData, setRepData] = useState<IDocumentFiles>({
    data: [],
    isAvailable: true,
    total: 0,
  })

  const {
    documentId,
    isMessage,
    replaceDocumentOguid,
    picsMode,
    isAttachment,
    docFile,
  } = useSelector((state: IApplicationState) => state.filePreview)

  const draftDocuments = useSelector((state: IApplicationState) => state.documents.draftDocuments);
  const draftMessages = useSelector((state: IApplicationState) =>  state.documents.draftMessages);

  const dispatch = useDispatch<any>()

  const { t } = useTranslation();

  useEffect(
    () => () => {
      requestId && http.removeAxiosEvent(requestId)
    },
    []
  )

  const draftData = isMessage
    ? (documentId in draftMessages.current ? draftMessages.current[documentId] : null)
    : (documentId in draftDocuments.current ? draftDocuments.current[documentId] : null);

  const previewFilesDraft = draftData
    ? draftData.previewFiles
    : {
        data: [],
        isAvailable: false,
        total: 0,
      };

  const previewFiles = (replaceDocumentOguid || !picsMode) ? repData : previewFilesDraft;

  const { data, isAvailable, total } = previewFiles;

  const isNextPageButtonDisabled = currentPage === total || total <= 1
  const isPrevPageButtonDisabled = currentPage === 1

  const handleClose = () => {
    dispatch(closePreview());
  }

  const loadPage = (): void => {
    if (total && total === data.length) return

    const newRequestId = uuidv4()

    setLoading(true)
    setRequestId(newRequestId)

    if (!replaceDocumentOguid) {
      dispatch(getDraftDocumentMessagePreview(documentId, newRequestId, isMessage, needResetParams))
        .then(() => {
          setLoading(false)
        })
        .catch((err: any) => {
          if (!err.response) return

          displayErrorNotification(err)

          handleClose()
        })
    } else {
      try {
        getPreviewFile(replaceDocumentOguid, String(currentPage),  GetPreviewFileMode.SHELF, undefined, { params: {...needResetParams } })()
          .then(({ fileCode, mimeType, orientation, totalPages }: IGetPreviewFile) => {
            setLoading(false)
            setRepData({
              data: [
                ...repData.data,
                {
                  fileCode: fileCode ?? "",
                  mimeType,
                  orientation
                }
              ],
              total: totalPages,
              isAvailable: true
            })
          })
      } catch (err) {
        displayErrorNotification(err)

        handleClose()
      }
    }
  }

  const renderPlaceholder = (): ReactNode => (
    <div className={styles.portrait}>
      <div className={cn(styles.placeholder, !isAvailable && styles.missing)}>
        <div className={cn(styles.placeholder_content, 'absolute atl-0 d-flex flex-column align-items-center')}>
          {isAvailable && (
            <div className={styles.placeholder_spinner}>
              <Spinner />
            </div>
          )}
          <h3 className={cn(styles.placeholder_text, 'text-muted mt-5')}>
            {isAvailable ? t('document:preview.loading') : t('document:preview.missing')}
          </h3>
        </div>
      </div>
    </div>
  )

  const renderPage = (): ReactNode => {
    const pageData = data[currentPage - 1]

    if (!pageData) {
      isAvailable && !isLoading && loadPage()

      return renderPlaceholder()
    }

    const { fileCode, mimeType, orientation } = pageData
    const header = `data:${mimeType};base64`
    const src = `${header}, ${fileCode}`

    const classes = cn(styles[orientation.toLocaleLowerCase()], 'relative')

    return (
      <div className={classes} key={fileCode.slice(cutSize)}>
        <img className={styles.image} src={src} alt='' />
      </div>
    )
  }

  const handleNextPageButtonClick = (): void => {
    if (isNextPageButtonDisabled) return

    setCurrentPage(currentPage + 1)
    setUpdatePage(true);
  }

  const handlePrevPageButtonClick = (): void => {
    if (isPrevPageButtonDisabled) return

    setCurrentPage(currentPage - 1)
    setUpdatePage(true);
  }

  const handleExtSetTotalPages = (page: number): void => {
    setRepData({
      ...repData,
      total: page
    })
  }

  return (
    <Portal>
      <Background backdrop={false} externalClass={styles.background} keyboard={false} onClose={handleClose}>
        <Button classes={cn('fixed atr-4', styles.button)} onClick={handleClose} theme='text'>
          <IconTimes classes={styles.button_icon} size='sm' withoutFill />
        </Button>
        <div className={cn('d-flex flex-column', {[`${styles.image_wrap} align-items-center`] : picsMode})}>
          {picsMode ? (
            renderPage()
          ) : (
            <File
              key={documentId}
              replaceFileOguid={replaceDocumentOguid}
              draftDocumentId={documentId}
              isMessage={isMessage}
              hideBg
              hideSharingBtn
              hidePreview
              extPageControl={{
                currentPage: currentPage,
                setCurrentPage: setCurrentPage,
                totalPages: repData.total,
                setTotalPages: handleExtSetTotalPages,
                updatePage: updatePage,
                setUpdatePage: setUpdatePage,
              }}
              closePreview={handleClose}
              isAttachment={isAttachment}
              docFile={docFile}
              disableAdditionalHeight
              classButtons={cn(styles.file_buttons, 'absolute ar-4')}
            />
          )}
        </div>
        <div className={cn('d-flex justify-content-center align-items-center fixed abl-0 full-width', styles.controls)}>
          <Button
            classes={cn(styles.button, {
              [styles.button_disabled]: isPrevPageButtonDisabled
            })}
            isDisabled={isPrevPageButtonDisabled}
            theme='text'
            onClick={handlePrevPageButtonClick}
          >
            <IconAngleLeft classes={styles.button_icon} size='sm' withoutFill />
          </Button>
          <p className='mb-0 mx-4 text-light'>
            {t('common:page')}
            <span className='mx-4'>{currentPage}</span>
            {t('common:of')}
            <span className='ml-4'>{total || 1}</span>
          </p>
          <Button
            classes={cn(styles.button, {
              [styles.button_disabled]: isNextPageButtonDisabled
            })}
            isDisabled={isNextPageButtonDisabled}
            theme='text'
            onClick={handleNextPageButtonClick}
          >
            <IconAngleRight classes={styles.button_icon} size='sm' withoutFill />
          </Button>
        </div>
      </Background>
    </Portal>
  )
}
