import {
  IApplicationState,
  ModuleThunkDispatch,
  Nullable,
} from '@app/store/types/commonTypes';
import { AxiosResponse } from 'axios';
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

export enum EnumAppType {
  STATIC = 'STATIC',
  SERVER = 'SERVER',
}

/** Полный интерфейс приложений */
export interface IApplicationFull {
  /** Индетификатор */
  oguid: string;
  /** Название приложения */
  name: string;
  /** Идентификатор иконки приложения */
  icon: number;
  // Тип хостинга: STATIC - должен передаваться ZIP-файл; SERVER - должен передаваться Url
  appTypeTest: EnumAppType;
  /** TEST-URL приложения */
  urlTest: Nullable<string>;
  /** Статус актуальности прод-приложения */
  isTestUpdated: boolean;
  /** Статус размещения приложения на проде */
  isDeployed?: boolean;
  // Тип хостинга: STATIC - должен передаваться ZIP-файл; SERVER - должен передаваться Url
  appTypeProd: EnumAppType;
  /** PROD-URL приложения */
  urlProd: Nullable<string>;
}

/** Интерфейс с общими для приложений полями */
export interface IApplicationAncestor {
  /** Название приложения */
  name: string;
  /** Идентификатор иконки приложения */
  icon: number;
  /** URL приложения */
  url: Nullable<string>;
}

/** Интерфейс приложений для отображения */
export interface IApplication extends IApplicationAncestor {
  /** Индетификатор */
  oguid: string;
}

/** Интерфейс приложений для создания */
export interface IApplicationCreate extends IApplicationAncestor {
  // Тип хостинга: STATIC - должен передаваться ZIP-файл; SERVER - должен передаваться Url
  appType: EnumAppType;
}

/** Интерфейс приложений для полного обновления */
export interface IApplicationEdit extends IApplicationCreate {
  /** Архив */
  zip: Nullable<File>;
}

/** Интерфейс приложений для обновления названия/иконки */
export type IApplicationPatch = Omit<IApplicationAncestor, 'url'>;

export enum ApplicationsActionTypes {
  SET_APPLICATIONS = 'SET_APPLICATIONS',
  ADD_APPLICATION = 'ADD_APPLICATION',
}

export interface IActionSetApplications extends Action<string> {
  payload: IApplication[];
  type: ApplicationsActionTypes.SET_APPLICATIONS;
}

export type Actions = IActionSetApplications;

type ActionKeys = 'setApplications';

export type ApplicationsActions = {
  [key in ActionKeys]: ActionCreator<Action<string>>;
};

export type ApplicationsThunkAction = ThunkAction<
  Promise<AxiosResponse>,
  IApplicationState,
  void,
  Action<string>
>;

export type ApplicationsThunkDispatch = ModuleThunkDispatch<IApplicationState>;
