import React, { ReactElement } from 'react'

import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

import { LinkContent } from '@infologistics/frontend-libraries'

import { createPath, getBadgeValue, getOrganizationInfo, transformPathToString } from '@utils/utils'

import { ASIDE_WIDTH_INIT } from '@const/consts'

import { INavMenuItemProps as IProps } from './types'

const REGEX_APP = /(?<=applications\/).+/g;

export class NavMenuItem extends React.Component<IProps> {
  // public path = ''
  // public toPath = ''

  public componentDidMount(): void {
    // this.setRoute()
  }

  public componentDidUpdate(): void {
    // this.setRoute()
  }

  public render(): ReactElement {
    const {
      activeMenu,
      alias,
      item,
      location,
      oguid,
      t,
      isDisabled,
      isRootMode
    } = this.props
    const { items, title, route } = item

    const organization = getOrganizationInfo(alias, oguid)

    const newTo: string = transformPathToString(route)

    const activeMenuSource = activeMenu || location.pathname

    const newPath = items?.[0]?.route ?? route

    const toPath = isRootMode ? newPath : createPath(organization, newPath)

    // console.log({route, toPath})

    const getActive = () => {
      const appName = activeMenuSource.match(REGEX_APP);
      const appNameRoute = newTo.match(REGEX_APP);
      if (appName && appNameRoute) {
        return appName[0] === appNameRoute[0];
      }
      return activeMenuSource.includes(newTo);
    };

    return <NavLink
      isActive={() => getActive()}
      title={t(title)}
      tabIndex={isDisabled ? -1 : undefined}
      to={toPath}
    >
      {this.renderLinkContent()}
    </NavLink>
  }

  // private readonly setRoute = (): void => {
  //   const { items, route } = this.props.item

  //   const newPath = items?.[0]?.route ?? route

  //   const {
  //     alias,
  //     oguid,
  //     isRootMode
  //   } = this.props

  //   const organization = getOrganizationInfo(alias, oguid)

  //   this.path = newPath
  //   this.toPath = isRootMode ? newPath : createPath(organization, newPath)
  //   console.log({path: this.path, toPath: this.toPath})
  // }

  private readonly renderLinkContent = (): ReactElement => {
    const {
      asideWidth = ASIDE_WIDTH_INIT,
      allBadges,
      badges,
      isRootMode,
      t,
      item: { icon, title, badge },
      isHorizontalNavMode
    } = this.props

    const badgesQuantity = isRootMode ? allBadges : badges
    const badgeProps = badge && {
      theme: badge.theme ?? 'default',
      value: getBadgeValue(badgesQuantity[badge.name])
    }

    return <LinkContent
      title={t(title)}
      titleMaxWidth={`calc(${asideWidth} - 44px)`}
      iconBefore={icon}
      iconBeforeProps={{ classes: 'mr-2' }}
      badgeProps={badgeProps}
      isHorizontalNavMode={isHorizontalNavMode}
    />
  }

}

export default withTranslation()(withRouter(NavMenuItem))
