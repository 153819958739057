import { AxiosError, AxiosResponse } from 'axios';
import { Action, ActionCreator } from 'redux';

import applicationsService from '@services/applications';

import { ResponseCode } from '@const/consts';

import {
  ApplicationsActionTypes,
  ApplicationsActions,
  ApplicationsThunkDispatch,
  IActionSetApplications,
  IApplication,
} from './types';
import { AsyncThunk } from '@app/store/types/commonTypes';

const setApplicationsAction: ActionCreator<Action> = (
  data: IApplication[]
): IActionSetApplications => ({
  payload: data,
  type: ApplicationsActionTypes.SET_APPLICATIONS,
});

export const actions: ApplicationsActions = {
  setApplications: setApplicationsAction,
};

export const getApplicationsUser: AsyncThunk =
  () =>
  (dispatch: ApplicationsThunkDispatch): Promise<AxiosResponse> =>
    applicationsService
      .getUserList()
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setApplications(resp.data));
        }

        return resp;
      })
      .catch((error: AxiosError) => Promise.reject(error));
