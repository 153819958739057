import { PDFRenderingQueue } from 'pdfjs-dist/types/web/pdf_thumbnail_viewer'
import {
  PageViewport,
  RenderingCancelledException
} from 'pdfjs-dist/types/src/display/display_utils'
import { OptionalContentConfig } from 'pdfjs-dist/types/src/display/optional_content_config'
import { IL10n } from 'pdfjs-dist/types/web/annotation_editor_layer_builder'
import { Nullable } from '@store/types/commonTypes'
import { IContextType, IPDFThumbnailViewOptions } from '../types'
import { sendErrorToSentry } from '@utils/utils'
import { RenderingState } from '@views/organization/documents/components/Document/components/File/consts'
import { OutputScale } from './ui_utils'

const DRAW_UPSCALE_FACTOR = 2
const MAX_NUM_SCALING_STEPS = 3
const THUMBNAIL_CANVAS_BORDER_WIDTH = 0
const THUMBNAIL_WIDTH = 150

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class TempImageFactory {
  private static tempCanvas: Nullable<HTMLCanvasElement> = null

  static getCanvas(width: number, height: number): [HTMLCanvasElement, Nullable<CanvasRenderingContext2D>] {
    const tempCanvas = this.tempCanvas ||= document.createElement('canvas')
    tempCanvas.width = width
    tempCanvas.height = height
    const ctx = tempCanvas.getContext('2d', {
      alpha: false
    })
    if (ctx) {
      ctx.save()
      ctx.fillStyle = 'rgb(255, 255, 255)'
      ctx.fillRect(0, 0, width, height)
      ctx.restore()
    }
    return [tempCanvas, tempCanvas.getContext('2d')]
  }

  static destroyCanvas(): void {
    const tempCanvas = this.tempCanvas

    if (tempCanvas) {
      tempCanvas.width = 0
      tempCanvas.height = 0
    }

    this.tempCanvas = null
  }
}

export class PDFThumbnailView {
  id: number
  renderingId: string
  pageLabel: Nullable<string>
  pdfPage: any
  rotation: number
  viewport: PageViewport
  pdfPageRotate: number
  private readonly optionalContentConfigPromise: Nullable<Promise<OptionalContentConfig>>
  pageColors: Nullable<object>
  linkService: any
  renderingQueue: PDFRenderingQueue
  renderTask: any
  renderingState: number
  resume: Nullable<(() => void)>
  canvas: HTMLCanvasElement | undefined
  canvasWidth: number
  canvasHeight: number
  scale: number
  l10n: IL10n
  anchor: HTMLAnchorElement
  div: HTMLDivElement
  ring: HTMLDivElement
  image: HTMLImageElement | undefined

  constructor({
    container,
    id,
    defaultViewport,
    optionalContentConfigPromise,
    linkService,
    renderingQueue,
    l10n,
    pageColors
  }: IPDFThumbnailViewOptions) {
    this.id = id
    this.renderingId = `thumbnail${id}`
    this.pageLabel = null
    this.pdfPage = null
    this.rotation = 0
    this.viewport = defaultViewport
    this.pdfPageRotate = defaultViewport.rotation
    this.optionalContentConfigPromise = optionalContentConfigPromise ?? null
    this.pageColors = pageColors ?? null
    this.linkService = linkService
    this.renderingQueue = renderingQueue
    this.renderTask = null
    this.renderingState = RenderingState.INITIAL
    this.resume = null
    const pageWidth = this.viewport.width
    const pageHeight = this.viewport.height
    const pageRatio = pageWidth / pageHeight
    this.canvasWidth = THUMBNAIL_WIDTH
    this.canvasHeight = this.canvasWidth / pageRatio | 0
    this.scale = this.canvasWidth / pageWidth
    this.l10n = l10n
    const anchor = document.createElement('a')
    anchor.href = linkService.getAnchorUrl(`#page=${id}`)

    this.thumbPageTitle
      .then(msg => {
        anchor.title = msg
      })
      .catch(() => null)

    anchor.onclick = function () {
      linkService.goToPage(id)
      return false
    }

    this.anchor = anchor
    const div = document.createElement('div')
    div.className = 'thumbnail'
    div.setAttribute('data-page-number', `${this.id}`)
    this.div = div
    const ring = document.createElement('div')
    ring.className = 'thumbnailSelectionRing'
    const borderAdjustment = 2 * THUMBNAIL_CANVAS_BORDER_WIDTH
    ring.style.width = `${this.canvasWidth + borderAdjustment}px`
    ring.style.height = `${this.canvasHeight + borderAdjustment}px`
    this.ring = ring
    div.append(ring)
    anchor.append(div)
    container.append(anchor)
  }

  setPdfPage(pdfPage: any): void {
    this.pdfPage = pdfPage
    this.pdfPageRotate = pdfPage.rotate
    const totalRotation = (this.rotation + this.pdfPageRotate) % 360
    this.viewport = pdfPage.getViewport({
      scale: 1,
      rotation: totalRotation
    })
    this.reset()
  }

  reset(): void {
    this.cancelRendering()
    this.renderingState = RenderingState.INITIAL
    const pageWidth = this.viewport.width
    const pageHeight = this.viewport.height
    const pageRatio = pageWidth / pageHeight
    this.canvasHeight = this.canvasWidth / pageRatio | 0
    this.scale = this.canvasWidth / pageWidth
    this.div.removeAttribute('data-loaded')
    const ring = this.ring
    ring.textContent = ''
    const borderAdjustment = 2 * THUMBNAIL_CANVAS_BORDER_WIDTH
    ring.style.width = `${this.canvasWidth + borderAdjustment}px`
    ring.style.height = `${this.canvasHeight + borderAdjustment}px`

    if (this.canvas) {
      this.canvas.width = 0
      this.canvas.height = 0
      delete this.canvas
    }

    if (this.image) {
      this.image.removeAttribute('src')
      delete this.image
    }
  }

  update({
    rotation = null
  }: {
    rotation?: Nullable<number | undefined>
  }): void {
    if (typeof rotation === 'number') {
      this.rotation = rotation
    }

    const totalRotation = (this.rotation + this.pdfPageRotate) % 360
    this.viewport = this.viewport.clone({
      scale: 1,
      rotation: totalRotation
    })
    this.reset()
  }

  cancelRendering(): void {
    if (this.renderTask) {
      this.renderTask.cancel()
      this.renderTask = null
    }

    this.resume = null
  }

  private getPageDrawContext(upscaleFactor = 1): IContextType {
    const canvas = document.createElement('canvas')
    canvas.hidden = true
    const ctx = canvas.getContext('2d', {
      alpha: false
    })
    const outputScale = new OutputScale()
    canvas.width = upscaleFactor * this.canvasWidth * outputScale.sx | 0
    canvas.height = upscaleFactor * this.canvasHeight * outputScale.sy | 0
    const transform = outputScale.scaled ? [outputScale.sx, 0, 0, outputScale.sy, 0, 0] : null
    return {
      ctx,
      canvas,
      transform
    }
  }

  private convertCanvasToImage(canvas?: Nullable<HTMLCanvasElement>): void {
    if (this.renderingState !== RenderingState.FINISHED) {
      throw new Error('convertCanvasToImage: Rendering has not finished.')
    }

    const reducedCanvas = this.reduceImage(canvas)

    if (!reducedCanvas) return

    const image = document.createElement('img')
    image.className = 'thumbnailImage'

    this.thumbPageCanvas
      .then(msg => {
        image.setAttribute('aria-label', msg)
      })
      .catch(() => null)

    image.style.width = `${this.canvasWidth}px`
    image.style.height = `${this.canvasHeight}px`
    image.src = reducedCanvas.toDataURL()
    this.image = image
    this.div.setAttribute('data-loaded', `${true}`)
    this.anchor.setAttribute('data-loaded', `${true}`)
    this.ring.append(image)
    reducedCanvas.width = 0
    reducedCanvas.height = 0

    if (!canvas) return
    canvas.hidden = false
  }

  draw(): Promise<void> {
    if (this.renderingState !== RenderingState.INITIAL) {
      const sentryParams = {
        info: {},
        message: 'Must be in new state before drawing'
      }
      sendErrorToSentry(sentryParams)
      return Promise.resolve()
    }

    const {
      pdfPage
    } = this

    if (!pdfPage) {
      this.renderingState = RenderingState.FINISHED
      return Promise.reject(new Error('pdfPage is not loaded'))
    }

    this.renderingState = RenderingState.RUNNING
    const finishRenderTask = async (error: Nullable<RenderingCancelledException> = null): Promise<void> => {
      if (renderTask === this.renderTask) {
        this.renderTask = null
      }

      this.renderingState = RenderingState.FINISHED

      this.convertCanvasToImage(canvas)

      if (error) {
        // eslint-disable-next-line no-console
        console.debug(error)
      }
    }

    const {
      ctx,
      canvas,
      transform
    } = this.getPageDrawContext(DRAW_UPSCALE_FACTOR)

    const drawViewport = this.viewport.clone({
      scale: DRAW_UPSCALE_FACTOR * this.scale
    })

    const renderContinueCallback = (cont: () => void): void => {
      if (!this.renderingQueue.isHighestPriority(this)) {
        this.renderingState = RenderingState.PAUSED

        this.resume = () => {
          this.renderingState = RenderingState.RUNNING
          cont()
        }

        return
      }

      cont()
    }

    const renderContext = {
      canvasContext: ctx,
      transform,
      viewport: drawViewport,
      optionalContentConfigPromise: this.optionalContentConfigPromise,
      pageColors: this.pageColors
    }
    const renderTask = this.renderTask = pdfPage.render(renderContext)
    renderTask.onContinue = renderContinueCallback
    const resultPromise = renderTask.promise.then(function () {
      return finishRenderTask(null)
    }, function (error: Nullable<undefined>) {
      return finishRenderTask(error)
    })
    resultPromise.finally(() => {
      if (canvas) {
        canvas.width = 0
        canvas.height = 0
      }
      const pageCached = this.linkService.isPageCached(this.id)

      if (!pageCached) {
        this.pdfPage?.cleanup()
      }
    })
    return resultPromise
  }

  setImage(pageView: any): void {
    if (this.renderingState !== RenderingState.INITIAL) {
      return
    }

    const {
      thumbnailCanvas: canvas,
      pdfPage,
      scale
    } = pageView

    if (!canvas) {
      return
    }

    if (!this.pdfPage) {
      this.setPdfPage(pdfPage)
    }

    if (scale < this.scale) {
      return
    }

    this.renderingState = RenderingState.FINISHED

    this.convertCanvasToImage(canvas)
  }

  private reduceImage(img: Nullable<HTMLCanvasElement> | undefined): Nullable<HTMLCanvasElement | undefined> {
    const {
      ctx,
      canvas
    } = this.getPageDrawContext()

    if (img && canvas && ctx) {
      if (img.width <= 2 * canvas.width) {
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height)
        return canvas
      }

      let reducedWidth = canvas.width << MAX_NUM_SCALING_STEPS
      let reducedHeight = canvas.height << MAX_NUM_SCALING_STEPS
      const [reducedImage, reducedImageCtx] = TempImageFactory.getCanvas(reducedWidth, reducedHeight)

      while (reducedWidth > img.width || reducedHeight > img.height) {
        reducedWidth >>= 1
        reducedHeight >>= 1
      }

      reducedImageCtx?.drawImage(img, 0, 0, img.width, img.height, 0, 0, reducedWidth, reducedHeight)

      while (reducedWidth > 2 * canvas.width) {
        reducedImageCtx?.drawImage(reducedImage, 0, 0, reducedWidth, reducedHeight, 0, 0, reducedWidth >> 1, reducedHeight >> 1)
        reducedWidth >>= 1
        reducedHeight >>= 1
      }

      ctx.drawImage(reducedImage, 0, 0, reducedWidth, reducedHeight, 0, 0, canvas.width, canvas.height)
    }
    return canvas
  }

  private get thumbPageTitle(): Promise<string> {
    return this.l10n.get('thumb_page_title', {
      page: this.pageLabel ?? this.id
    })
  }

  private get thumbPageCanvas(): Promise<string> {
    return this.l10n.get('thumb_page_canvas', {
      page: this.pageLabel ?? this.id
    })
  }

  setPageLabel(label: Nullable<string>): void {
    this.pageLabel = typeof label === 'string' ? label : null

    this.thumbPageTitle
      .then(msg => {
        this.anchor.title = msg
      })
      .catch(() => null)

    if (this.renderingState !== RenderingState.FINISHED) {
      return
    }

    this.thumbPageCanvas
      .then(msg => {
        this.image?.setAttribute('aria-label', msg)
      })
      .catch(() => null)
  }
}
