import { IFileObj } from '@app/views/organization/documents/components/Document/components/File/types';
import { Action } from 'redux';

export interface IFilePreview {
  /** Показ превью */
  show: boolean;
  /** ID документа */
  documentId: string;
  /** Режим отправки документа (Обмен ЭДО) */
  isMessage?: boolean;
  /** Замена документа для превью */
  replaceDocumentOguid?: string;
  /** Превью в виде картинок */
  picsMode?: boolean;
  /** Превью для прикреплённых в обсуждении файлов */
  isAttachment?: boolean;
  /** Превью для файлов в запущеном документе */
  docFile?: IFileObj | undefined;
}

export enum FilePreviewActionTypes {
  SET_PREVIEW = 'SET_PREVIEW',
  CLOSE_PREVIEW = 'CLOSE_PREVIEW',
}

export interface IFilePreviewSet extends Action<string> {
  payload: IFilePreview;
  type: FilePreviewActionTypes.SET_PREVIEW;
}

export interface IFilePreviewClose extends Action<string> {
  type: FilePreviewActionTypes.CLOSE_PREVIEW;
}

export type Actions = IFilePreviewSet | IFilePreviewClose;
