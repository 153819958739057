import { ILibDropdownItem } from '@infologistics/frontend-libraries'
import { SCALE_BREAKPOINTS } from '@views/organization/documents/components/Document/components/File/consts'

export const getScaleItems = (): ILibDropdownItem[] =>
  SCALE_BREAKPOINTS
    .map(point => {
      return {
        name: point.toString(),
        translation: point.toString(),
        data: point
      }
    })
