import { Actions, ApplicationsActionTypes, IApplication } from './types';

export const initialState: IApplication[] = [];

export default function applicationsReducer(
  state: IApplication[] = initialState,
  action: Actions
): IApplication[] {
  switch (action.type) {
    case ApplicationsActionTypes.SET_APPLICATIONS:
      return action.payload;
    default:
      return state;
  }
}
