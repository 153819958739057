import { IRenderableView, PDFThumbnailViewer, PDFViewer } from 'pdfjs-dist/types/web/pdf_rendering_queue'
import { IFileViewDrawError, IVisibleRenderingQueue } from '@views/organization/documents/components/Document/components/File/types'
import { Nullable } from '@store/types/commonTypes'
import { RenderingState } from '@views/organization/documents/components/Document/components/File/consts'
import { ErrorCode } from '@const/consts'
import store from '@store/configureStore'
import { actions as documentsActions } from '@store/modules/documents/actions'

const CLEANUP_TIMEOUT = 30000

let PDF_VIEWER: PDFViewer
let THUMBNAIL_VIEWER: PDFThumbnailViewer

export class PDFRenderingQueue {
  pdfViewer: any
  pdfThumbnailViewer: any
  onIdle: any
  highestPriorityPage: Nullable<string>
  idleTimeout: Nullable<ReturnType<typeof setTimeout>>
  printing: boolean
  isThumbnailViewEnabled: boolean

  constructor() {
    this.pdfViewer = null
    this.pdfThumbnailViewer = null
    this.onIdle = null
    this.highestPriorityPage = null
    this.idleTimeout = null
    this.printing = false
    this.isThumbnailViewEnabled = false
  }

  setViewer(pdfViewer: PDFViewer): void {
    this.pdfViewer = pdfViewer
    PDF_VIEWER = pdfViewer
  }

  setThumbnailViewer(pdfThumbnailViewer: PDFThumbnailViewer): void {
    this.pdfThumbnailViewer = pdfThumbnailViewer
    THUMBNAIL_VIEWER = pdfThumbnailViewer
  }

  isHighestPriority(view: IRenderableView): boolean {
    return this.highestPriorityPage === view.renderingId
  }

  hasViewer(): boolean {
    return !!this.pdfViewer
  }

  renderHighestPriority(currentlyVisiblePages?: object): void {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout)
      this.idleTimeout = null
    }

    if (PDF_VIEWER.forceRendering(currentlyVisiblePages)) {
      return
    }

    if (THUMBNAIL_VIEWER?.forceRendering()) {
      return
    }

    if (this.printing) {
      return
    }

    if (this.onIdle) {
      this.idleTimeout = setTimeout(() => this.onIdle.bind(this), CLEANUP_TIMEOUT)
    }
  }

  getHighestPriority(visible: IVisibleRenderingQueue, views: any[], scrolledDown: boolean, preRenderExtra: boolean | undefined = false): any {
    const visibleViews = visible?.views
    const numVisible = visibleViews?.length ?? 0

    if (numVisible === 0) {
      return null
    }

    for (let i = 0; i < numVisible; i++) {
      const view = visibleViews[i].view

      if (!this.isViewFinished(view)) {
        return view
      }
    }

    const firstId = visible.first.id
    const lastId = visible.last.id

    if (lastId - firstId + 1 > numVisible) {
      const visibleIds = visible.ids

      for (let i = 1, ii = lastId - firstId; i < ii; i++) {
        const holeId = scrolledDown ? +firstId + i : lastId - i

        if (visibleIds.has(holeId)) {
          continue
        }

        const holeView = views[holeId - 1]

        if (!this.isViewFinished(holeView)) {
          return holeView
        }
      }
    }

    let preRenderIndex = scrolledDown ? +lastId : +firstId - 2
    let preRenderView = views[preRenderIndex]

    if (preRenderView && !this.isViewFinished(preRenderView)) {
      return preRenderView
    }

    if (preRenderExtra) {
      preRenderIndex += scrolledDown ? 1 : -1
      preRenderView = views[preRenderIndex]

      if (preRenderView && !this.isViewFinished(preRenderView)) {
        return preRenderView
      }
    }

    return null
  }

  isViewFinished(view: IRenderableView): boolean {
    return view.renderingState === RenderingState.FINISHED
  }

  renderView(view: IRenderableView): boolean {
    switch (view.renderingState) {
      case RenderingState.FINISHED:
        return false

      case RenderingState.PAUSED:
        this.highestPriorityPage = view.renderingId
        view.resume?.()
        break

      case RenderingState.RUNNING:
        this.highestPriorityPage = view.renderingId
        break

      case RenderingState.INITIAL:
        this.highestPriorityPage = view.renderingId
        view.draw()
          .finally(() => this.renderHighestPriority())
          .catch((reason: IFileViewDrawError) => {
            if (reason?.status === ErrorCode.NOT_AUTH) {
              store.dispatch(documentsActions.setReloadCurrentDocumentFile(true))
              return
            }
          })
        break
    }

    return true
  }
}
